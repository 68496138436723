
.function_common {
	margin: 10px;
	border: 1px dashed var(--sky-blue-color);
	padding: 10px;
	display: flex;
	flex-direction: column;
}

.function_common h2 {
	margin: 0;
	padding: 10px 0;
	width: 100%;
	color:var(--sky-blue-color);
}

.complain_info h2 {
	margin: 0;
	padding: 10px 0;
	width: 100%;
	color: var(--sky-blue-color);
}

.complain_info p {
	padding: 10px 20px;
	background-color: var(--light-yellow-color);
}

.complain_info p:nth-child(odd) {
	background-color: var(--light-fresh-color);
}

.repaly_indivisual {
	width: 90%;
	margin: auto;
	border: 1px solid var(--gray-color);
	padding: 10px;
	margin-bottom: 20px;
}

.repaly_indivisual p {
	padding: 10px 20px;
	background-color: var(--white-color);
	border: 1px solid var(--gray-color);
}

.repaly_indivisual p:nth-child(even) {
	padding: 10px 20px;
	background-color: var(--light-gray-color);
	border: 1px solid var(--light-gray-color);
}

.repaly_indivisual .button_section {
	flex-direction: row;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
}

.repaly_indivisual .mrgin_20 {
	margin: 20px 0;
}

.repaly_indivisual .mrgin_20 h3 {
	color: var(--green-color) !important;
}

.repaly_indivisual .button_section button {
	cursor: pointer;
}

.repaly_indivisual .button_section h3 {
	left: 0;
	position: absolute;
	color: var(--primary-color);
}

.button_section button {
    padding: 8px 12px;
    background-color: var(--green-color);
    color: var(--white-color);
    border: none;
    border-radius: 2px;
    font-size: 18px;
}

.button_section button:last-child {
	background-color: var(--primary-color);
}

.give_reply .input_section {
	display: flex;
	flex-grow: 4;
	margin: 10px 0;
}

.image_view {
	display: flex;
	flex-wrap: wrap;
}

.image_view img {
	width: 100px;
	margin: 10px;
}

.give_reply .input_section label {
	flex: 1;
}

.give_reply .input_section input {
	flex: 3;
	padding: 8px;
}

.give_reply .input_section textarea {
	flex: 3;
}

.give_reply .input_section .icon_sec {
	flex: 3;
}

.give_reply .input_section span:last-child {
	margin-left: 20px;
}

.give_reply .input_section span svg {
	color: gray;
	margin: 0;
	padding: 0;
}

.give_reply .input_section span .class_true {
	color: var(--green-color);
}
