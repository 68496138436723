.wrapper {
  background-color: var(--next-white);
  min-height: calc(100vh - 100px);
  padding-bottom: 50px;
}
.holder {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  h2 {
    font-weight: 400;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 2px;
    text-transform: uppercase;
    font-size: 18px;
    width: max-content;
    margin: auto;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}
.head {
  display: flex;
  align-items: center;
  gap: 10px;
}
.table_wrapper {
  position: relative;
  background-color: var(--white-color);
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    th {
      background-color: var(--teal);
      color: var(--white-color);
      border: 1px solid var(--white-color);
      padding: 4px;
    }
    tbody tr td {
      padding: 4px;
    }
    tbody tr:nth-child(even) {
      background-color: var(--lightb);
    }
  }
}
.action_buttons {
  display: flex;
  gap: 10px;
  align-items: center;

  a {
    text-decoration: none;
  }

  button {
    padding: 4px 10px;
    color: var(--white-color);
    background-color: var(--pink);
  }

  button:nth-child(2) {
    background-color: var(--teal);
  }
  .assigned{
    background-color: var(--gray)!important;
  }
}
.edit_button{
  margin-left: 10px;
  padding: 4px 8px;
  background-color: var(--green);
  color: var(--white-color);
}