.main{
    background-color: var(--white-color);
    padding: 30px;
    border-radius: 10px;
    h4{
        text-align: center;
        font-weight: 400;
        font-size: 1.3rem;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 2px;
        max-width: max-content;
        margin: auto;
    }
}
.input_wrapper{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 15px;
}

.common{
    gap: 5px;
    display: flex;
    flex-direction: column;
  
}
.common input{
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    outline: none;
}
.common input:focus{
    border: 1px solid var(--outline-color);
}
.extra_wrapper{
    display: flex;
   
    flex-wrap: wrap;
  
}

.btn_group{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.btn_group button{
    background-color: var(--green);
    border-radius: 4px;
    color: var(--white-color);
    padding: 10px 20px;
    margin-top: 10px;
    transition: 0.3s;
}
.btn_group button:hover{
    outline: 1px solid var(--green);
    background-color: var(--white-color);
    color: var(--black);
    transition: 0.3s;
}

@media  screen and (min-width: 768px) {
    .input_wrapper{
       
        grid-template-columns: repeat(2,1fr);
  
    }
}
@media  screen and (min-width: 992px) {
	.input_wrapper{
       
        grid-template-columns: repeat(4,1fr);
      
    }
}