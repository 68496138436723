.heading{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;
	h2 {
		font-weight: 400;
		border-bottom: 1px solid var(--border-color);
		padding-bottom: 2px;
		text-transform: uppercase;
		font-size: 18px;
		width: max-content;
		margin: auto;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 10px;
   		margin-bottom: 15px;
	}
	.inner_headign{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);
		.input_section{

			
	
				display: flex;
				gap: 20px;
				.common{
					display: flex;
					flex: 1;
					justify-content: center;
					align-items: center;
					gap: 10px;
					margin: 20px 0;

					label{
						font-size: 14px;
						margin-bottom: 5px;
						
					}
					select{
						width: 100%;
						padding: 8px;
						border-radius: 4px;
						border: 1px solid var(--border-color);
						&:focus{

							outline: 1px solid var(--outline-color);
						}
					}
				}
		
			.dates{
				display: flex;
				gap: 20px;
				margin-top: 30px;
				.dateWrapper{
					display: flex;
					flex: 1;
					justify-content: center;
					align-items: center;	
					gap: 10px;
					label{
						font-size: 14px;
						margin-bottom: 5px;
						flex: 1;
					}
					input{
						width: 100%;
						padding: 6px;
						border-radius: 4px;
						outline: none;
						border: 1px solid var(--border-color);
						flex: 6;
						&:focus{

							outline: 1px solid var(--outline-color);
						}
					}
				}
			}
			.invoices{
				display: flex;
				gap: 20px;
				margin-top: 20px;
				.invoicesWrapper{
					display: flex;
					flex: 1;
					justify-content: center;
					align-items: center;	
					gap: 10px;
					label{
						font-size: 14px;
						margin-bottom: 5px;
						flex: 1;
					}
					input{
						width: 100%;
						padding: 6px;
						border-radius: 4px;
						outline: none;
						border: 1px solid var(--border-color);
						flex: 6;
						&:focus{

							outline: 1px solid var(--outline-color);
						}
					}
				}
			}
		}

		
		
		
	}
	.search{
		display: flex;
			justify-content: end;
		button{
			padding: 8px 15px;
			font-weight: 500;
			color: var(--white-color);
			background-color: var(--green);
			
		}
	}
	.buttons{
		.common{
			display: flex;
			gap: 10px;
		}
		button{
			padding: 8px 10px;
			font-weight: 500;
			color: var(--white-color);
			border-radius: 4px;
			
				
				&:nth-child(1){
					background-color: var(--cyan);

				}
				&:nth-child(2){
					background-color: var(--primary);

				}
				
		}
	}
	.DetailsReport{
		h3{
			text-align: center;
			font-weight: 500;
			margin-top: 10px;
		}
		.common{
			display: flex;
			justify-content: center;
			gap: 20px;
			margin-top: 10px;
			p{
				font-size: 16px;
				svg{
					margin-right: 5px;
					
				}
			}
		}
	}
	.input_section2{
		margin-top: 15px;
	}
}