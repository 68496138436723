.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;

	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

		h2 {
			font-weight: 400;
			border-bottom: 1px solid var(--border-color);
			padding-bottom: 2px;
			text-transform: uppercase;
			font-size: 18px;
			width: max-content;
			margin: auto;
			letter-spacing: 1px;
			text-align: center;
			margin-top: 10px;
			   margin-bottom: 15px;
		}
	

	.input_section {
		
		display: flex;
		align-items: center;
		flex: 6;
		position: relative;
		justify-content: center;
		gap: 20px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);

		.input_wrapper{
			
			gap: 12px;
			display: flex;
			flex-direction: column;
			
			label{
				
				font-size: 16px;
				text-align: start;
				font-weight: 500;
				color: var(--header-font-color);
				
				}
				input{
					
					padding: 10px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
				textarea{
					
					padding: 10px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
				select{
					
					padding: 10px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					&:focus{
						outline: 1px solid var(--outline-color);
					}		
				}
						
		}
	
		.buttonWrapper{
			display: flex;
			justify-content: end;
			align-items: center;
			gap: 20px;
			
		button{
			width: 150px;
			font-size: 16px;
			padding: 8px 15px;
			font-weight: 500;
			color: var(--white-color);
			background-color: var(--green);
			
			text-transform: capitalize;
			&:last-child{
				background-color: var(--cyan);
		}
	}

	}
	}

	.tableSection{
			margin-top: 20px;
		table {
			width: 100%;
			border-collapse: collapse;
			text-align: center;
			
			th {
				background-color: var(--teal);
				color: var(--white-color);
				border: 1px solid var(--white-color);
				padding: 8px;
			}
			tbody tr td{
				padding: 4px;
				border: 1px solid var(--white-color);
				svg{
					cursor: pointer;
					color: var(--indigo);
				}
			}
			/* tbody tr td:nth-child(1){
			   text-align: left;
			} */
			tbody tr:nth-child(even) {
				background-color: var(--lightb);
			}
		}
		
	}
}
	}
