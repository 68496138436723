.wrapper{

    --radious:8px;
    background-color: var(--next-white);
    min-height: calc(100vh - 100px);
	padding-bottom: 50px;
}
.menuitems{
    padding: 14px 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}
.report_devider h1{
    font-size: 1.5rem;
    text-align: center;
    border-bottom: 1px solid var(--border-color);
    max-width: max-content;
    margin: auto;
    padding-bottom: 2px;
    color: var(--dark);
    padding-top: 14px;
    font-weight: 500;
}
.item{
    background-color: var(--white);
    aspect-ratio: 4/2;
    border-radius: var(--radious);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--secondary);
	text-align: center;  
    h2{
        font-size: 1.2rem;
        font-weight: 500;
    }
    &:hover{
        
        background-color: var(--lightb);
        h2{
            color: var(--cyan);
        }
    }
}
@media screen and (max-width: 992px) {
    .menuitems{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 768px) {
    .menuitems{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 500px) {
    .menuitems{
        grid-template-columns: repeat(2, 1fr);
        padding: 16px 30px;
    }
    .item{
        h2{
            font-size: 1rem;
        }
    }
    .report_devider h1{
        font-size: 1.3rem;
        text-align: center;
        color: var(--cyan);
        &:first-child{
            padding-top: 16px;
        }
    }
}
