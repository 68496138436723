:root{
    --white-color : #ffffff;
    --next-white : #f0f0f0;
    --black-color: #000000;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #9c9e9f;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --lightb: #E4E9EF;
    --dark: #343a40;
    --border-color:#ccc;
    --outline-color:#66afe9;
    --header-font-color:#666;
    --model-wrapper-color: #00000090;
    --modal-radius:8px;
    --focus:#2580F8;
}