
.atributeValue {

		gap: 10px;
		margin: 5px;
		padding: 5px 10px;
		display: inline-block;
		background-color: var(--light);
		border-radius: 8px;
		:hover svg{
			color: var(--black-color);
			transition: 0.3s;
		}
		.area{
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			color: var(--header-font-color);
		}
		
	svg{
		color: var(--red);
		cursor: pointer;
		margin-left: 2px;
		font-size: 20px;
		transition: 0.3s;
		
	}
}



