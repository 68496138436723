.wrapper_popup {
  inset: 0;
  background-color: var(--model-wrapper-color);
  z-index: 100;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  .holder {
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 4px;
    width: 90%;
    h2 {
      text-align: center;
      font-weight: 400;
      border-bottom: 1px solid var(--border-color);
      margin: auto;
      max-width: max-content;
      margin-bottom: 20px;
    }
    p {
      text-align: center;
      text-align: justify;
      margin-bottom: 20px;
    }
    h3 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 18px;
      span {
        color: var(--red);
      }
    }
    .input_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      lable {
        font-size: 14px;
      }
      input {
        margin-top: 2px;
        padding: 8px;
        border: none;
        border-radius: 2px;
        outline: 1px solid var(--border-color);

        &:focus {
          outline: 1px solid var(--primary);
        }
      }
      .control {
        &:focus {
          outline: 1px solid var(--primary) !important;
        }
      }
    }
    .button_wrapper {
      grid-column: span 3;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      gap: 20px;
      button {
        background-color: var(--green);
        padding: 8px 14px;
        color: var(--white-color);
        border-radius: 2;
        margin-top: 10px;
        font-size: 14px;
        &:last-child {
          background-color: var(--red);
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .wrapper_popup {
    .holder {
      width: 50%;
    }
  }
}
