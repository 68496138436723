.wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
	inset: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;

}


.holder {
    padding: 20px;
    background: var(--white-color);
    margin: auto;
    border-radius: var(--modal-radius);
    width: 50%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;
    position: relative;
}

.holder h2 {
	font-weight: 500;
    text-align: center;
    font-size: 1.25rem;
}
.age_select{
    display: flex;
    gap: 16px;
    align-items: center;
    .my_select{
        flex: 1;
    }
}
.form_section{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .vr_inputs{
        display: flex;
        flex-direction: column;  
        gap: 8px;     
    }
    .input_common{
        display: flex;
        align-items: center;
        padding: 8px;
        background-color: #fff8e8;
        border-radius: 4px;
        &:nth-child(even){
            background-color: var(--lightb);
        }
        label{
            flex: 1;
        }

        input{
            flex: 2;
            padding: 8px;
            border-radius: 4px;
            border: none;
            outline: 1px solid var(--gray);
        }
    }
}

.button_section{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    button{
        padding: 8px 16px;
            background-color: var(--green);
            color: var(--white-color);
            &:first-child{
                background-color: var(--red);
            }
    }
}
