.wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}
.holder {

	position: relative;
	width: 50%;
	padding: 20px;
	background-color: var(--white-color);
	border-radius: var(--modal-radius);
    h2 {
        font-weight: 400;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 2px;
        text-transform: uppercase;
        font-size: 18px;
        width: max-content;
        margin: auto;
        letter-spacing: 1px;
        text-align: center;
        margin-top: 10px;
           margin-bottom: 15px;
    }
}

   

        .tableSection {
            position: relative;
            background-color: var(--white-color);
            padding: 10px;
            border-radius: 4px;
            table {
                width: 100%;
                border-collapse: collapse;
                text-align: center;
                font-size: 14px;
				        
                th {
                    background-color: var(--teal);
                    color: var(--white-color);
                    border: 1px solid var(--white-color);
                    padding: 8px;
                }
        
                tbody tr td {
                    
                    padding: 8px;
                    border: 1px solid var(--white-color);    
                     
                }
                tbody tr:nth-child(even) {
                    background-color: var(--lightb);
                }
        				
                .action_buttons{
                    display: flex;
                    justify-content: center;
                    gap: 12px;
                    input{
                        padding: 3px 10px;
                        cursor: pointer;
                        background-color: var(--green);
                        border-radius: 4px;
                        color: var(--white-color);
                        transition: 0.3s;
                        border: none;
                        
                        a{
                            text-decoration: none;
                            color: var(--white-color);
                        }
                        &:hover{
                            background-color: var(--white-color);
                            color: var(--green);
                            outline: 1px solid var(--green);
                            a{
                                color: var(--green);  
                            }
                            }
                    }
                    input:last-child{
                        background-color: var(--danger);
                        &:hover{
                        background-color: var(--white-color);
                        color: var(--danger);
                        outline: 1px solid var(--danger);
                        }
                    }
                }
            }
	
        }
		.close_btn{
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 20px;
			button{
				width: 150px;
				font-size: 16px;
				padding: 8px 15px;
				font-weight: 500;
				color: var(--white-color);
				background-color: var(--danger);
				margin-top: 10px;
				text-transform: capitalize;
		
		}
		}
		
	

	


	