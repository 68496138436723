
.actionButton {
	display: flex;
	align-items: center;
	justify-content: center;
}

.actionButton a svg {
	color: var(--primary);
	width: 20px;
	height: 20px;
	cursor: pointer;
	padding: 5px;
}

.actionButton a svg:hover {
	color: var(--primary) !important;
}

.actionButton a svg:hover::after {
	content: 'hello';
	display: block;
	position: absolute;
	z-index: 120211;
}

.actionButton button {
	background-color: var(--cyan)
}

.actionButton button:hover {
	background-color: var(--primary);
}
.color_purple{
	color:  var(--purple-color);
}
.color_red{
	color:  var(--red-color);
}

.tooltip_holder {
	position: relative;
}

.tooltip_holder svg {
	color:var(--primary);
	height: 20px;
	width: 20px;
	cursor: pointer;
}

.tooltip {
	position: absolute;
	right: 0;
	background-color: var(--primary);
	color: var(--white-color);
	border-radius: 5px;
	margin-top: -10px;
	padding: 5px;
	z-index: 1;
	width: max-content;
	visibility: hidden;
}

.tooltip_holder:hover .tooltip {
	visibility: visible;

}

.salesMain {
	width: 100%;
	min-height: 200px;
	padding: 10px 0;
	background-color: var(--next-white);

	h2 {
		font-weight: 400;
		border-bottom: 1px solid var(--border-color);
		padding-bottom: 2px;
		text-transform: uppercase;
		font-size: 18px;
		width: max-content;
		margin: auto;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 10px;
   		margin-bottom: 8px;
	}
	.innerSale {
		width: 96%;
		margin: auto;
		padding-top: 10px;
		position: relative;
		background-color: var(--white-color);
		padding: 10px;
		border-radius: 8px;
	
		.tableSection {
			table{
				width: 100%;
				margin-top: 10px;

				tr th {
					background-color: var(--teal);
					color: var(--white-color);
					padding: 8px;
				}

				tr:nth-child(even) {
					background-color: var(--lightb);
				}
				td {
					text-align: center;
					padding: 0px 8px !important;
				}
				span{
					font-size: 14px;
					background-color: var(--cyan);
					padding: 3px 5px;
					color: var(--white-color);
	
				}
				.bar{
					color: var(--purple);
					cursor: pointer;
				}

				.edit{
				
					margin-right: 5px;
					cursor: pointer;
					color: var(--green);
					transition: 0.3s;

					:hover{
						
						color: var(--black-color);
						transition: 0.3s;
					}
				}
			
				.pen {
					
					margin-right: 5px;
					cursor: pointer;
					color: var(--info);
					
					border-radius: 3px;
					transition: 0.3s;

					:hover{
						color: var(--black-color);
						transition: 0.3s;
					}

				}
				.delete {
					
					cursor: pointer;
					color: var(--danger);
				
				:hover{
					color: var(--black-color);
					transition: 0.3s;
				}
				}
				img {
					height: 30px;
					width: 30px;
					border: 1px solid var(--border-color);
					border-radius: 50%;
					margin-right: 5px;
					object-fit: contain;
				}
				
			}
			
			
		}
		.salesHistoruImagesec {
			display: flex;
			align-items: center;
			font-weight: bold;
			font-weight: 400;
			color: var(--header-font-color);
		}
		
	}

	.orderactivites_wrapper{
		background-color: var(--model-wrapper-color);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1000;
		display: flex;
		align-items: center;
		justify-content: center;
		
	}
	.orderactivites {
		position: relative;
		background-color: var(--white-color);
		width: 60%;
		padding: 20px;
		border-radius: var(--modal-radius);
		


		button{
		
			background-color: var(--danger);
			padding: 8px 20px;
			font-size: 16px;
			
		}
		h4{
			font-size: 14px;
			margin-bottom: 10px;
			font-weight: 500;
		}
		h3{
			background-color: var(--cyan);
			margin-bottom: 10px;
		}

		table{
			width: 100%;
			thead{
				background-color: var(--teal);
				tr{
					th{
						padding: 5px;

					}
					
				}
				tr:nth-child(even) {
					background-color: var(--lightb);
				}
				
			}
			tbody{
				td{
					text-align: center;
					padding: 0px 8px !important;
				}
			}
		}
		
	}
	h3 {
		margin: 0;
		padding: 0;
		width: 100%;
		background-color: var(--sky-blue-color);
		text-align: center;
		padding: 10px 0;
		border-radius: 10px;
		color: var(--white-color);
	
	}
	
button {
	background: var(--red-color);
	color: var(--white-color);
	border-radius: 5px;
	padding: 8px;
	border: none;
	margin-top: 20px;
	cursor: pointer;
	float: right;

}

}












.orderactivites table th {
	background-color: var(--sky-blue-color);
	color: var(--white-color);
}
.orderactivites table td {
	text-align: center;
}
/* summerpop comp css  end */
.my_popup {
	position: absolute;
	padding: 20px;
	top: 150px;
	background: var(--white-color);
	border-radius: 5px;
	width: 50%;
	box-shadow: 1px 2px 4px 0px var(--gray-color);
	left: 0;
	right: 0;
	margin: auto;
	z-index: 2;
}

.my_popup h2 {
	margin: 0;
	padding: 10px 0;
	background-color: var(--sky-blue-color);
	color: var(--white-color);
	text-align: center;
	border-radius: 5px;
}

.my_popup table th {
	background-color:var(--primary-color);
	color: var(--white-color);
}

.my_popup .button_section {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.button_section button {
    padding: 8px 12px;
    background-color: var(--green-color);
    color: var(--white-color);
    border: none;
    border-radius: 2px;
    font-size: 18px;
}

.button_section button:last-child {
	background-color: var(--primary-color);
}

.SpinnerWrrapper {
	position: fixed;
	left: 50%;
	top: 30%;
	z-index: 1;
	padding: 30px;
	transform: translate(-30%, -50%);
}

.SpinnerWrrapper p {
	width: 100%;
	text-align: center;
	margin-top: 20px;
	font-size: 25px;
	font-weight: bold;
	font-style: italic;
	color: var(--green-color);
}