.wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}
.holder {
	position: relative;
	width: 30%;
	padding: 20px;
	background-color: var(--white-color);
	border-radius: var(--modal-radius);

}
.tabs {
	display: flex;
	button{
		border-radius:4px;
		padding: 8px;
		background-color: var(--cyan);
		border: 1px solid white;
		color: var(--white-color);
	}
}

.active_tab{
	background-color: var(--cyan)!important;
}

