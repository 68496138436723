.wrapper {
  background-color: var(--next-white);
  min-height: calc(100vh - 100px);
  padding-bottom: 50px;
  .holder {
    padding: 20px;
    display: flex;
    gap: 50px;
  }
  .side_bar {
    background-color: var(--white);
    padding: 40px;
    border-radius: 4px;
    flex: 1;
    .image_wrapper {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--border-color);
      border-radius: 4px;
      img {
        width: 100px;
        margin: 10px;
        padding: 4px;
        border: 1px solid var(--orange);
        border-radius: 50%;
        height: 100px;
      }
    }
    .text_wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      button {
        background-color: var(--next-white);
        display: flex;
        padding: 8px;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .main_wrapper {
    flex: 3;
    background-color: var(--white);
    border-radius: 4px;
    padding: 20px;

    h2 {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      border-bottom: 1px solid var(--border-color);
      max-width: max-content;
      margin: auto;
      text-transform: uppercase;
    }
    .main_holder {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
      .information_wrapper {
        gap: 20px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        p {
          text-align: right;
        }
        span {
          color: var(--secondary);
          grid-column: span 5;
        }
      }
    }
  }
  .report_warapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    h3{
      color: var(--dark);
      margin-top: 20px;
      border-bottom: 1px solid var(--border-color)
       }
    .report_links{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      text-align: center;
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--next-white);
        aspect-ratio: 4/2;
        padding: 8px;
        font-size: 1.2rem;
        border-radius: 4px;
        transition: 0.3s;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        &:hover{
          
          transition: 0.3s;
          background-color: var(--cyan);
          color: var(--white);
        }
      }
    }
  }
}
