.wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.holder {
	width: 70%;
	position: relative;
	background-color: var(--white-color);
	border-radius: var(--modal-radius);
	overflow: auto;
	max-height: 90vh;
	overflow: auto;
}
.holder h3{
    background-color: var(--primary);
	color: var(--white-color);
	text-align: center;
    padding: 10px;
}
.info_holder{
    padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.info_section {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px;
	padding: 10px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.table_wrapper{
	position: relative;
	background-color: var(--white-color);
    padding: 10px;
    border-radius: 4px;
	table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        th {
            background-color: var(--teal);
            color: var(--white-color);
            border: 1px solid var(--white-color);
            padding: 4px;
        }
        tbody tr td{
            padding: 4px;
            border: 1px solid var(--white-color);
        }
        tbody tr td:nth-child(2){
           text-align: left;
        }
        tbody tr:nth-child(even) {
            background-color: var(--lightb);
        }
    }
    .payment_info{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 8px;
        margin-top: 10px;
        div{
            display: flex;
            gap: 10px;
            background-color: #e9e9fe;
            color: var(--primary);
            padding: 4px;
            border-radius: 4px;
        }
    }
}

.buttonsection {
    display: flex;
    gap: 15px;
    justify-content: center;
    button {
        padding: 8px 16px;
        color: var(--white-color);
        background-color: var(--green);
        border-radius: 4px;
        cursor: pointer;
        &:disabled{
            background-color: var(--gray);
            cursor: not-allowed;
        }
        &:not(:disabled):hover {
            filter: brightness(.9);
        }
        &:first-child {
            background-color: var(--red);
        }
    }
}

