.wrapper{
    background-color: var(--next-white);
	padding: 30px;
	height: 100vh;

    .holder{
        background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

        .form_wrapper{
            max-width: 30%;
            width: 70%;
            margin: auto;
            h2 {
                font-weight: 400;
                border-bottom: 1px solid var(--border-color);
                padding-bottom: 2px;
                text-transform: uppercase;
                font-size: 18px;
                width: max-content;
                margin: auto;
                letter-spacing: 1px;
                text-align: center;
                margin-top: 10px;
                   margin-bottom: 15px;
            }
            .input_wrapper{
                display: flex;
                flex-direction: column;
                gap: 5px;
                input{
                    border: none;
                    outline: 1px solid var(--border-color);
                    border-radius: 4px;
                    padding: 8px;
                }
            }
            .button_wrapper{
                display: flex;
              margin-top: 20px;
              justify-content: center;
                button{
                    background-color: var(--green);
                    color: var(--white-color);
                    padding: 8px 20px;
                    
                }
            }
        
        }
      
    }
}