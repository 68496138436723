.wrapper {
	width: 90%;
	margin: 20px auto;
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 10px;
}

.wrapper h3{
	text-align: center;
}

.wrapper img {
	margin-right: 10px;
	width: 60px;
	height: auto;
	border: 1px solid var(--border-color);
	
}
.info_group{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	position: sticky;
	top: 50px;
	background-color: var(--white-color);
	padding: 10px;
}
.info_group button{
	padding: 8px;
	background-color: var(--green);
	color: var(--white-color);
	
}


.action_btns{
display: flex;
gap: 20px;
align-items: center;
justify-content: center;

}
.action_btns button{
	padding: 8px 16px;
	background-color: var(--green);
	color: var(--white-color);
}
.action_btns button:last-child{
	background-color: var(--info);
}

.table_wrapper{
	overflow: auto;
}
.wrapper table{
    width: 100%;
}
.wrapper table{
	width: 100%;
	margin-top: 20px;
}
.wrapper table td {
	text-align: center;
	padding: 5px 10px;
}

.wrapper table th {
	background-color: var(--teal);
	color: var(--white-color);
	padding: 10px 5px;
	font-size: 16px;
	text-align: center;
	
}
.wrapper table tr:nth-child(even) {
	background-color: var(--lightb);
}
.wrapper table tr{
	font-size: 14px;

}

