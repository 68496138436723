.wrapper {
  background-color: var(--next-white);
  min-height: calc(100vh - 100px);
  padding-bottom: 50px;
}
.holder {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  h3 {
    font-weight: 400;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 2px;
    text-transform: uppercase;
    font-size: 18px;
    width: max-content;
    margin: auto;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .header_button_wrapper {
    background-color: var(--white-color);
    padding: 10px;
    border-radius: 4px;
    display: flex;
    gap: 20px;
    button {
      background-color: var(--cyan);
      padding: 8px;
      border-radius: 2px;
      color: var(--white-color);
    }
  }
  .table_wrapper {
    position: relative;
    background-color: var(--white-color);
    padding: 10px;
    border-radius: 4px;
    overflow: auto;
    h2 {
      font-size: 18px;
      text-align: center;
      border-bottom: 1px solid var(--border-color);
      max-width: max-content;
      margin: auto;
      margin-bottom: 10px;
      font-weight: 400;
      color: var(--dark);
    }
    table {
      width: 100%;
      border-collapse: collapse;
      text-align: center;
      font-size: 14px;
      th {
        background-color: var(--teal);
        color: var(--white-color);
        border: 1px solid var(--white-color);
        padding: 4px;
      }
      tbody tr td {
        padding: 4px;
        border: 1px solid var(--white-color);
      }
      tbody tr:nth-child(even) {
        background-color: var(--lightb);
      }
      .action_Wrapper {
        display: flex;
        justify-content: center;
        gap: 10px;
        button {
          background-color: transparent;
          svg {
            color: var(--cyan);
            font-size: 16px;
          }
          &:last-child {
            svg {
              color: var(--red);
            }
          }
        }
      }
    }
  }
}


