.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	
	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

		h2 {
			font-weight: 400;
			border-bottom: 1px solid var(--border-color);
			padding-bottom: 2px;
			text-transform: uppercase;
			font-size: 18px;
			width: max-content;
			margin: auto;
			letter-spacing: 1px;
			text-align: center;
			margin-top: 10px;
			   margin-bottom: 15px;
		}

    }
		.tableSection{
			table {
				width: 100%;
				border-collapse: collapse;
				text-align: center;
				th {
					background-color: var(--teal);
					color: var(--white-color);
					border: 1px solid var(--white-color);
					padding: 8px;
				}
				tbody tr td{
					padding: 4px;
					border: 1px solid var(--white-color);
					
					svg{
						cursor: pointer;
						color: var(--indigo);
					}
					.buttonWrapper{
						display: flex;
						justify-content: center;
						align-items: center;
						button{
							width: 150px;
							font-size: 16px;
							padding: 8px 8px;
							font-weight: 500;
							color: var(--white-color);
							background-color: var(--green);
							margin: 4px;
							text-transform: capitalize;
							&:last-child{
								background-color: var(--cyan);
								margin-left: 10px;
							}
						}	
					}
                   
				}
				
				tbody tr:nth-child(even) {
					background-color: var(--lightb);
				}
				
				svg{
					font-size: 18px;
					&:nth-child(1){
						color: var(--danger);
						
					}
					&:nth-child(2){
						color: var(--primary);
						margin-left: 10px;
					}
				}
			}
			.btn_group{		
				display: flex;
				gap: 15px;
				justify-content: end;
				margin-top: 20px;
				
				button{
				width: 100px;
				font-size: 16px;
				padding: 8px 15px;
				font-weight: 500;
				color: var(--white-color);
				background-color: var(--green);
				margin-bottom: 20px;
				text-transform: capitalize;
				&:last-child{
					background-color: var(--cyan);
				}
			}	
		}
	}

}

.pop_up_wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.pop_up {
	position: relative;
	width: 500px;
	padding: 20px;
	background-color: var(--white-color);
	border-radius: var(--modal-radius);
	display: flex;
	flex-direction: column;

	h3{
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		color: var(--primary);

	}
	textarea{
		margin: auto;
		padding: 8px;
		border-radius: 4px;
		outline: 1px solid var(--border-color);
		border: none;
		&:focus{
			outline: 1px solid var(--outline-color);
		}
	}	
						
	.action_btns{
		display: flex;
		justify-content: center;
		margin-top: 20px;
		gap: 20px;
		button{
		width: 100px;
        font-size: 14px;
        padding: 12px 15px;
        font-weight: 500;
        color: var(--white-color);
        background-color: var(--danger);
        margin-top: 10px;
        text-transform: capitalize;
		&:last-child{
			background-color: var(--danger);
		}
	}
	}
}

