.menupart {
    display: flex;
    position: sticky;
    top: 50px;
    background-color: var(--next-white);
    z-index: 1000;
    padding: 30px 30px 0px 30px; 
    
    ul {
        padding: 30px;
        display: flex;
        border-radius: 8px;
        background-color: var(--white-color);
        width: 100%;
        gap: 20px;
        a{
            text-decoration: none;
            list-style: none;
            border: 1px solid var(--border-color);
            padding: 8px;
            border-radius: 4px;
            background-color: var(--primary);
            color: var(--white-color);
            font-weight: 500;
            transition: 0.3s;
            &:hover{
                background-color: var(--white-color);
                color: var(--primary);
                transition: 0.3s;
            }
            li{


            }
        }
    }
}
