.wrapper{
	position: fixed;
	inset: 0;
	background-color: var(--model-wrapper-color);
	z-index: 1000;
	display: flex;
}
.holder {
	background-color: var(--white-color);
	width: 50%;
	margin: auto;
	max-height: 80vh;
	overflow: auto;
	border-radius: var(--modal-radius);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.holder[aria-label="source"] {
	overflow: unset;
}
.holder form{
    display: flex;
    gap: 16px;
    align-items: center;
}
.holder form[aria-label="source"]{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.holder form input{
    flex: 1;
    padding: 8px;
    border-radius: 4px;
    border: none;
    outline: 1px solid var(--green);
}
.input_wrap{
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}
.myselect{
    flex: 1;
}
.holder form button{
    padding: 8px;
    background-color: var(--green);
    color: var(--white-color);
}
.source_buttons{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 16px;
}
.source_buttons button:first-child{
    background-color: var(--red);
}
