.wrapper{
    position: fixed;
    background-color: var(--model-wrapper-color);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .holder{
        background-color: var(--white-color);
        width: 60%;
        border-radius: 10px;
        

        h2{
            text-align: center;
            margin: 0;
            padding: 10px;
            background-color: var(--dark-orange-color);
            color: var(--white-color);
        }
        h4{
            text-align: center;
            margin: 0;
            padding: 10px;
            background-color: var(--sky-blue-color);
            color: var(--white-color);
        }
        
        .input_wrapper{
            width: 90%;
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            flex-direction: column;
            padding: 20px 0;


            .common_att{
                display: flex;
                align-items: center;
                gap: 10px;
                
                label{
                    flex: 1;
                }
                input{
                    padding: 10px;
                    outline: 1px solid var(--border-color);
                    border: none;
                    flex: 3;
                   border-radius: 4px;
                 }
                 .m_select{
                    flex: 3;
                  
                 }
                 .fake_image{
                    flex: 3;
                    display: flex;
                    align-items: center;

                    img{
                        width: 50px;
                        height: auto;
                    }
                }
            }
        }
        .att_buttons{
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: center;
            
            button{
                background-color: var(--green);
                border-radius: 4px;
                color: var(--white-color);
                padding: 8px 16px;
                margin-bottom: 20px;
                &:last-child{
                    background-color: var(--red);
                
                }
            }
        }
}


}




.image_hide{
    display: none;
}