.wrapper{
    position: fixed;
    background-color: var(--model-wrapper-color);
    inset: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.holder{
    width:40%;
    background-color: var(--white-color);
    overflow: hidden;
    border-radius: var(--modal-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.holder img{
   height: 100px;
   width: 100px;
   object-fit: cover;
   border-radius: 50%;
   border: 3px solid var(--info);
}
.info{
   display: flex;
   flex-direction: column;
   gap: 10px;
}
.btn_group {
   margin-top: 10px;
   display: flex;
}
.btn_group button{
    background-color: var(--red);
    padding: 10px 25px;
    color: var(--white-color);
}