.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;

	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

		h2 {
			font-weight: 400;
			border-bottom: 1px solid var(--border-color);
			padding-bottom: 2px;
			text-transform: uppercase;
			font-size: 18px;
			width: max-content;
			margin: auto;
			letter-spacing: 1px;
			text-align: center;
			margin-top: 10px;
			   margin-bottom: 15px;
		}
	
		.buttonWrapper{
				display: flex;
				justify-content: end;
				align-items: center;
				gap: 20px;
			button{
				width: 100px;
				font-size: 16px;
				padding: 8px 15px;
				font-weight: 500;
				color: var(--white-color);
				background-color: var(--green);
				margin-bottom: 20px;
				text-transform: capitalize;
				&:last-child{
					background-color: var(--cyan);
			}
		}
		}
		
		.tableSection{
			
			table {
				width: 100%;
				border-collapse: collapse;
				text-align: center;
				th {
					background-color: var(--teal);
					color: var(--white-color);
					border: 1px solid var(--white-color);
					padding: 8px;
				}
				tbody tr td{
					padding: 4px;
					border: 1px solid var(--white-color);
					svg{
						cursor: pointer;
						color: var(--indigo);
					}
				}
				/* tbody tr td:nth-child(1){
				   text-align: left;
				} */
				tbody tr:nth-child(even) {
					background-color: var(--lightb);
				}
			}
			
		}
	}
	


}