.login{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	max-width: 600px;
	margin: auto;
	span{
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
	}
	.container{
		position: relative;
		background-color: var(--white-color);
		margin: auto;
		border-radius: 8px;
		width: 90%;
		h3{
			font-size: 22px;
			text-align: center;
			font-weight: 500;
			position: sticky;
			z-index: 1;
			margin-top: 16px;
		}
		img{
			width:100%;
			height: 100%;
			object-fit: cover;
			border-radius: 10px;
			box-shadow: #d0d0d0 0px 0px 8px 4px;
			position: absolute;
			top: 0;
		}
		.main{		
			padding: 16px;
			z-index: 1;
			position: sticky;
			.wrapper{
				display: flex;
				flex-direction: column;
				gap: 15px;
				.input_container{
					display: flex;
					align-items: center;
					justify-content: center;
					input{
						padding: 8px;
						flex: 1;
						border: none;
						outline: 1px solid var(--border-color);
						border-radius: 4px 0px 0px 4px;
					}
					svg{
						background-color: var(--red);
						padding: 10px;
						color: var(--white-color);
						border-radius: 0px 4px 4px 0px;
					}
				}
				button{
					width: 100px;
					margin: auto;
					padding: 8px;
					background-color: var(--danger);
					color: var(--white-color);
					border-radius: 4px;
					cursor: pointer;
				}
			}
		}
	}
}
@media screen and (min-width: 768px) {
	
}
@media screen and (min-width: 992px) {
	
}