.wrapper{
    background-color: var(--next-white);
	min-height: calc(100vh - 100px);
	padding-bottom: 50px;  
}

.holder{
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	h2{
		font-weight: 400;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 2px;
        text-transform: uppercase;
        font-size: 18px;
        width: max-content;
        margin: auto;
        letter-spacing: 1px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 15px;
	}
   
}

.filter_section{
	
	display: flex;
	
	align-items: center;
	gap: 20px;
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 4px;
    input{
		padding: 8px;
		flex: 1;
		border-radius: 4px;
		border: none;
		outline: 1px solid var(--gray);
	}
	button{
		padding: 8px 24px;
		background-color: var(--green);
		color: var(--white-color);
		&:last-child{
			background-color: var(--red);
		}
	}
}

.return_item{
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 8px;
	gap: 16px;
}

.table_wrapper {
	position: relative;
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 4px;
	overflow: auto;
	table {
		width: 100%;
		border-collapse: collapse;
		text-align: center;
		font-size: 14px;

		th {
			background-color: var(--teal);
			color: var(--white-color);
			border: 1px solid var(--white-color);
			padding: 4px;
		}

		tbody tr td {
			padding: 4px;
			border: 1px solid var(--white-color);

		}
		tbody tr:nth-child(even) {
			background-color: var(--lightb);
		}
	}

}
.info_section{
	display: flex;
	flex-direction: column;
	gap: 10px;
	.common{
		display: flex;
		gap: 16px;
	}
}
.button_section{
	display: flex;
	justify-content: center;
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 4px;
	gap: 16px;
	button{
		padding: 8px 20px;
		background-color: var(--orange);
		color: var(--white-color);
		&:first-child{
			background-color: var(--indigo);
		}
	}
}




@media only screen and (min-width: 992px) {
	.return_item{
	
		grid-template-columns: repeat(2, 1fr);
		
	}
}

