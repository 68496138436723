
.wrapper {
    margin-top: 15px;
    margin-bottom: 130px;
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 10px;

	h3{
		text-align: center;
		border-bottom: 1px solid var(--light-gray-color);
		padding-bottom: 1px;
		max-width: max-content;
		margin: auto;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 2px;
		font-size: 17px;
	}
	
}

.nameAtrribute {
	display: flex;
	padding: 10px;
	width: 100%;
	align-items: center;
	margin: auto;
	gap: 10px;

	.atributesec{
		display: flex;
	}
	
	input{
		
		border: none;
		border-radius: 4px;
		outline: 1px solid var(--border-color);
		padding: 8px;

	}
	input:focus{
		
		outline: 1px solid var( --outline-color);
		
	}
	.button_group {
		color: var(--white-color);
		border-radius: 5px;
		font-size: 14px;
		border: none;
		button{
			background-color: var(--primary);
			margin-right: 20px;
			color: var(--white-color);
			padding: 10px 24px;
			cursor: pointer;
			
		}
	}
	
}



.table_wrapper{
	table{
		width: 100%;
		margin-top: 20px;
	}
	td {
		text-align: center;
		padding: 5px 10px;
	}
	td:nth-child(3) {
		text-align: start;
	}
	tbody td:last-child{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	th {
		background-color: var(--teal);
		color: var(--white-color);
		padding: 10px 5px;
		font-size: 16px;
		text-align: center;
		
	}
	tr:nth-child(even) {
		background-color: var(--lightb);
	}
	tr{
		font-size: 14px;
	
	}
	.action_buttons{
		display: flex;
		gap: 12px;
		button{
			padding: 3px 10px;
			cursor: pointer;
			background-color: var(--green);
			border-radius: 4px;
			color: var(--white-color);
			transition: 0.3s;
			border: none;
			&:hover{
				background-color: var(--white-color);
				color: var(--green);
				outline: 1px solid var(--green);
				}
		}
		button:last-child{
			background-color: var(--danger);
			&:hover{
			background-color: var(--white-color);
			color: var(--danger);
			outline: 1px solid var(--danger);
			}
		}
	}
	

} 
