.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;
    h2 {
        font-weight: 400;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 2px;
        text-transform: uppercase;
        font-size: 18px;
        width: max-content;
        margin: auto;
        letter-spacing: 1px;
        text-align: center;
        margin-top: 10px;
           margin-bottom: 15px;
    }
  
	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

.tabActive {
	background-color: var(--primary);
	border-radius: 2px;
	font-weight: 300;
	color: var(--white-color) !important;
}

		.searchSection{
			display: flex;
			gap: 20px;
			margin-bottom: 20px;
		
            .input_section{
                flex: 1;
                gap: 8px;
                display: flex;
                flex-direction: column;
         
                    input{
                    
                        padding: 10px;
                        border-radius: 4px;
                        outline: 1px solid var(--border-color);
                        border: none;
                        &:focus{
                            outline: 1px solid var(--outline-color);
                        }					
                    }
					               	
                label{
                    font-size: 14px;
                    
                }
            }	
		}	
        .tableSection {
            position: relative;
            background-color: var(--white-color);
            padding: 10px;
            border-radius: 4px;
            overflow: auto;
            table {
                width: 100%;
                border-collapse: collapse;
                text-align: center;
                font-size: 14px;
        
                th {
                    background-color: var(--teal);
                    color: var(--white-color);
                    border: 1px solid var(--white-color);
                    padding: 8px;
                }
        
                tbody tr td {
                    padding: 8px;
                    border: 1px solid var(--white-color);
                    
                    svg{
                        font-size: 16px;
                        cursor: pointer;
                        &:first-child{
                            color:  var(--cyan);
                            margin-right: 5px;
                        }
                        &:last-child{
                            color: var(--primary);
                        }
                    }
                }
                tbody tr:nth-child(even) {
                    background-color: var(--lightb);
                }
            }
        
        }
		.buttonWrapper{
				display: flex;
				justify-content: end;
				align-items: center;
				gap: 20px;
			button{
				width: 150px;
				font-size: 16px;
				padding: 8px 15px;
				font-weight: 500;
				color: var(--white-color);
				background-color: var(--green);
				margin-bottom: 20px;
				text-transform: capitalize;
		
		}
		}
	
	}
	a{
		text-decoration: none;
		color: var(--cyan);
		
	}
	
}

.EditUserPop_wrapper{
    position:fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    content: " ";
    bottom: 0;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: var(--model-wrapper-color);

    .EditUserPop{
        background-color: var(--white-color);
        width: 50%;
        margin: auto;
        border-radius: 4px;
        padding: 20px;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 20px;
        
        .common{
            display: flex;
            flex-direction: column;
            gap: 4px;
            grid-template-columns: repeat(3,1fr);
            input{
                padding: 8px;
                border: none;
                outline: 1px solid var(--border-color);
            }

            label{
                color: var(--header-font-color);
            }
          
         
        }
     
        .buttosection{
            display: flex;
            gap: 20px; 
           grid-column: span 3;
           justify-content: center;
            button{
                padding: 8px;
                background-color: var(--green);
                color: var(--white-color);
               &:last-child{
                background-color: var(--red);
               } 
            }
        }
       
    }
    .ViewUserPop{
        background-color: var(--white-color);
        width: 50%;
        margin: auto;
        border-radius: 4px;
        padding: 20px;
        .buttosection{
            display: flex;
            justify-content: center;
            margin-top: 10px;
            button{
                padding: 8px;
                background-color: var(--red);
                color: var(--white-color);
               margin: auto;
            }
        }
      
       
        .textWrapper{
        display: flex;
        flex-direction: column;
        gap: 10px;
            p{
                display: flex;
                justify-content: center;
                gap: 10px;
            }
           
        }
    }
}