.salesMain{
	background-color: var(--next-white);
	padding: 20px 30px 70px 30px;
	

	h2 {
		font-weight: 400;
		border-bottom: 1px solid var(--border-color);
		padding-bottom: 2px;
		text-transform: uppercase;
		font-size: 18px;
		width: max-content;
		margin: auto;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 10px;
		   margin-bottom: 15px;
	}
	.innerSale{
		
		padding: 30px;
		border-radius: var(--modal-radius);
		&:nth-of-type(1){
			background-color: var(--white-color);
			padding-bottom: 10px;
		}
		&:nth-of-type(2){
			background-color: var(--white-color);
			margin-top: 30px;
			padding-bottom: 10px;
		}
		&:nth-of-type(3){
			background-color: var(--white-color);
			margin-top: 30px;
			padding-bottom: 10px;
		}
		h6 {
			font-weight: 400;
			border-bottom: 1px solid var(--border-color);
			padding-bottom: 2px;
			text-transform: uppercase;
			font-size: 16px;
			width: max-content;
			margin: auto;
			letter-spacing: 1px;
			text-align: center;
			margin-top: 10px;
			   margin-bottom: 15px;
			   color: var(--primary);
		}
		.inputWrapper{
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 20px;
			margin-bottom: 20px;
			.inputSection{
				display: flex;
				flex-direction: column;
				gap: 10px;
				input{

					padding: 10px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
				textarea{

					padding: 10px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					margin: 0;
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
			}
		}	
		.inputWrapperInformation{
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 20px;
			margin-bottom: 20px;
			.inputSection{
				display: flex;
				flex-direction: column;
				gap: 10px;
				input{
					
					padding: 10px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
				textarea{

					padding: 10px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					margin: 0;
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
			}
		}

		.buttonWrapper{
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 20px;
				
			button{
				
				width: 100px;
				font-size: 16px;
				padding: 8px 15px;
				font-weight: 500;
				color: var(--white-color);
				background-color: var(--green);
				margin-bottom: 20px;
				text-transform: capitalize;
				&:last-child{
					background-color: var(--cyan);
			}
		}
		}
		
	
	}
	


	
}