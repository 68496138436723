.heading{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;
	h2 {
		font-weight: 400;
		border-bottom: 1px solid var(--border-color);
		padding-bottom: 2px;
		text-transform: uppercase;
		font-size: 18px;
		width: max-content;
		margin: auto;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 10px;
   		margin-bottom: 15px;
	}
	.inner_headign{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);
	

			
			.input_section2{
				display: flex;
				gap: 20px;
				.common{
					display: flex;
					flex: 1;
					justify-content: center;
					align-items: center;
					gap: 10px;

					label{
						font-size: 14px;
						margin-bottom: 5px;
						width: 100px;
					}
					input{
						width: 100%;
						padding: 6px;
						border-radius: 4px;
						outline: none;
						border: 1px solid var(--border-color);
						
						&:focus{

							outline: 1px solid var(--outline-color);
						}	
						
					}
					
				}
			}
			.input_section{
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 20px;
				button{
					width: 100px;
					padding: 8px;
					font-size: 14px;
					font-weight: 500;
					color: var(--white-color);
					background-color: var(--cyan);
					
					}
			}
			
		

		
	}
}