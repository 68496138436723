.salesMain{
	background-color: var(--next-white);
	padding: 30px;
    position: relative;

    h2 {
        font-weight: 400;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 2px;
        text-transform: uppercase;
        font-size: 18px;
        width: max-content;
        margin: auto;
        letter-spacing: 1px;
        text-align: center;
        margin-top: 10px;
         margin-bottom: 15px;
       
    }
  
	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);
        margin-bottom: 20px;
        P{
            padding: 0px 20px 10px 20px;
        }
	

        .update_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
			margin-top: 20px;
        button{
            width: 200px;
            font-size: 16px;
            padding: 8px 15px;
            font-weight: 500;
            color: var(--white-color);
            background-color: var(--green);
           
            text-transform: capitalize;
    
    }
    }

	}
	
}
