.main{
    position: fixed;
    background-color: var(--model-wrapper-color);
    inset: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
}
.data_holder{
    background-color: var(--white-color);
    padding: 20px;
    width: 50%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  
}
.data_holder form{
    display: flex;
    flex-direction: column;
    gap: 10px;;
}
.data_holder h3{
    margin: 20px auto;
    /* width: 100%; */
    text-align: center;
    border-bottom: 2px solid var(--primary);
    max-width: max-content;
}
.common{
    display: flex;
    gap: 20px;
}
.common label{
   flex: 1;
}
.common input{
   flex: 3;
   padding: 8px;
   border-radius: 4px;
   border: none;
   outline: 1px solid var(--gray);
}
.common input:focus{
 outline: 2px solid var(--purple);
}
.data_holder .btns{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px auto;
}
.data_holder .btns button{
    background-color:var(--green);
    padding: 8px 16px;
    color: var(--white-color);
}
.data_holder .btns button:last-child{
    background-color: var(--red);
}