.input_wrapper{
   display: grid;
   grid-template-columns: repeat(1,1fr);
    gap: 15px;
    align-items: flex-end;
}
.main{
    background-color: var(--white-color);
    border-radius: 10px;
    padding: 10px;
}
.common{
    flex: 1;
    min-width: 30%;
    display: flex;
    flex-direction: column;
}
.common input{
   padding: 10px;
}
.extra_wrapper{
    display: flex;
    flex-wrap: wrap;
}
.extra_wrapper h4{
   width: 100%;
   text-align: center;
   border-bottom: 1px solid var(--border-color);
   margin: 0;
   padding: 0;
   padding-bottom: 3px;
   margin-bottom:10px ;
   font-size: 20px;
}
.inner_common{
    flex: 1;
    min-width: 30%;
    display: flex;
    flex-direction: column;
}
.image_holder{
    display: flex;
    gap: 10px;
}
.image_show{
    width: 100px;
    height: auto;
    margin-top: 15px;
}
.text_editor{
   margin-top: 20px;
}
.btn_group{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.btn_group button{
    background-color: var(--green);
   border-radius: 4px;
}
.btn_group button:hover{
    background-color: var(--green);
}

.common_att{
    flex: 1;
    min-width: 20%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.common_att input{
   padding: 8px;
   border: none;
   border-radius: 4px;
   border: 1px solid var(--border-color);
   outline: none;
}
.att_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 5px;
    margin-bottom: 10px;
    gap: 20px;
}
.att_head h4{
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: var(--header-font-color);
}
.att_buttons{
    display: flex;
    align-items: center;
}
.att_buttons button{
   background-color: var(--green);
   border-radius: 4px;
   color: var(--white-color);
   padding: 10px 20px;
   margin: 20px 0;
}
.submitbutton{
    background-color: var(--green);
   border-radius: 4px;
   color: var(--white-color);
   padding: 10px 20px;
   margin-top: 20px;
}
.tableWrapper{
    overflow: auto;
}
.main table{
    width: 100%;
}

.main table{
	width: 100%;
	margin-top: 20px;
}
.main table td {
	text-align: center;
	padding: 5px 10px;
}

.main table tbody td:nth-child(3) {
	text-align: start;
}
.main table tbody td:last-child{
	display: flex;
	align-items: center;
	justify-content: center;

}
.main table th {
	background-color: var(--teal);
	color: var(--white-color);
	padding: 14px;
	font-size: 16px;
	text-align: center;
	
}
.main table tr:nth-child(even) {
	background-color: var(--td-color);
}
.main table tr{
	font-size: 14px;

}

@media  screen and (min-width: 768px) {
	.input_wrapper{
        grid-template-columns: repeat(2,1fr);
        
     }
}
@media  screen and (min-width: 992px) {
	.input_wrapper{
        grid-template-columns: repeat(4,1fr);
        
     }
}
