.wrapper {
	background-color: var(--next-white);
	min-height: calc(100vh - 100px);
	padding-bottom: 50px;

	
}

.holder {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	h2{
		font-weight: 400;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 2px;
        text-transform: uppercase;
        font-size: 18px;
        width: max-content;
        margin: auto;
        letter-spacing: 1px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 15px;
	}

	.view_quantity {
		display: flex;
		gap: 10px;
		padding: 8px;
		border-radius: 8px;
		align-items: center;
		label{
			font-size: 1rem;
			font-weight: 600;
		}
		select {
			padding: 8px 30px;
			border-radius: 4px;
			border: none;
			box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
		}
	}

}

.search_holder {
	background-color: var(--white-color);
	padding: 20px;
	border-radius: 8px;
}

.table_wrapper {
	position: relative;
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 4px;
	overflow: auto;
	table {
		width: 100%;
		border-collapse: collapse;
		text-align: center;
		font-size: 14px;

		th {
			background-color: var(--teal);
			color: var(--white-color);
			border: 1px solid var(--white-color);
			padding: 4px;
		}

		tbody tr td {
			padding: 4px;
			border: 1px solid var(--white-color);

		}

		tbody tr td:nth-child(4) {
			text-align: left;
		}

		tbody tr td:nth-child(6) {
			text-align: left;
		}

		tbody tr:nth-child(even) {
			background-color: var(--lightb);
		}

		.user_wrapper {
			display: flex;
			gap: 10px;

			img {
				width: 20px;
				height: auto;
				border-radius: 5px;
				border: 1px solid var(--gray);
			}
		}

		.myStatus {
			color: var(--green);
            border-radius: 4px;
            font-weight: 700;
			text-align: center;
		}

		.action_buttons {
			display: flex;
			gap: 12px;
			align-items: center;
			justify-content: center;
			margin: 3px;
			a{
				svg{
					font-size: 16px;
				}
				
			}
			button {
				padding: 4px 10px;
				color: var(--white-color);
				background-color: var(--pink);
			  }
			
			  button:nth-child(4) {
				background-color: var(--teal);
			  }
			  .assigned{
				background-color: var(--gray)!important;
			  }
		}


		.confirmed {
			color: var(--green);
            border-radius: 4px;
            font-weight: 700;
		}
	}

}

.pagination {
	display: flex;
	gap: 20px;
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 8px;
	align-items: center;

	button {
		padding: 8px 28px;
		background-color: var(--primary);
		color: var(--white-color);
	}

	input {
		padding: 4px;
		border-radius: 4px;
		border: none;
		outline: 1px solid var(--gray);
	}
}
