.wrapper{
    background-color: var(--poup-background-color);
    inset: 0;
    position: fixed;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    .holder{
        background-color: var(--white-color);
        width: 50%;
        .head {
            display: flex;
            justify-content: center;
            padding: 16px;
            color: var(--primary-color);
          }
          .input_section{
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 0 20px;
          }
          .images{
            display: flex;
            gap: 30px;
            img{
                width: 300px;
                aspect-ratio: 1;
                height: auto;
            }
          }
    }
}