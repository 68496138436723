.wrapper {
  background-color: var(--white-color);
}
.table_wrapper {
  position: relative;
  padding: 16px;
  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    thead th {
      background-color: var(--pest-color);
      color: var(--white-color);
      padding: 4px;
      border: 1px solid var(--white-color);
    }

    tbody {
      tr:nth-child(even) {
        background-color: var(--light-puple);
      }
      td {
        border: 1px solid var(--border-color);
        padding: 4px;
      }
    }
  }
  .toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .toggle .on {
    color: var(--pest-color);
  }
  .toggle .off {
    color: var(--black-d);
  }
  .toggle:hover svg {
    color: var(--pink-color);
  }
  .action_buttons {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
    justify-content: center;
    margin: 8px 20px;
    button {
      padding: 8px 12px;
      border-radius: 4px;
      border: none;
      background-color: var(--black-c);
      color: var(--white-color);
      cursor: pointer;
      &:hover {
        background-color: var(--black-color);
      }
    }
  }
}
