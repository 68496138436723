
.details .client .info {

    font-size: 14px;
    margin-bottom: 3px;

}

 .details .client .info .moreinfo {
    font-weight: 600;
    min-width: 70px;
}

 .details .invoice .info {
    font-size: 14px;
    margin-bottom: 3px;
    display: flex;
}

 .details .invoice .info .moreinfo {
    font-weight: 600;
  
}
 .details .invoice .info .invoiceMoreinfo{
    width: 63px;
}


 .details .invoice .info {
    font-size: 14px;
    margin-bottom: 3px;
    display: flex;
}

 .details .invoice .info .moreinfo {
    font-weight: 600;
  
}
 .details .invoice .info .invoiceMoreinfo{
    width: 63px;
}

.table table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 0px !important;
}
.filter-data {
    margin-bottom: 15px;
}
 .table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

 .table thead tr {
    height: 20px;
}

 .table thead .unit {
    text-align: center;
    padding-right: 10px;
}
 .table thead .unit_price {
    text-align: right;
    padding-right: 10px;
}
 .table thead .total {
    text-align: right;
    padding-right: 10px;
}

 .table thead tr .no {
    color: #000;
    font-size: 13px;
    background: #fff;
    width: 32px;
}

 .table thead tr th {
    border: 1px solid #000;
}

 .table thead tr th {
    border: 1px solid #000;
    padding: 5px;
}

 .table tbody {
    display: table-row-group;
    vertical-align: middle;
    font-size: 13px;
    border-color: inherit;
}

 .table tbody tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
 .table tbody tr .no {
    color: #000;
    font-size: 13px;
    background: #fff;
    text-align: center !important;
}
 .table tbody tr .desc {
    text-align: left;
    padding: 4px 5px 4px 10px;
    width: 320px;
}
 .table tbody tr .desc p {
    margin: 5px 0px 0px 0;
    color: #000 !important;
    font-size: 11px;
    font-weight: 600;
}

 .table tbody tr .desc strong {
    font-weight: 500;
    font-size: 13px;
}

 .table tbody tr .unit {
    padding: 4px 5px 4px 5px;
    width: 57px;
    text-align: center !important;
}

 .table tbody tr .unit_price {
    padding: 4px 8px 4px 5px;
    width: 50px;
    text-align: right!important;
}
 .table tbody tr .total {
    padding: 4px 8px 4px 5px;
    width: 90px;
    text-align: right!important;
}

 .table tbody tr td {
    border: 1px solid #000 !important;
    margin: 3px 5px 3px 5px;
    background: #fff;
    color: #000;
}

 .table .tfoot {

    border-collapse: collapse;
    border-spacing: 0;
    border: none;
}
 .table .tfoot tr td {
    border: none !important;
    padding: 2px 7px;
    background: #FFFFFF;
    border-bottom: none;
    font-size: 14px;
    text-align: right !important;
}

 .table .tfoot tr td span {
    border-bottom: 1px solid #000;
    display: block;
    width: 260px;
    float: right;
}

 .table .tfoot .gran_total_text {
    width: 100px;
}

 .table .tfoot .gran_total {
    padding: 0px 4px;
    font-weight: bold
}

 .table .tfoot .pre_Paid_text {
    width: 100px;
}

 .table .tfoot .pre_Paid {
    padding: 0px 4px;
    font-weight: bold
}

 .table .tfoot .amount {
    padding: 0px 4px;
    font-weight: bold
}

 .table .tfoot .amount_text {
    width: 100px;
}


.final_row td {
    font-weight: bold;
 
}
.final_row  td:nth-child(1){
    text-align: right!important;
}
.final_row  td:nth-child(2){
    text-align: right!important;
}

.no_border_right {
    border-right: none !important;
    border-left: none !important;
}

.no_border_right:first-child {
    border: 1px solid black !important;
    text-align: right !important;
    padding-right: 5px !important;
}