.wrapper {
  background-color: var(--next-white);
  min-height: calc(100vh - 100px);
  padding-bottom: 50px;
}

.holder {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  h2 {
    font-weight: 400;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 2px;
    text-transform: uppercase;
    font-size: 18px;
    width: max-content;
    margin: auto;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.head {
  display: flex;
  align-items: center;
  gap: 10px;
}
.report_button button{
  padding: 8px;
  background-color: var(--teal);
  color: var(--white-color);
}
.search_holder {
  background-color: var(--white-color);
  padding: 20px;
  border-radius: 8px;
}

.table_wrapper {
  position: relative;
  background-color: var(--white-color);
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 14px;
    th {
      background-color: var(--teal);
      color: var(--white-color);
      border: 1px solid var(--white-color);
      padding: 4px;
    }
    tbody tr td {
      padding: 4px;
      border: 1px solid var(--white-color);
    }
    tbody tr td:nth-child(4) {
      text-align: left;
    }
    tbody tr td:nth-child(6) {
      text-align: left;
    }
    tbody tr:nth-child(even) {
      background-color: var(--lightb);
    }
    .user_wrapper {
      display: flex;
      gap: 10px;
      img {
        width: 20px;
        height: auto;
        border-radius: 5px;
        border: 1px solid var(--gray);
      }
    }
    .myStatus {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      svg {
        width: 80%;
        margin: auto;
        background-color: var(--cyan);
        padding: 4px;
        border-radius: 4px;
        color: var(--white-color);
        cursor: pointer;
      }
    }
    .action_buttons {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      a {
        text-decoration: none;

        svg {
          font-size: 16px;
          padding: 2px;
        }
      }
    }
    .confirmed {
      color: var(--green);
      border-radius: 4px;
      font-weight: 700;
    }
    button {
      padding: 4px 10px;
      color: var(--white-color);
      background-color: var(--pink);
    }
  
    button:nth-child(5) {
      background-color: var(--teal);
    }
    .assigned{
      background-color: var(--gray)!important;
    }
  }
}
