.wrapper {
	background-color: var(--next-white);
	min-height: calc(100vh - 100px);
	padding-bottom: 50px;

	h2 {
		font-weight: 400;
		border-bottom: 1px solid var(--border-color);
		padding-bottom: 2px;
		text-transform: uppercase;
		font-size: 18px;
		width: max-content;
		margin: auto;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 10px;
		   margin-bottom: 15px;
	}
}

.holder {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;

}

.head {
	display: flex;
	align-items: center;
	gap: 10px;

	.view_quantity {
		display: flex;
		gap: 20px;
		background-color: var(--white-color);
		padding: 8px;
		border-radius: 8px;
		align-items: center;

		select {
			padding: 4px 30px;
			border-radius: 4px;
			border: none;
			outline: 1px solid var(--gray);
		}
	}

}

.search_holder {
	background-color: var(--white-color);
	padding: 20px;
	border-radius: 8px;
}

.table_wrapper {
	position: relative;
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 4px;
	overflow: auto;

	table {
		width: 100%;
		border-collapse: collapse;
		text-align: center;
		font-size: 14px;

		th {
			background-color: var(--teal);
			color: var(--white-color);
			border: 1px solid var(--white-color);
			padding: 4px;
		}

		tbody tr td {
			padding: 4px;
			border: 1px solid var(--white-color);

		}

		tbody tr td:nth-child(4) {
			text-align: left;
		}

		tbody tr td:nth-child(6) {
			text-align: left;
		}

		tbody tr:nth-child(even) {
			background-color: var(--lightb);
		}

		.user_wrapper {
			display: flex;
			gap: 10px;

			img {
				width: 20px;
				height: auto;
				border-radius: 5px;
				border: 1px solid var(--gray);
			}
		}

		.myStatus {
			border-radius: 4px;
			text-align: center;
			font-size: 12px;
			padding: 4px;
			gap: 2px;
			display: flex;
			color: var(--red);
			font-weight: 600;
			button{
				background-color: var(--cyan);
				padding: 3px 4px;
				color: var(--white-color);
				
			}
		}

		.action_buttons {
			display: flex;
			gap: 8px;
			align-items: center;
			justify-content: center;

			a {
				text-decoration: none;
				color: var(--white-color);
				margin: 3px;
				svg{
					font-size: 16px;
					
				}
			}

			button {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: var(--cyan);
				padding: 8px;
				color: var(--white-color);
			}
		}
	}

}

.deliverydone_wrapper{
	position: fixed;
    background-color: var(--model-wrapper-color);
    inset: 0;
    display: flex;
    align-items: center;
    z-index: 1000;
    justify-content: center;

	.deliverydone{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: 4px;
		width: 40%;
		h3{
			font-size: 20px;
			text-align: center;
			margin-bottom: 25px;
			border-bottom: 1px solid var(--border-color);
		}
		.common{
			display: flex;
			flex-direction: column;
			gap: 20px;
			input{
				padding: 10px;
				border: none;
				border-radius: 2px;
				outline: 1px solid var(--border-color);	
			}
			.deliverydone_btns{
				display: flex;
				gap: 30px;
				justify-content: center;

				button{
					background-color: var(--cyan);
					padding: 8px 15px;
					color: var(--white-color);
					&:last-child{
						background-color: var(--red);
					}
				}
				
			}
		}
		
	}
	
}


