.iteminfo{
    display: flex;
    width: 100%;
}
.my_form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .input_wrapper{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        background-color: var(--white-color);
        padding: 24px;
        border-radius: 8px;
        gap: 12px;
        .common{
            display: flex;
            flex-direction: column;
            gap: 8px;
            input{
                padding: 10px;
                border-radius: 5px;
                border: 1px solid var(--border-color);
                background-color: var(--white);
            }
        }
    }
    .input_jodit{
        display: flex;
        flex-direction: column;
        gap: 12px;
        background-color: var(--white-color);
        padding: 24px;
        border-radius: 8px;
        h4{
           text-align: center;
        }
    }
    .btn_group{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        button{
            background-color: var(--green);
            border-radius: 4px;
            color: var(--white-color);
            padding: 10px 20px;
            font-size: 14px;
            margin-top: 20px;
            &:hover{
                background-color: var(--primary);
            }
         }
    }  
}

@media  screen and (min-width: 768px) {
	.my_form{
		.input_wrapper {
			grid-template-columns: repeat(2,1fr);
		}
	}
}
@media  screen and (min-width: 992px) {
	.my_form{
		.input_wrapper {
			grid-template-columns: repeat(3,1fr);
		}
	}	
}