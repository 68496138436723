.invoice_main {
  position: fixed;
  inset: 0;
  z-index: 101;
  background-color: var(--white-color);
  overflow: auto;
  padding-bottom: 70px;
}
.printBtn {
  position: fixed;
  right: 50px;
  top: 50px;
  padding: 8px 24px;
  background-color: var(--primary);
  color: var(--white-color);
  &:hover {
    background-color: var(--white-color);
    color: var(--primary);
    outline: 1px solid var(--primary);
    transition: 0.3s;
  }
}
.contents {
  width: 80%;
  margin: auto;
  img {
    width: 100%;
    height: auto;
    position: sticky;
    top: 0;
    z-index: 3;
  }
}

@media print {
  @page {
    size: A4; /* Set paper size */
    margin: 10px 20px; /* Set margin */
    background-color: rebeccapurple;
  }

  .invoice_main {
    position: relative;
  }

  .printBtn {
    display: none; /* Hide the print button */
  }

  .contents {
    width: 94%; /* Set the width of contents */
    margin: 0 auto; /* Center the contents horizontally */
  }
}
