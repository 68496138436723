.wrapper{
    inset: 0;
    background-color: var(--model-wrapper-color);
    z-index: 100;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;

    .holder{
        background-color: var(--white-color);
        padding: 20px;
        border-radius: 4px;
        position: relative;
        width: 50%;
        max-height: 80vh;
        overflow: auto;
        .product_price{
            display: flex;
            justify-content:space-between;

            p{
                font-weight: 600;
            }
        }
        h4{
            text-align: center;
            font-weight: 400;
            text-transform: uppercase;
            font-size: 20px;
            padding: 4px;
            border-radius: 2px;
            margin-bottom: 10px;
        }
        h2{
            text-align: center;
            font-weight: 400;
            text-transform: uppercase;
            font-size: 20px;
        }

        .table_wrapper{
            table{
                width: 100%;
                border-collapse: collapse;
                text-align: center;
                margin-top: 20px;
                thead{
                  
                    th{
                        background-color: var(--cyan);
                        border: 1px solid var(--white-color);
                        color: var(--white-color);
                        padding: 8px;
                    }	
                }
                tbody{
            
                    td{
                        border: 1px solid var(--white);
                        padding: 8px;
                        &:nth-child(2){
                            text-align: left;
                        }
                    }
                   tr:nth-child(even) {
                        background-color: var(--lightb);
                    }
                }
             
            }
          
        }
        .button_Wrapper{
            display: flex;
            justify-content: center;
            gap: 12px;
            margin-top: 20px;
            button{
                padding: 8px 15px;
                background-color: var(--red);
                color: var(--white-color);
                &:first-child{
                    background-color: var(--red);
                }
            }
        }
    }

    .info_section{
        display: flex;
        gap: 20px;
        h4{
            text-align: left;
            margin: 0;
            font-size: 1rem;
            font-weight: 500;
            padding: 0;
        }
    }
    
}
