
.pop_wrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index : 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000090;
}
.pop_holder {
    background: var(--white-color);
    width: 30%;
    padding: 25px;
    border-radius: 20px;
    text-align:center;
    display: flex;
    flex-direction: column;
    gap: 20px;

}
.icon{
    font-size: 90px;
    margin: 20px auto;
    color : var(--cyan);
   
}



.popup_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.popup_btns button{
    padding: 10px 32px; 
    color : var(--white-color);
    border: none;
    background: var(--cyan);
    cursor: pointer;
    border-radius: 4px;
}

.popup_btns button:last-child{
    background: var(--danger);
 }