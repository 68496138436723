.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;

	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

		h2 {
			font-weight: 400;
			border-bottom: 1px solid var(--border-color);
			padding-bottom: 2px;
			text-transform: uppercase;
			font-size: 18px;
			width: max-content;
			margin: auto;
			letter-spacing: 1px;
			text-align: center;
			margin-top: 10px;
			   margin-bottom: 15px;
		}
		p{
			font-size: 16px;
			margin-bottom: 20px;
			text-align: center;
		}
		.addsupplier{
			display: flex;
			gap: 20px;
			margin-bottom: 20px;
		
			.supplierinput{
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 10px;
				label{
					font-size: 14px;
					
				}
				input{
					
					padding: 8px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
				select{
					padding: 8px;
					border-radius: 4px;
					border: none;
					border: 1px solid var(--border-color);		
					
					&:focus{
						outline: 1px solid var(--outline-color);
					}	
				}
				
				
			}
			
			
		}	
		.buttonsection{
				display: flex;
				justify-content: end;
				align-items: center;
				gap: 20px;
			button{
				width: 100px;
				font-size: 16px;
				padding: 8px 15px;
				font-weight: 500;
				color: var(--white-color);
				background-color: var(--green);
				margin-bottom: 20px;
				text-transform: capitalize;
				&:last-child{
					background-color: var(--cyan);
			}
		}
		}
		
	
	}
	


}