.creditVoucherCustomerWrapper{
	
	.input_section{
	
		display: flex;
		gap: 20px;
		width: 50%;
		margin: auto;
		align-items: center;
		.input_wrapper{
			flex: 3;
			
			input{
				padding: 8px;
				width: 100%;
				border-radius: 4px;
				border: 1px solid var(--border-color);
				outline: none;
				}
		}

	  .search_data{
		flex: 1;
		button{
			
			padding: 8px 15px;
			font-weight: 500;
			color: var(--white-color);
			background-color: var(--green);
			margin: 20px 0;
			
		}	
	  }
	}


}

