.heading{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;
	h2 {
		font-weight: 400;
		border-bottom: 1px solid var(--border-color);
		padding-bottom: 2px;
		text-transform: uppercase;
		font-size: 18px;
		width: max-content;
		margin: auto;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 10px;
   		margin-bottom: 15px;
	}
	.inner_headign{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);
		
		.common{
			display: flex;
			flex: 1;
			justify-content: center;
			align-items: center;
			gap: 10px;
			margin-bottom: 20px !important;
			width: 50%;
			margin: auto;
			label{
				font-size: 14px;
				margin-bottom: 5px;
				width: 100px;
				
			}
			input{
				width: 100%;
				padding: 8px;
				border-radius: 4px;
				border: 1px solid var(--border-color);
				&:focus{

					outline: 1px solid var(--outline-color);
				}
			}
			select{
				width: 100%;
				padding: 8px;
				border-radius: 4px;
				border: 1px solid var(--border-color);
				&:focus{

					outline: 1px solid var(--outline-color);
				}
			}
			button{
				padding: 8px 10px;
				font-weight: 500;
				color: var(--white-color);
				border-radius: 4px;
				
					
					&:nth-child(1){
						background-color: var(--cyan);
	
					}
					&:nth-child(2){
						background-color: var(--primary);
	
					}
					
			}
		}
	
	}


}