.wrapper {
  background-color: var(--next-white);
  min-height: calc(100vh - 100px);
  padding-bottom: 50px;
  .holder {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    h2 {
      font-weight: 400;
      border-bottom: 1px solid var(--gray-color);
      padding-bottom: 2px;
      text-transform: uppercase;
      font-size: 18px;
      width: max-content;
      margin: auto;
      letter-spacing: 1px;
    }
    .table_wrapper {
      position: relative;
      background-color: var(--white-color);
      padding: 10px;
      border-radius: 4px;
      overflow: auto;
      table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        font-size: 14px;
        th {
          background-color: var(--teal);
          color: var(--white-color);
          border: 1px solid var(--white-color);
          padding: 4px;
        }
        tbody tr td {
          padding: 4px;
          border: 1px solid var(--white-color);
        }
        tbody tr:nth-child(even) {
          background-color: var(--lightb);
        }
      }
    }
  }
}
