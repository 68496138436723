
.comp_search {
	max-width: 100% !important;
	align-items: center;
    position: relative;

    input {
        width: 100%;
        padding: 10px;
        border: none;
        outline: 1px solid var(--border-color);
        border-radius: 2px;
    }

    .search_item_section {
        position: absolute;
        top: 100%;
        background-color: var(--cyan);
        width: 100% !important;
    }
    .dropdown_list {
        margin-top: 5px !important;
        width: 100% !important;
        z-index: 1000;
    }
}





.dropdown_list {
	position: absolute;
	width: 50%;
	top: 45px;
}

.dropdown_list p {
    padding: 5px 10px;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    color: var(--white-color);
   
    font-size: 14px;
    
    &:hover {
        background-color: var(--outline-color);
        color: var(--white-color);
    }
    
    span{
        color: var(--black-color);
       
    }
}





