.ToggleSwitchon{
	display: flex;
	align-items: center;
	justify-content: center;
}
.ToggleSwitchon svg {
	color: #04AA6D;
	font-size: 20px;
}

.ToggleSwitchon p {
	position: absolute;
	height: 25px;
	width: 40px;
	margin-top: 10px;
	background-color: red;
	opacity: 0;
	cursor: pointer;
}

.ToggleSwitchoff{
	display: flex;
	align-items: center;
	justify-content: center;
}
.ToggleSwitchoff svg {
	color: gray;
	font-size: 20px;
}
.ToggleSwitchoff p {
	position: absolute;
	height: 25px;
	width: 40px;
	margin-top: 10px;
	background-color: red;
	opacity: 0;
	cursor: pointer;
}