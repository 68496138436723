.wrapper{
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	background-color: var(--model-wrapper-color);
}
.load_wrapp {
	/* margin: 0 10px 10px 0; */
	padding: 20px 20px 20px;
	border-radius: 5px;
	text-align: center;
	background-color: white;
	height: 150px;
	display: flex;
	align-items: center;
  }
  .load_3 p{
	color: var(--gray-color);
  }
  .load_3 h3{
	color: var(--navy-blue-color);
  }
  .line {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 15px;
	background-color: var(--purple-color);
	margin: 0 5px;
  }
  .load_3 .line:nth-last-child(1) {
	animation: loadingC 1s 0.3s linear infinite;
  }
  .load_3 .line:nth-last-child(2) {
	animation: loadingC 1s 0.4s linear infinite;
  }
  .load_3 .line:nth-last-child(3) {
	animation: loadingC 1s 0.5s linear infinite;
  }
  @keyframes loadingC {
	0%{
	  transform: translate(0, 0);
	}
	50% {
	  transform: translate(0, 30px);
	}
	100% {
	  transform: translate(0, 0);
	}
  }