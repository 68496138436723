
.adminfooter {
	background-color: white;
	color: black;
	padding: 8px 0;
	text-align: center;
	font-size: 14px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	z-index: 2;
}

.adminfooter .mylink {
	cursor: pointer;
	color: #d30101;
}

@media print {
	.adminfooter{
		display: none;
	}
}