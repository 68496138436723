.wrapper{
    background-color: var(--next-white);
	padding: 30px;
    .innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);
        .my_form{
            display: grid;
            grid-template-columns: repeat(1,1fr);
            gap: 8px;
            .form_inner{
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
        }
        .table_wrapper{
            position: relative;
            background-color: var(--white-color);
            padding: 10px;
            border-radius: 4px;
            overflow: auto;

            table {
                width: 100%;
                border-collapse: collapse;
                text-align: center;
                font-size: 14px;
        
                th {
                    background-color: var(--teal);
                    color: var(--white-color);
                    border: 1px solid var(--white-color);
                    padding: 8px;
                }
        
                tbody tr td {
                    padding: 8px;
                    border: 1px solid var(--white-color);
        
                }
                tbody tr:nth-child(even) {
                    background-color: var(--lightb);
                }
            }
        }

        .btn_group{
            display: flex;
            justify-content: end;
            align-items: center;
            margin-top: 10px;
        button{
            width: 150px;
            font-size: 16px;
            padding: 8px 15px;
            font-weight: 500;
            color: var(--white-color);
            background-color: var(--green);
            margin-bottom: 20px;
            text-transform: capitalize;
    
    }
    }
    }
}

@media only screen and (min-width: 992px) {
    .wrapper{
        .innerSale{
            .my_form{
                
                grid-template-columns: repeat(2,1fr); 
            }
        }
    
    }
			

}
