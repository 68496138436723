.wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
	inset: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;

}

.holder {
    padding: 20px;
    background: var(--white-color);
    margin: auto;
    border-radius: var(--modal-radius);
    width: 50%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 16px;
    h3{
        text-align: center;
        color: var(--teal);
    }
}
.icon{
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        font-size: 5rem;
        color: var(--info);
    }
}
.information{
    display: flex;
    flex-direction: column;
    gap: 8px;
    h2{
        text-align: center;
        color: var(--secondary);
    }
    p{
        text-align: center;
        color: var(--primary);
    }
}
.button_section{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    button{
        padding: 8px 40px;
            background-color: var(--green);
            color: var(--white-color);
            &:first-child{
                background-color: var(--red);
            }
    }
}