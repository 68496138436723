.salesMain {
  background-color: var(--next-white);
  padding: 30px;
  min-height: 100vh;
  position: relative;
  h2 {
    font-weight: 400;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 2px;
    text-transform: uppercase;
    font-size: 18px;
    width: max-content;
    margin: auto;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .innerSale {
    background-color: var(--white-color);
    padding: 30px;
    border-radius: var(--modal-radius);

    .searchSection {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;

      .common {
        flex: 1;
        gap: 8px;
        display: flex;
        flex-direction: column;
        select {
          padding: 10px;
          border-radius: 4px;
          outline: 1px solid var(--border-color);
          border: none;
        }
        input {
          padding: 10px;
          border-radius: 4px;
          outline: 1px solid var(--border-color);
          border: none;
          &:focus {
            outline: 1px solid var(--outline-color);
          }
        }

        label {
          font-size: 14px;
        }
      }
    }
    .tableSection {
      position: relative;
      background-color: var(--white-color);
      padding: 10px;
      border-radius: 4px;
      table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        font-size: 14px;

        th {
          background-color: var(--teal);
          color: var(--white-color);
          border: 1px solid var(--white-color);
          padding: 8px;
        }

        tbody tr td {
          padding: 8px;
          border: 1px solid var(--white-color);
        }
        tbody tr:nth-child(even) {
          background-color: var(--lightb);
        }
        img {
          width: 100px;
        }
        .action_buttons {
          display: flex;
          justify-content: center;
          gap: 12px;
          input {
            padding: 3px 10px;
            cursor: pointer;
            background-color: var(--green);
            border-radius: 4px;
            color: var(--white-color);
            transition: 0.3s;
            border: none;

            a {
              text-decoration: none;
              color: var(--white-color);
            }
            &:hover {
              background-color: var(--white-color);
              color: var(--green);
              outline: 1px solid var(--green);
              a {
                color: var(--green);
              }
            }
          }
          input:last-child {
            background-color: var(--danger);
            &:hover {
              background-color: var(--white-color);
              color: var(--danger);
              outline: 1px solid var(--danger);
            }
          }
        }
      }
    }
    .buttonWrapper {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 20px;
      button {
        width: 200px;
        font-size: 16px;
        padding: 8px 15px;
        font-weight: 500;
        color: var(--white-color);
        background-color: var(--green);
        margin-bottom: 20px;
        text-transform: capitalize;
      }
    }
  }

  .re_arrange_slide {
    position: fixed;
    background-color: var(--model-wrapper-color);
    inset: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    .popUp {
      background-color: var(--white-color);
      padding: 20px;
      width: 50%;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 80%;
      overflow: auto;

      .header {
        position: sticky;
        top: -20px;
        background-color: var(--white-color);
        .slide_title {
          display: flex;
          justify-content: end;
          align-items: center;
          gap: 20px;
          button {
            margin-top: 10px;
            width: 200px;
            font-size: 16px;
            padding: 8px 15px;
            font-weight: 500;
            color: var(--white-color);
            background-color: var(--green);
            margin-bottom: 20px;
            text-transform: capitalize;
          }
        }
      }

      ul {
        li {
          img {
            width: 90%;
          }
          list-style: none;
          text-align: center;
          p {
            font-size: 16px;
            margin: 15px 0 5px 0;
            color: var(--primary);
          }
        }
      }
    }
  }

  .edit_popup_wrapper {
    position: fixed;
    background-color: var(--model-wrapper-color);
    inset: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;

    .edit_popup {
      background-color: var(--white-color);
      padding: 20px;
      width: 50%;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 80vh;
      overflow: auto;

      .common {
        flex: 1;
        gap: 8px;
        display: flex;
        flex-direction: column;

        input {
          padding: 10px;
          border-radius: 4px;
          outline: 1px solid var(--border-color);
          border: none;
          &:focus {
            outline: 1px solid var(--outline-color);
          }
        }
        select {
          padding: 10px;
          border-radius: 4px;
          margin-left: 0;
          outline: 1px solid var(--border-color);
          border: none;
          &:focus {
            outline: 1px solid var(--outline-color);
          }
        }

        label {
          font-size: 14px;
        }
      }
      .update_btns {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 20px;
        button {
          margin-top: 10px;
          width: 200px;
          font-size: 16px;
          padding: 8px 15px;
          font-weight: 500;
          color: var(--white-color);
          background-color: var(--green);
          margin-bottom: 20px;
          text-transform: capitalize;

          &:last-child {
            background-color: var(--danger);
          }
        }
      }
      img {
        width: 200px;
      }
    }
  }
}
