.addnewiteminfo {
	width: 95%;
	margin: auto;
}
.addnewiteminfo hr {
	margin: 20px 0;
	width: 100%;

}

.addnewiteminfo h3 {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 16px;
	border-bottom: 1px solid var(--primary);
	max-width: max-content;
	margin: auto;
	margin-top: 10px;
	margin-bottom: 8px;
}
.admincomm {
	margin-top: 15px;
	margin-bottom: 130px;
}
.disBlock {
	display: block;
}
.disNone {
	display: none;
}