.debitVoucherCustomerWrapper{
	
	.input_section{
		width: 50%;
		margin: auto;
		.input_wrapper{
			background-color: var( --lightb);
			padding: 10px;
			border-radius: 4px;
			
			p{
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 5px;
			}
		}

	  .search_data{
			display: flex;
			justify-content: center;
		button{
			padding: 8px 15px;
			font-weight: 500;
			color: var(--white-color);
			background-color: var(--green);
			margin: 20px 0;
			
		}	
	  }
	}

	.tableSection{
		table{
			width: 100%;
		}
		th {
			background-color: var(--teal);
			color: var(--white-color);
			padding: 8px;
		}
		tbody tr:nth-child(even) {
			background-color: var(--lightb);
		}
		tr{
			text-align: center;
			
		}
		td{
			padding: 5px;
		}
		 td:nth-child(3) {
			text-align: start;
		}

		.other_information{
			
			.text_section{
				margin: 25px 0;
    			text-align: right;
				p{
					font-size:16px;
					font-weight: 400;
					margin-top: 10px;
					
				}
			}
			.payment_info{
				display: flex;
				flex-direction: column;
				justify-content: end;
				gap: 20px;
				.input_section{
					display: flex;
					gap: 30px;
					align-items: center;
					width: 100%;
					label{
						font-size: 14px;
						flex: 1;
						text-align: right;
					}
					select{
						flex: 1;
						width: 100%;
						padding: 8px;
						border-radius: 4px;
						
						border: 1px solid var(--border-color);
						&:focus{
							outline: 1px solid var(--outline-color);
						}
					}
					input{
						flex: 1;
						width: 100%;
						padding: 8px;
						border-radius: 4px;
						
						border: 1px solid var(--border-color);
						&:focus{
							outline: 1px solid var(--outline-color);
						}
					}
					
				}
				.button_section{
					display: flex;
					justify-content: end;

					button{
					width: 100px;
					font-size: 16px;
					padding: 8px 15px;
					font-weight: 500;
					color: var(--white-color);
					background-color: var(--green);
					margin: 20px 0;
					
				}	
				}
				

			}
		}
	
	}
}

