.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	margin-top: 0 !important;
	
    h2 {
        font-weight: 400;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 2px;
        text-transform: uppercase;
        font-size: 18px;
        width: max-content;
        margin: auto;
        letter-spacing: 1px;
        text-align: center;
        margin-top: 10px;
           margin-bottom: 15px;
    }
  
	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

.tabActive {
	background-color: var(--primary);
	border-radius: 2px;
	font-weight: 300;
	color: var(--white-color) !important;
}

		.searchSection{
			display: grid;
			grid-template-columns: repeat(1,1fr);
			gap: 20px;
			margin-bottom: 20px;
			
            .input_section{
               
                gap: 8px;
                display: flex;
                flex-direction: column;
         
                    input{
                    
                        padding: 10px;
                        border-radius: 4px;
                        outline: 1px solid var(--border-color);
                        border: none;
                        &:focus{
                            outline: 1px solid var(--outline-color);
                        }					
                    }
					               	
                label{
                    font-size: 14px;
                    
                }
            }	
		}	
		.textandimage{
			
			display: grid;
			grid-template-columns: repeat(1,1fr);
			gap: 20px;
            .mTextArea{
                gap: 10px;
                display: flex;
                flex-direction: column;
	
				textarea{ 
                       
						min-height: 200px;
                        border-radius: 4px;
                        outline: 1px solid var(--border-color);
                        border: none;
                        &:focus{
                            outline: 1px solid var(--outline-color);
                        }					
                    }
				
					               	
                label{
                    font-size: 14px;
					                  
                }
            }
			.input_section{		
				 display: flex;
				 flex-direction: column;
				 gap: 10px;
				 flex: 1;
				 
				input{
					padding: 10px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
				img{
					width: 100px;
					border: 1px solid var(--border-color);
					border-radius: 4px;
				}
								   
			label{
				font-size: 14px;
				
			}
		}		
		}	
   
		.buttonWrapper{
				display: flex;
				justify-content: end;
				align-items: center;
				gap: 20px;
			button{
				width: 150px;
				font-size: 16px;
				padding: 8px 15px;
				font-weight: 500;
				color: var(--white-color);
				background-color: var(--green);
				margin-bottom: 20px;
				text-transform: capitalize;
		
		}
		}
	
	}

	
}


@media only screen and (min-width: 992px) {
	.salesMain{
		.innerSale{
			.searchSection{
					
				grid-template-columns: repeat(4,1fr);
				
			}
			.textandimage{
				grid-template-columns: repeat(2,1fr);
			}
		}
				
	}
	

}