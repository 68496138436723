/* 453 css */
.wrapper{
    background-color: var(--next-white);
	min-height: calc(100vh - 100px);
	padding-bottom: 50px;  

.holder{
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;
.search{
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 4px;

	input{
		padding: 8px;
		border: none;
		outline: 1px solid var(--border-color);
		border-radius: 2px;
		&:last-child{
			cursor: pointer;
		}
	}
}
.table_wrapper{
	position: relative;
	background-color: var(--white-color);
    padding: 10px;
    border-radius: 4px;
	overflow: auto;
	table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
		font-size: 14px;
        th {
            background-color: var(--teal);
            color: var(--white-color);
            border: 1px solid var(--white-color);
            padding: 4px;
        }
        tbody tr td{
            padding: 4px;
            border: 1px solid var(--white-color);
			
        }
        tbody tr:nth-child(even) {
            background-color: var(--lightb);
        }
    }
	.button_section{
		margin: 10px 0;
		display: flex;
		gap: 20px;
		justify-content: center;

		button{
			background-color: var(--cyan);
			color: var(--white-color);
			padding: 8px;

			&:last-child{
				background-color: var(--green);
			}
		}
	}
}
.return_input{
	display: flex;
	margin-top: 20px;
	.common{
		display: flex;
		align-items: center;
		gap: 20px;
	}
	input{
		padding: 8px;
		border: none;
		outline: 1px solid var(--border-color);
		border-radius: 2px;
	}
}
.info_section{
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 20px;
	.common{
		display: flex;
		gap: 20px;
	}
}
}

.popup_wrapper{
	background-color: var(--model-wrapper-color);
	inset: 0;
	z-index: 100;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	.popup_holder{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 400;
		text-align: center;

		button{
			margin-top: 20px;
			background-color: var(--green);
			padding: 5px 15px;
			color: var(--white-color);
		}
	}
}

}