.heading {
	/* background-color: #d7edff; */
	width: 100%;
	min-height: 100px;
	padding-bottom: 30px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.heading .inner-headign {
	width: 96%;
	margin: auto;
	padding-top: 10px;
	position: relative;
}

.heading h2 {
	margin: 0;
	padding: 0;
}

.heading .inner-headign .headign-h2 {
	color: var(--sky-blue-color);
	font-weight: 400;
	margin-bottom: 20px;
}

.heading .inner-headign .input_section {
	display: flex;
	align-items: flex-end;
}

.heading .inner-headign .input_section .common {
	flex-grow: 1;
}



.heading .inner-headign .input_section .common button {
	background-color: var(--sky-blue-color) !important;
	color: var(--white-color);
	border: none;
	border-radius: 10px;
	cursor: pointer;
	font-weight: bold;
}

.heading .inner-headign .input_section .common button:hover {
	background-color: var(--navy-blue-color);
}

.heading .inner-headign .input_section .common select {
	border: none;
	outline: 1px solid var(--sky-blue-color);
	border-radius: 10px;
}

.heading .inner-headign .input_section .common {
	display: flex;
	flex-direction: column;
	align-items: left;
	margin-right: 10px;
}

.heading .inner-headign .input_section .common input {
	border: none;
	outline: 1px solid var(--sky-blue-color);
	border-radius: 10px;
	padding: 5px;
}

.heading .inner-headign .input_section .common label {
	margin-bottom: 8px;
}
.input_section{
	margin-top: 20px;
	width: 100%;
}
.supplier-info {
	display: flex;
	margin-top: 20px;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.supplier-info p {
	border: 1px solid var(--navy-blue-color);
	text-align: center;
	padding: 10px 0;
	height: max-content;
	flex-grow: 1;
}

.supplier-info p:nth-child(2) {
	border-right: none;
	border-left: none;

}

.supplier-info p:nth-child(3) {
	border-right: none;
}
.tableSection table th {
	background-color: var(--dark-fresh-color);
	color: var(--white-color);
	/* border: 1px solid white; */
}

.tableSection table tr:nth-child(odd) {
	background-color: var(--light-fresh-color);
}

.tableSection table tr:nth-child(even) {
	background-color: var(--light-yellow-color);
}
.other_information {
	display: flex;
	flex-direction: column;
}

.other_information .payment-info {
	display: flex;
	flex-direction: column;
}

.other_information .text-section {
	display: flex;
	flex-direction: column;
}

.other_information .ledger-ballnce {
	margin: 10px 0;
	font-weight: bold;
}

.other_information .limit-due {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 10px 0;
	font-weight: bold;
}

.other_information div {
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
}

.other_information div .input_section {
	display: flex;
	margin: 10px 0;
	align-items: center;
}

.other_information div .input_section input {
	flex: 1;
	padding: 8px;
}

.other_information div .input_section label {
	flex: 1;
	text-align: right;
	margin-right: 20px;
}

.other_information .option-class {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.other_information .option-class label {
	flex: 1;
	text-align: right;
	margin-right: 20px;
}

.other_information .option-class select {
	flex: 1;
}
.WarningText {
	color: var(--red-color);
	font-style: italic;
	font-weight: bold;
	padding: 0;
	margin: 0;
	margin-top: 5px;
}
.input_section .input-wrapper select {
	padding: 8px;
	flex: 6;
}

.input_section .input-wrapper textarea {
	padding: 8px;
	flex: 6;
}