.salesMain {
	width: 100%;
	min-height: 200px;
	padding-bottom: 30px;
}

.salesMain .innerSale {
	width: 96%;
	margin: auto;
	padding-top: 10px;
	position: relative;
}

.salesMain h2 {
	margin: 0;
	padding: 0;
}

.salesMain .innerSale .saleHeading {
	font-weight: 400;
	border-bottom: 1px solid var(--gray-color);
	padding-bottom: 2px;
	text-transform: uppercase;
	font-size: 18px;
	width: max-content;
	margin: auto;
	letter-spacing: 1px;
}

.salesMain .innerSale .tableSection {
    background-color: var(--white-color);
    border-radius: 2px;
    overflow-x: auto;
}



.salesMain .innerSale tr th {
	background-color: var(--primary-color);
	color: var(--white-color);
}

.tableSection table th {
	background-color: var(--dark-fresh-color);
	color: var(--white-color);
	/* border: 1px solid white; */
}

.tableSection table tr:nth-child(odd) {
	background-color: var(--light-fresh-color);
}

.tableSection table tr:nth-child(even) {
	background-color: var(--light-yellow-color);
}


.tableDetails {
	text-align: right;
}

.tableDetails p {
	margin: 0;
	padding: 8px;
}

.tableDetails p:nth-child(even) {
	background-color: var(--light-fresh-color);
}

.tableDetails p:nth-child(odd) {
	background-color: var(--light-yellow-color);
}

.button_section button {
    padding: 8px 12px;
    background-color: var(--green-color);
    color: var(--white-color);
    border: none;
    border-radius: 2px;
    font-size: 18px;
}

.button_section button:last-child {
	background-color: var(--primary-color);
}

