.salesMain {
	width: 100%;
	min-height: 200px;
	padding-bottom: 30px;
}

.salesMain .innerSale {
	width: 96%;
	margin: auto;
	padding-top: 10px;
	position: relative;
}

.salesMain h2 {
	margin: 0;
	padding: 0;
}

.salesMain .innerSale .saleHeading {
	font-weight: 400;
	border-bottom: 1px solid var(--gray-color);
	padding-bottom: 2px;
	text-transform: uppercase;
	font-size: 18px;
	width: max-content;
	margin: auto;
	letter-spacing: 1px;
}

.manageuserMain .tableSection {
	overflow: auto;
}

.manageuserMain .tableSection table {
	margin-top: 0;
}

.tableSection table th {
	background-color: var(--th-color);
	color: var(--white-color);
	/* border: 1px solid white; */
}

.tableSection table td {
	text-align: center;
}

.tableSection table tr:nth-child(even) {
	background-color: var(--td-color);
}

.ToggleSwitchon svg {
	color: var(--light-green-color);
	font-size: 22px;
}

.ToggleSwitchon p {
	position: absolute;
	height: 25px;
	width: 40px;
	margin-top: 10px;
	background-color: var(--red-color);
	opacity: 0;
	cursor: pointer;
}

.ToggleSwitchoff svg {
	color: var(--gray-color);
	font-size: 22px;


}

.ToggleSwitchoff p {
	position: absolute;
	height: 25px;
	width: 40px;
	margin-top: 10px;
	background-color: var(--red-color);
	opacity: 0;
	cursor: pointer;
}

.addsupplier {
	display: flex;
	flex-wrap: wrap;
	width: 80%;
	margin: auto;
}

.addsupplier .supplierinput {
	width: 50%;
	display: flex;
	flex-direction: column;
}

.addsupplier .supplierinput label {
	margin-top: 15px;

}

.addsupplier .supplierinput input {
	margin-top: 5px;
	padding: 8px;
	margin-right: 10px;
	border-radius: 4px;
	border: none;
	outline: 1px solid var(--cadet-gray-color);
}

.addsupplier .supplierinput select {
	margin-top: 5px;
	padding: 8px;
	margin-right: 10px;
	width: 97%;
}

.WarningText {
	color: var(--red-color);
	font-style: italic;
	font-weight: bold;
	padding: 0;
	margin: 0;
	margin-top: 5px;
}

.addsupplier .supplierinput img {
	height: 70px;
	width: 70px;
	object-fit: cover;
	border-radius: 50%;
	margin-top: 10px;
	border: 3px solid var(--navy-blue-color);
}

.addsupplier .buttonsection {
	width: 100%;
	flex-direction: row;
	justify-content: right;
}

.addsupplier .buttonsection button {
	width: max-content;
	padding: 10px 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto;
	border: 1px solid var(--green-color);
	color: var(--light-green-color);
	border-radius: 10px;
	background-color: var(--white-color);
	font-weight: bold;
	cursor: pointer;

}

.addsupplier .buttonsection button:last-child {
	color: var(--white-color);
	border: none;
	
	background-color: var(--dark-red-color);
}

.addsupplier .buttonsection button:last-child:hover {

	background-color: var(--red-color);
}

.addsupplier .buttonsection button:hover {
	background-color: var(--green-color);
	color: var(--white-color);
}

.edit_bank_wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.edit_bank {
	position: relative;
	width: 40%;
	padding: 20px;
	border-radius: var(--modal-radius);
	background-color: var(--white-color);
}

.edit_bank h2 {
	margin: 10px auto;
	border-bottom: 2px solid var(--navy-blue-color);
	width: max-content;
}
.edit_btn{
	background-color: var(--dark-yellow-color);
	color: var(--white-color);
	padding: 7px;
}