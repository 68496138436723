.conAlert_wrapper{
	position: fixed;
	background-color: var(--model-wrapper-color);
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
}
.conAlert {
	padding: 20px;
	background: var(--white-color);
	margin: auto;
	border-radius: var(--modal-radius);
	width: 400px;
	position: relative;
	overflow-y: auto;

	h2{
		text-align: center;
		border: none;
		text-transform: capitalize;
		font-weight: 600;
		color:var(--cyan);
	}
	.buttonsec {
		display: flex;
		justify-content: center;
	}
	.action_buttons{
		display: flex;
		justify-content: center;
		gap: 12px;
		margin-top: 20px;
		input{
			padding: 8px 10px;
			cursor: pointer;
			background-color: var(--green);
			border-radius: 4px;
			color: var(--white-color);
			transition: 0.3s;
			border: none;
			width: 60px;
			
			a{
				text-decoration: none;
				color: var(--white-color);
			}
			&:hover{
				background-color: var(--white-color);
				color: var(--green);
				outline: 1px solid var(--green);
				a{
					color: var(--green);  
				}
				}
		}
		input:first-child{
			background-color: var(--danger);
			&:hover{
			background-color: var(--white-color);
			color: var(--danger);
			outline: 1px solid var(--danger);
			}
		}
	}
}







.conAlert .yes {
	background-color: var(--red);
	color: var(--white-color);
}

.conAlert .no {
	background-color: var(--black-color);
	color: white;

}

.conAlert input:hover {
	background-color: #f7941d;
	cursor: pointer;

}
