.popbox {
	color: var(--black-color);

	.overlay {
		position: fixed;
		width: 100%;
		z-index: 2000;
		background: var(--model-wrapper-color);
		top: 0;
		left: 0;
		height: 100%;

		.popup {
			margin-top: 3% !important;
			padding: 20px;
			background: #fff;
			margin: auto;
			height: 85%;
			border-radius: 2px;
			width: 50%;
			position: relative;
			overflow-y: auto;
			box-shadow: 1px 2px 4px 0px var(--gray-color);

			h2 {
				margin-top: 0;
				color: var(--black-color);
				font-family: Tahoma, Arial, sans-serif;
				text-align: center;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 16px;
				padding-bottom: 3px;
				border-bottom: 1px solid var(--light-gray-color);
				width: max-content;
				margin: auto;
				background: var(--white-color);
			}
			.content {
				width: 90%;
				margin: auto;
				gap: 15px;
				display: flex;
				flex-direction: column;
			}
			 input {
				width: 95%;
				outline: none !important;
				border: 1px solid var(--cadet-gray-color);
				/* padding: 10px; */
				border-radius: 2px;
				
			}
			label{
				margin-bottom: 3px;
			}
			select {
				outline: none !important;
				border: 1px solid var(--cadet-gray-color);
				padding: 10px;
				border-radius: 2px;
				width: 98%;
			}
			
			 .button {
				width: -moz-fit-content;
				width: fit-content;
				float: right;
				background-color: var(--primary);
				padding: 10px 20px;
				color: var(--white-color);
				border-radius: 2px;
				border: none;
				margin-right: 13px;
				margin-top: 10px;
				font-weight: 500;
				font-size: 16px;
				cursor: pointer;
				border-radius: 5px;
			}
			
			.inputcomm {
				margin-top: 10px;
				width: 100%;

				label {
					font-weight: 300;
				}
			}

			.close {
				position: absolute;
				top: 20px;
				right: 30px;
				transition: all 200ms;
				font-size: 30px;
				font-weight: bold;
				text-decoration: none;
				color: var(--black-color);
			}
			
			.close:hover {
				color: var(--red-color);
				cursor: pointer;
			}
			

		}

	}

	
}

@keyframes popAnimation {
	0% {
		top: -40%;
	}

	100% {
		top: 40%;
	}
}

.wrapper {
	width: 100%;
	
}

.wrapper .withimage {
	width: 45% !important;
	margin-right: 10px;
	height: 19px;
	display: flex;
	align-items: center;
}

.wrapper img {
	margin-right: 10px;
	height: 65px;
	display: flex;
	align-items: center;
	width: auto;
}


.buttonClass {
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
}

.buttonClass button {
	padding: 8px;
	border: none;
	background-color: var(--red-color);
	color: var(--white-color);
	border-radius: 5px;
}

.buttonClass button:first-child {
	margin-right: 10px;
	background-color: var(--green-color);
}



.addCatagory {
	width: 100%;
	h3 {
		text-align: center;
		border-bottom: 1px solid var(--light-gray-color);
		padding-bottom: 1px;
		max-width: max-content;
		margin: auto;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 2px;
		font-size: 17px;
	}
	.itemreport {
		width: 25%;
	}
	.ltemlistsearch {
		width: 95%;
		display: flex;
		padding: 5px;
		margin: auto;
		justify-content: space-between;
	}
	
	.submit {
		width: 150px;
		background: var(--primary);
		border: none;
		color: var(--white-color);
		border-radius: 2px;
		font-size: 14px;
		cursor: pointer;
	}
	.Categorysearch {
		display: flex;
		width: 50%;
		
		
		.searchOption {
			width: 500px;
			border: none;
			border: 1px solid var(--border-color);
			outline: none;
			border-radius: 4px 0 0 4px;
			padding: 8px;

		}
		.searchOption:focus{
	
			border: 1px solid var(--outline-color);
		
		
	}
	
		.searchButton {
			width: 100px;
			background: var(--primary);
			color: var(--white-color);
			border: none;
			font-size: 14px;
			border-radius: 0 4px 4px 0;
			cursor: pointer;
		}

	
	}
	.ManageTagTable{
		width: 100%;

		table{
			width: 100%;
			margin-top: 20px;
		}
		td {
			text-align: center;
			padding: 5px 10px;
		}
		tbody td:nth-child(3) {
			text-align: start;
		}
		td:last-child{
			display: flex;
			align-items: center;
			justify-content: center;
		
		}
		th {
			background-color: var(--teal);
			color: var(--white-color);
			padding: 10px 5px;
			font-size: 16px;
			text-align: center;
			
		}
		tr:nth-child(even) {
			background-color: var(--lightb);
		}
		tr{
			font-size: 14px;
		
		}
	}

}


.admincomm {
	margin-top: 15px;
	margin-bottom: 130px;
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 10px;
}










