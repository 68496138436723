.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;
    h2 {
        font-weight: 400;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 2px;
        text-transform: uppercase;
        font-size: 18px;
        width: max-content;
        margin: auto;
        letter-spacing: 1px;
        text-align: center;
        margin-top: 10px;
           margin-bottom: 15px;
    }
  
	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

.tabActive {
	background-color: var(--primary);
	border-radius: 2px;
	font-weight: 300;
	color: var(--white-color) !important;
}

    .searchSection{
        display: grid;
        grid-template-columns:repeat(1,1fr);
        gap: 20px;
        margin-bottom: 20px;
        
        .input_section{
            flex: 1;
            gap: 8px;
            display: flex;
            flex-direction: column;
                input{
                
                    padding: 10px;
                    border-radius: 4px;
                    outline: 1px solid var(--border-color);
                    border: none;
                    &:focus{
                        outline: 1px solid var(--outline-color);
                    }					
                }
                
            
                
            label{
                font-size: 14px;
                
            }
    
    
        }
        
    }	
    .table_wrapper {
        position: relative;
        background-color: var(--white-color);
        padding: 10px;
        border-radius: 4px;
        overflow: auto;
        table {
            width: 100%;
            border-collapse: collapse;
            text-align: center;
            font-size: 14px;
    
            th {
                background-color: var(--teal);
                color: var(--white-color);
                border: 1px solid var(--white-color);
                padding: 8px;
            }
    
            tbody tr td {
                padding: 8px;
                border: 1px solid var(--white-color);
    
            }
            tbody tr:nth-child(even) {
                background-color: var(--lightb);
            }
        }
        button{
            background-color: var(--green);
            color: var(--white-color);
            padding: 5px 8px;
        }
    
    }
	
	}
	

	a{
		text-decoration: none;
		color: var(--cyan);
		
	}
	
}

@media only screen and (min-width: 992px) {
    .salesMain{
        .innerSale{
            .searchSection{		
                grid-template-columns: repeat(3,1fr);
                
            }
        
        }
    }
	
			

}