.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;

	h2 {
		font-weight: 400;
		border-bottom: 1px solid var(--border-color);
		padding-bottom: 2px;
		text-transform: uppercase;
		font-size: 18px;
		width: max-content;
		margin: auto;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 10px;
   		margin-bottom: 15px;
	}
	.input_section2{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);
	
		.inputSection{
			width: 50%;
			margin: auto;


			.complain_category{
				display: flex;
				gap: 20px;
				flex-direction: column;
				justify-content: center;
			
				
				
			}
			.input_wrapper{
				padding: 10px;
				display: flex;
				flex-direction: column;
				gap: 5px;
				background-color: var(--lightb);
				
				margin: auto;

				p{
					font-size: 14px;
				}
			}
			.customer_btn{
				display: flex;
				justify-content: center;
				margin-top: 20px;

				button{
					
					padding: 8px;
					font-size: 14px;
					font-weight: 500;
					color: var(--white-color);
					background-color: var(--cyan);
					
					}
			}
		
		}
			
			
	}
}