.wrapper{
	width: 90%;
	margin: 50px auto;
}
.innerSale{
	display: flex;
	flex-direction: column;
	gap: 30px;
	background-color:var(--white-color);
	box-shadow: 0 0 10px 5px #e2e2e2;
}
.innerSale h3{
	text-align: center;
	padding-top: 20px;
}
.my_form{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 20px;
	gap: 16px;
	.common{
		display: flex;
		gap: 8px;
		align-items: center;
		label{
			flex: 2;
		}
		input{
			align-items: center;
			flex: 5;
			padding: 8px;
			border-radius: 4px;
			border: none;
			outline: 1px solid var(--gray);
		}
		select{
			flex: 5;
			padding: 8px;
			border-radius: 4px;
			border: none;
			outline: 1px solid var(--gray);
		}
	}
	.buttonWrapper{
		display: flex;
		grid-column: span 2 ;
		align-items: center;
		justify-content: center;
		button{
			padding: 8px 48px;
			background-color: var(--green);
			color: var(--white-color);
		}
	}
}
.table_wrapper{
	padding: 16px;
	table{
		width: 100%;
		border-collapse: collapse;
		text-align: center;
		thead{
			th{
				background-color: var(--cyan);
				border: 1px solid var(--white-color);
				color: var(--white-color);
				padding: 8px;
			}	
		}
		tbody{
	
			td{
				border: 1px solid var(--gray);
				padding: 8px;
				img{
					width: 100px;
					height: auto;
				}
				&:nth-child(6){
					font-size: 12px;

				}
				
			}
		
		}
		.action_buttons{
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			button{
				flex: 1;
				padding: 4px;
				background-color: var(--red);
				color: var(--white-color);
				&:first-child{
					background-color: var(--green);
				}
			}
		}
	}
}