.alert_wrapper {
    position: fixed;
    background-color: var(--model-wrapper-color);
    inset: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .alert_holder {
      width: 350px;
      background-color: var(--white-color);
      padding: 40px 20px;
      border-radius: 4px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      svg {
        font-size: 80px;
      }
      h2 {
        font-size: 18px;
      }
      .alert_button_wrapper {
        display: flex;
        gap: 30px;
        margin-top: 10px;
        button {
          padding: 8px 20px;
          background-color: var(--green);
          color: var(--white);
          &:last-child {
            background-color: var(--red);
          }
        }
      }
    }
  }