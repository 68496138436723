.ewrapper{
    position: fixed;
    background-color: var(--model-wrapper-color);
    z-index: 100;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.holder{
    background-color: var(--white-color);
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    h3{
    padding: 8px;
    background-color: var(--cyan);
    text-align: center;
    color: var(--white-color);
    }
    .image_holder{
        width: 50%;
        margin: auto;
        img{
            width: 100%;
            height: auto;
        }
    }
}
.my_form{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 20px;
	gap: 16px;
	.common{
		display: flex;
		gap: 8px;
		align-items: center;
		label{
			flex: 2;
		}
		input{
			align-items: center;
			flex: 5;
			padding: 8px;
			border-radius: 4px;
			border: none;
			outline: 1px solid var(--gray);
		}
		select{
			flex: 5;
			padding: 8px;
			border-radius: 4px;
			border: none;
			outline: 1px solid var(--gray);
		}
	}
	.buttonWrapper{
		display: flex;
		grid-column: span 2 ;
		align-items: center;
		justify-content: center;
		gap: 20px;
		button{
			padding: 8px 48px;
			background-color: var(--green);
			color: var(--white-color);
			
			&:first-child{
				background-color: var(--red);
			}
		}
	}
}