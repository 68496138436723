.wrapper {
  position: fixed;
  background-color: var(--model-wrapper-color);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .holder {
    background-color: var(--white-color);
    width: 60%;
    border-radius: 10px;

    .images {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      img {
        width: 300px;
        border: 1px solid var(--border-color);
      }
    }
    h2 {
      text-align: center;
      border-bottom: 1px solid var(--gray);
      padding-bottom: 1px;
      max-width: max-content;
      margin: auto;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 2px;
      font-size: 17px;
      color: var(--header-font-color);
      margin-top: 20px;
    }

    .content {
      width: 90%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      flex-direction: column;
      padding: 20px 0;

      .input_holder {
        display: flex;
        align-items: center;
        gap: 10px;

        label {
          flex: 1;
        }
        input {
          padding: 10px;
          outline: 1px solid var(--border-color);
          border: none;
          flex: 3;
          border-radius: 4px;
        }
        .m_select {
          flex: 3;
        }
        .fake_image {
          flex: 3;
          display: flex;
          align-items: center;

          img {
            width: 50px;
            height: auto;
          }
        }
      }
      .image_holder {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        img {
          width: 100px;
          height: auto;
        }
      }
    }
    .btn_group {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;

      button {
        background-color: var(--green);
        border-radius: 4px;
        color: var(--white-color);
        padding: 8px 16px;
        margin-bottom: 20px;
        &:last-child {
          background-color: var(--red);
        }
      }
    }
    .btn_group button:hover{
      background-color: var(--white);
      outline: 1px solid var(--green);
      color: var(--dark);
      transition: 0.3s;
     }
  }
}

.image_hide {
  display: none;
}
