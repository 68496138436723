.wrapper{
    position: fixed;
    inset: 0;
    background-color: var(--model-wrapper-color);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.holder{
    width: 80%;
    padding: 30px;
    background-color: var(--white-color);
    border-radius: var(--modal-radius);
    max-height: 80vh;
    overflow: auto;
}
.btns{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    
    button{
        padding: 8px 24px;
        background-color: var(--cyan);
        color: var(--white);
    }
}

.btns button:first-child{
    background-color: var(--danger);
}