.wrapper{
    background-color: var(--next-white);
	padding: 20px 30px 70px 30px;
    min-height: calc(100vh - 100px);
    position: relative;
    .header_part{
       display: flex;
       align-items: center;
       justify-content: center;
       padding: 16px 0;
       padding-top: 0;
    }
    
    h3 {
        font-weight: 500;
        border-bottom: 1px solid var(--border-color);
        text-transform: uppercase;
        font-size: 18px;
        margin: auto;
        letter-spacing: 1px;
      }
    .holder{
        padding: 30px;
        border-radius: var(--modal-radius);
        background-color: var(--white); 
       
        .form_wrapper{
                
            form{
                display: flex;
                gap: 20px;
                .input_wrapper{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    flex: 1;
                    input{
                        padding: 8px;
                        border: none;
                        outline: 1px solid var(--border-color);
                        border-radius: 2px;
                    }
                }
                .button_wrapper{
                    margin-top: 25px;
                    display: flex;
                    gap: 16px;
                    button{
                        padding: 8px 15px;
                        background-color: var(--green);
                        color: var(--white);
                        font-size: 14px;
                        &:last-child{
                            background-color: var(--cyan);
                        }
                    }
                }
            }
        }
        .table_wrapper{
            table{
                width: 100%;
                border-collapse: collapse;
                text-align: center;
                margin-top: 20px;
                thead{
                    position: sticky;
                    top: 50px;
                    th{
                        background-color: var(--cyan);
                        border: 1px solid var(--white-color);
                        color: var(--white-color);
                        padding: 8px;
                    }	
                }
                tbody{
            
                    td{
                        border: 1px solid var(--white);
                        padding: 8px;
                        &:nth-child(2){
                            text-align: left;
                        }
                    }
                    tr:hover{
                        background-color: var(--next-white);
                    }
                   tr:nth-child(even) {
                        background-color: var(--lightb);
                        
                    }
                    .grand_total_wrapper{
                        td{
                            font-weight: 600;
                        }
                        td:nth-child(1){
                            text-align: right;
                        }                    }
                }
                .action_buttons{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;
                    button{
                        flex: 1;
                        padding: 4px;
                        background-color: var(--red);
                        color: var(--white-color);
                        &:first-child{
                            background-color: var(--green);
                        }
                    }
                }
            }
            table[aria-label="invoice"]{
                tbody{
                    td{
                        &:nth-child(2){
                            text-align: center;
                        }
                        &:nth-child(6){
                            text-align: left;
                        }
                    }
                }
            }
            table[aria-label="product"]{
                tr button{
                    background-color: transparent;
                    svg{
                        font-size: 16px;
                        color: var(--teal);
                    }
                }
               .grand_total_wrapper{
                    td:nth-child(2){
                        text-align: center;
                    }  
               }
            }
        }
    }
}

