.wrapper{
    background-color: var(--poup-background-color);
    inset: 0;
    position: fixed;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.holder{
    background-color: var(--white-color);
    width: 50%;
}
.head{
    padding: 8px 16px;
    background-color: var(--pest-color);
    color: var(--white-color);
    position: relative;
}
.head button{
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 8px 16px;
    background-color: var(--danger-color);
    color: var(--white-color);
}
.my_form{
    display: flex;
    padding: 16px;
    gap: 16px;
}
.image_upload{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 16px;
}
.img_holder_g{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    .item_holder_g{
        position: relative;
        margin-top: 10px;
    }
    button{
        position: absolute;
        padding: 4px;
        background-color: var(--danger-color);
        color: var(--white-color);
        left: 0;
        right: 0;
        top: -10px;
        margin: auto;
    }
}

.dholder{
    background-color: var(--white-color);
    width: 80%;
    height: 80vh;
    overflow: auto;
}
.editor_holder{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
}
.editor_wrap{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.pholder{
    background-color: var(--white-color);
    width: 70%;
}
.formWrapper{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    padding: 16px;

    .inputWrapper{
        display: flex;
        flex-direction: column;
        gap: 4px;
        label{
            font-size: 1rem;
            color: var(--black-c);
        }
        input{
            padding: 8px;
            border: none;
            border-radius: 4px;
            outline: 1px solid var(--gray-color);
            &:focus{
                outline: 1px solid var(--primary-color);
            }
        }
       
        &:last-child{
            display: flex;
            align-items: center;
            grid-column: span 2;
            button{
                padding: 10px;
                border: none;
                font-size: 1rem;
                cursor: pointer;
                background-color: var(--primary-color);
                color: var(--white-color);
                border-radius: 4px;
                transition: 0.3s;
               
                &:hover{
                    background-color: var(--white-color);
                    color: var(--primary-color);
                    outline: 1px solid var(--primary-color);
                    transition: 0.3s;
                }
            }
        }
    }
    .inputWrapper[aria-label="vedio"]{
        grid-column: span 3;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 30px;
        textarea{
            flex: 1;
            padding: 8px;
            outline: 1px solid var(--gray-color);
            border-radius: 4px;
            border: none;
            &:focus{
                outline: 1px solid var(--primary-color);
            }
        }
    }
    

}
.seoformWrapper{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding: 16px;

    .inputWrapper{
        display: flex;
        gap: 16px;

        label{
            flex: 1;
            font-size: 1rem;
            color: var(--black-c);
        }
        input{
            flex: 5;
            padding: 8px;
            border: none;
            border-radius: 4px;
            outline: 1px solid var(--gray-color);
            &:focus{
                outline: 1px solid var(--primary-color);
            }
        }
       
        &:last-child{
            display: flex;
           justify-content: center;
            /* grid-column: span 3; */
            button{
                padding: 10px;
                border: none;
                font-size: 1rem;
                cursor: pointer;
                background-color: var(--primary-color);
                color: var(--white-color);
                border-radius: 4px;
                transition: 0.3s;
               
                &:hover{
                    background-color: var(--white-color);
                    color: var(--primary-color);
                    outline: 1px solid var(--primary-color);
                    transition: 0.3s;
                }
            }
        }
    }
    

}