.info_pop_Wrapper {
    position: fixed;
    background-color: var(--model-wrapper-color);
    inset: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .info_pop_holder {
      width: 350px;
      background-color: var(--white-color);
      padding: 40px 20px;
      border-radius: 4px;
      position: relative;
  
      .image_wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        position: absolute;
        top: -60px;
        left: 0;
        right: 0;
        img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          border: 2px solid var(--border-color);
          background-color: var(--white-color);
          text-align: center;
        }
      }
      .info_wrapper {
        text-align: center;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
  
        p {
          text-align: right;
        }
        span {
          text-align: left;
          color: var(--secondary);
          grid-column: span 2;
        }
      }
      .button_Wrpper {
        display: flex;
        justify-content: center;
        button {
          background-color: var(--red);
          padding: 8px 10px;
          color: var(--white-color);
          margin-top: 20px;
        }
      }
    }
  }
  