
.editPopupSection{
    position: fixed;
    inset: 0;
    background-color: var(--model-wrapper-color);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
   
    .editPoup{
        width: 50%;
        background-color: var(--white-color);
        padding: 30px;
        border-radius: 8px;
        overflow: hidden;
       h3{
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            border-bottom: 1px solid var(--border-color);
            max-width: max-content;
            margin: auto;
            color: var(--header-font-color);
            overflow: hidden;
        }
        .input_wrapper{
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;		
            .input_holder{
                flex: 1;
                gap: 8px;
                display: flex;
                flex-direction: column;
                position: relative;
                    input{
                        padding: 10px;
                        border-radius: 4px;
                        outline: 1px solid var(--border-color);
                        border: none;
                        &:focus{
                            outline: 1px solid var(--outline-color);
                        }					
                    }					             	
                label{
                    font-size: 14px;
                }
                
            }
            .btn_group{
                display: flex;
               
                justify-content: flex-end;
                gap: 10px;
    
            button{
                width: 100px;
                font-size: 16px;
                padding: 8px 15px;
                font-weight: 500;
                color: var(--white-color);
                background-color: var(--green);
                text-transform: capitalize;
                
                &:last-child{
                    background-color: var(--danger);
                }
            }
        }
    
        }	
    }
 
}