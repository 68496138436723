
.wrapper{
    background-color: var(--next-white);
    min-height: 80vh;
    padding-bottom: 100px;
}
.holder{
    width: 90%;
    margin: auto;
    background-color: var(--white-color);
    padding: 16px;
    h2{
        font-weight: 400;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 2px;
        text-transform: uppercase;
        font-size: 18px;
        width: max-content;
        margin: auto;
        letter-spacing: 1px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 15px;
    }
.add_product{
    display: flex;
    gap: 20px;
   margin-top: 10px;
    .my_select{
        flex: 1;
    }
    button{
        background-color: var(--green);
        padding: 8px 16px;
        color: var(--white-color);
    }
   
}
.table_wrapper{
    overflow: auto;
    table{
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        font-size: 14px;
        margin-top: 20px;
        
        thead{
           
                background-color: var(--teal);
                th{
                    color: var(--white-color);
                    border: 1px solid var(--white-color);
                    padding: 4px;
                }
                
        }
        tbody tr{
            &:nth-child(even) {
                background-color: var(--lightb);
            }
            td {
                padding: 4px;
                border: 1px solid var(--white-color);
            }
        }
        .qty_button{
            display: flex;
            justify-content: center;
            gap: 16px;
            button{
                cursor: pointer;
                padding: 2px 8px;
                background-color: var(--green);
                color: var(--white-color);
                &:first-child{
                    background-color: var(--red);
                }
            }
        }
      
    }
}

.customer_info{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
    .info_common{
        display: flex;
        gap: 4px;
        flex-direction: column;
        input{
            border: none;
            outline: 1px solid var(--border-color);
            padding: 8px;
            border-radius: 4px;
            position: relative;
            &:focus{
                outline: 2px solid var(--focus);
            }
        }
        label{
            color: var(--header-font-color);
        }
    }
}
.button_section {
    grid-column: span 2;
    display: flex;
    justify-content: center;
    button {
        padding: 8px 32px;
        background-color: var(--green);
        color: var(--white-color);
        &:disabled{
            background-color: var(--secondary); 
            cursor: not-allowed;
        }
    }
    
}
}