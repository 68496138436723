.wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
	inset: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;

}


.holder {
	padding: 20px;
	background: var(--white-color);
	margin: auto;
	border-radius: var(--modal-radius);
	width: 40%;
	position: relative;
	overflow-y: auto;
	max-height: 80vh;
}

.holder h2 {
	font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.25rem;
}

.close {
	position: absolute;
	top: 0;
	right: 20px;
	transition: all 200ms;
	font-size: 30px;
	font-weight: bold;
	text-decoration: none;
	color: var(--black-color);
}

.close:hover {
	color: var(--red);
	cursor: pointer;
}

content {
	width: 90%;
	margin: auto;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.inner {
	margin-top: 10px;
	width: 100%;
	display: flex;
	gap: 10px;
}

.inner label {
	font-weight: 500;
    flex: 3;
}
.inner input {
    flex: 5;
    padding: 4px;
}
.image_holder{
    display: flex;
    justify-content: flex-end;
}
.image_holder img{
   width: 50px;
   height: auto;
}

.btn_group{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}
.btn_group button{
    padding: 8px 16px;
    background-color: var(--green);
    color: var(--white-color);
}