.salesMain {
  background-color: var(--next-white);
  padding: 30px;
  height: 100vh;

  .innerSale {
    background-color: var(--white-color);
    padding: 30px;
    border-radius: var(--modal-radius);
    h2 {
      font-weight: 400;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 2px;
      text-transform: uppercase;
      font-size: 18px;
      width: max-content;
      margin: auto;
      letter-spacing: 1px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    svg {
      font-size: 20px;
      color: var(--black-color) !important;
      cursor: pointer;
      &:hover {
        color: var(--gray) !important;
      }
    }
    .orderdetaislInner {
      h3 {
        text-align: center;
        font-weight: 500;
      }

      .customerSection {
        display: flex;
        justify-content: space-between;

        .customerDetails {
          display: flex;
          flex-direction: column;
          gap: 6px;
          margin-bottom: 20px;
          .addressSec {
            display: flex;
            gap: 20px;
          }
          h4 {
            font-size: 18px;
            font-weight: 500;
          }
          p {
            font-size: 16px;
            color: var(--header-font-color);
            font-weight: 500;
          }
        }
        .orderDetails {
          display: flex;
          flex-direction: column;
          gap: 6px;
          h4 {
            font-size: 16px;
            font-weight: 500;
          }
          p {
            font-size: 14px;
            color: var(--header-font-color);
            font-weight: 500;
          }
        }
      }
    }

    .input_section {
      display: flex;
      align-items: center;
      flex: 6;
      position: relative;
      justify-content: center;
      gap: 20px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      .input_wrapper {
        gap: 12px;
        display: flex;
        flex-direction: column;

        label {
          font-size: 16px;
          text-align: start;
          font-weight: 500;
          color: var(--header-font-color);
        }
        input {
          padding: 10px;
          border-radius: 4px;
          outline: 1px solid var(--border-color);
          border: none;
          &:focus {
            outline: 1px solid var(--outline-color);
          }
        }
        textarea {
          padding: 10px;
          border-radius: 4px;
          outline: 1px solid var(--border-color);
          border: none;
          &:focus {
            outline: 1px solid var(--outline-color);
          }
        }
        select {
          padding: 10px;
          border-radius: 4px;
          outline: 1px solid var(--border-color);
          border: none;
          &:focus {
            outline: 1px solid var(--outline-color);
          }
        }
        .inner_input {
          display: flex;
          gap: 5px;

          input {
            width: 100%;
          }
        }
      }
    }
    .add_new_product {
      .SalesOrderSearchEdit {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin: 20px 0;
        label {
          font-size: 16px;
          text-align: start;
          font-weight: 500;
          color: var(--header-font-color);
        }
        .my_select {
          width: 50%;
          &:focus {
            outline: 1px solid var(--outline-color);
          }
        }
        button {
          width: 100px;
          padding: 10px;
          background-color: var(--cyan);
          color: var(--white-color);
        }
      }
    }

    .tableSection {
      margin-top: 20px;
      table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;

        th {
          background-color: var(--teal);
          color: var(--white-color);
          border: 1px solid var(--white-color);
          padding: 8px;
        }
        tbody tr td {
          padding: 4px;
          border: 1px solid var(--white-color);
          .quantity_edit {
            display: flex;
            gap: 5px;
            justify-content: center;
            align-items: center;
            p {
              font-size: 20px;
              font-weight: 500;
            }
            input {
              margin: 3px;
              padding: 6px;
              border-radius: 4px;
              outline: 1px solid var(--border-color);
              border: none;
              &:focus {
                outline: 1px solid var(--outline-color);
              }
            }
          }
          svg {
            cursor: pointer;
            color: var(--indigo);
          }
        }

        tbody tr:nth-child(even) {
          background-color: var(--lightb);
        }
      }
      .tableDetails {
        margin-right: 5px;
        text-align: end;
        p {
          padding: 5px 0px;
          &:nth-child(5) {
            font-weight: 600;
          }
        }
        button {
          margin-top: 20px;
          width: 150px;
          padding: 10px;
          background-color: var(--cyan);
          color: var(--white-color);
        }
      }
    }
  }
}
