.menupart {
  width: 100%;
  background-color: var(--cyan);
  z-index: 100;
  position: sticky;
  top: 50px;
}

.menupart .menuitems {
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin-left: 5px;
}

.menupart .menuitems li {
  list-style-type: none;
  padding: 10px;
  font-size: 16px;
}

.menupart .menuitems a:hover {
  background-color: var(--primary);
  cursor: pointer;
  transition: 0.3s;
  border-radius: 4px;
}

.menupart .menuitems a {
  color: var(--white-color);
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
}
.active {
  background-color: var(--primary);
  border-radius: 4px;
  padding: 8px;
  color: var(--white-color);
}
