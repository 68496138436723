.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;
    position: relative;

    h2 {
        font-weight: 400;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 2px;
        text-transform: uppercase;
        font-size: 18px;
        width: max-content;
        margin: auto;
        letter-spacing: 1px;
        text-align: center;
        margin-top: 10px;
           margin-bottom: 15px;
    }
  
	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

		.input_wrapper{
			display: flex;
			gap: 20px;
			margin-bottom: 20px;		
            .input_holder{
                flex: 1;
                gap: 8px;
                display: flex;
                flex-direction: column;
				position: relative;
                    input{
                        padding: 10px;
                        border-radius: 4px;
                        outline: 1px solid var(--border-color);
                        border: none;
                        &:focus{
                            outline: 1px solid var(--outline-color);
                        }					
                    }					             	
                label{
                    font-size: 14px;
                }
                
            }

		}	
        .btn_group{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

        button{
            width: 200px;
            font-size: 16px;
            padding: 8px 15px;
            font-weight: 500;
            color: var(--white-color);
            background-color: var(--green);
            text-transform: capitalize;
    
    }
    }
    
        .table_wrapper {
            position: relative;
            background-color: var(--white-color);
            padding: 10px;
            border-radius: 4px;
            table {
                width: 100%;
                border-collapse: collapse;
                text-align: center;
                font-size: 14px;
        
                th {
                    background-color: var(--teal);
                    color: var(--white-color);
                    border: 1px solid var(--white-color);
                    padding: 8px;
                }
        
                tbody tr td {
                    
                    padding: 8px;
                    border: 1px solid var(--white-color);    
                     
                }
                tbody tr:nth-child(even) {
                    background-color: var(--lightb);
                }
                img{
                    width: 100px;
                }  
				
                .action_buttons{
                    display: flex;
                    justify-content: center;
                    gap: 12px;
                    button{
                        padding: 3px 10px;
                        cursor: pointer;
                        background-color: var(--green);
                        border-radius: 4px;
                        color: var(--white-color);
                        transition: 0.3s;
                        border: none;
                        &:hover{
                            background-color: var(--white-color);
                            color: var(--green);
                            outline: 1px solid var(--green);
                            }
                    }
   
                }
            }
	
        }

        
	
	}

}
