.orderactivites_wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
    inset: 0;
	display: flex;
	align-items: center;
	z-index: 1000;
	justify-content: center;

}
.holder{
    position: relative;
	border-radius: var(--modal-radius);
	background-color: var(--white-color);
    border-radius: 8px;
    overflow: hidden;
	h3 {
		margin: 0;
		padding: 0;
		width: 100%;
		background-color: var(--primary);
		text-align: center;
		padding: 10px 0;
		color: var(--white-color);
		font-weight: 500;
		font-size: 1.8rem;
	}
}
.orderactivites {
    padding: 10px 20px;
}
.orderactivites h4{
   margin: 0;
   font-weight: 500;
   font-size: 1.5rem;
}

.table_wrapper{
	position: relative;
	table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        th {
            background-color: var(--teal);
            color: var(--white-color);
            border: 1px solid var(--white-color);
            padding: 4px;
        }
        tbody tr td{
            padding: 4px;
            border: 1px solid var(--white-color);
			svg{
				cursor: pointer;
				color: var(--indigo);
			}
        }
        tbody tr td:nth-child(2){
           text-align: left;
        }
        tbody tr:nth-child(even) {
            background-color: var(--lightb);
        }
    }
}

.btn_group{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.btn_group button{
    background: var(--red);
	color: var(--white-color);
    padding: 10px 20px;
}