.opupSectionWrapper {
  background-color: var(--poup-background-color);
  inset: 0;
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .head {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
    color: var(--primary-color);
  }

  .innerWrapper {
    background-color: var(--white-color);
    padding: 30px;
    border-radius: 8px;
    width: 95%;
    max-height: 80vh;
    overflow: auto;
    .formWrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .inputWrapper {
        display: flex;
        gap: 10px;
        flex-direction: column;

        input {
          padding: 8px;
          border-radius: 4px;
          border: none;
          outline: 1px solid var(--border-color);
          flex: 5;
        }
        label {
          flex: 2;
        }
        .section {
          padding: 8px;
          border-radius: 4px;
          border: none;
          flex: 5;
        }
        &:last-child {
          button {
            padding: 8px 16px;
            background: var(--pest-color);
            color: var(--white-color);
            &:last-child {
              background-color: var(--danger-color);
            }
          }
        }
      }
      
    }
    
  }
}

@media screen and (min-width: 448px) {
  .opupSectionWrapper {
    .innerWrapper {
      .formWrapper {
        .inputWrapper {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .opupSectionWrapper {
    .innerWrapper {
      width: 70%;
    }
  }
}

@media screen and (min-width: 992px) {
  .opupSectionWrapper {
    .innerWrapper {
      width: 50%;
    }
  }
}
