.sectionWrapper {
  .sectionInner {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    a {
      display: flex;
      font-size: 1.2rem;
      aspect-ratio: 4 / 2;
      border-radius: 4px;
      color: var(--primary-color);
      align-items: center;
      justify-content: center;
      background-color: var(--white-color);
      transition: 0.3s;
      &:hover {
        background-color: var(--primary-color);
        color: var(--white-color);
        transition: 0.3s;
      }
    }
  }
}

@media screen and (min-width: 480px) {
  .sectionWrapper {
    .sectionInner {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (min-width: 768px) {
  .sectionWrapper {
    .sectionInner {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (min-width: 992px) {
  .sectionWrapper {
    .sectionInner {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
