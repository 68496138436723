.invoicemain {
    position: absolute;
    min-height: 100vh;
    width: 100%;
    background-color: var(--white-color);
    left: 0;
    right: 0;
    top: 0;
    z-index: 12112;
    padding-top: 30px;
}
.final-row td {
    font-weight: bold;
 
}

.last-raw {
    width: 100%;
    background-color: var(--white-color);
    margin-top: -20px;

}

.last-raw p {
    display: block;
    color: var(--black-color);
    padding: 6px 10px;
    text-align: right;
    border: 1px solid var(--black-color);
    border-top: none;
    font-weight: bold;
}

.no-border-right {
    border-right: none !important;
    border-left: none !important;
}

.no-border-right:first-child {
    border: 1px solid var(--black-color) !important;
    text-align: right !important;
    padding-right: 5px !important;
}

.printBtn {
    width: 80px;
    padding: 7px !important;
    font-size: 16px;
    float: right;
    margin-top: 44px;
    margin-right: 38px;
    cursor: pointer;
    background-color: var(--focus) !important;
    color: var(--white-color);
    border: none;
    border-radius: 5px;
    transition: 0.3s;
    position: fixed;
    right: 50px;
}
.printBtn:hover {
    background-color: var(--pink) !important;
    color: var(--white-color);
    transition: 0.3s;
}
.invoiceSection .container {
    width: 870px;
    margin: auto;
    padding: 0;
    overflow: hidden;
    margin-top: 0 !important;
}
section.invoiceSection.supplier-invoice {
    margin-top: 20px;
    margin-bottom: 20px !important;
}
.invoiceSection .container .topSection img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 685px;
}


.invoiceSection .container .main {
    width: 780px;
    margin: auto !important;
}

.invoiceSection .container .main h3 {
    padding: 2px 10px;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
    width: 150px;
    text-align: center;
    float: none;
    margin: 0 auto;
    margin-bottom: 17px;
    margin-top: 10px;
}

.invoiceSection .container .main img {
    padding-left: 0px;
    margin-top: 10px
}

.invoiceSection .container .main h3 {
    padding: 2px 0px;
    margin-top: 0px;
    width: max-content;
    border-bottom: 1px solid var(--black-color);
}

.invoiceSection .container .main .details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.invoiceSection .container .main .po-info {
    width: 100%;
    background-color: none;
    text-align: center;
    font-size: 22px;
    font-style: italic;
    font-weight: bold;
    color: var(--black-color);
    margin-top: -5px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--black-color);
}

.invoiceSection .container .main .details .client .info {

    font-size: 14px;
    margin-bottom: 3px;

}

.invoiceSection .container .main .details .client .info .moreinfo {
    font-weight: 600;
    min-width: 70px;
}
.invoiceSection .container .main .details .invoice .info {
    font-size: 14px;
    margin-bottom: 3px;
    display: flex;
}

.invoiceSection .container .main .details .invoice .info .moreinfo {
    font-weight: 600;
  
}
.invoiceSection .container .main .details .invoice .info .invoiceMoreinfo{
    width: 63px;
}
.invoiceSection .container .main .table table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 0px !important;
}
.filter-data {
    margin-bottom: 15px;
}
.invoiceSection .container .main .table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.invoiceSection .container .main .table thead tr {
    height: 20px;
}

.invoiceSection .container .main .table thead .unit {
    text-align: center;
    padding-right: 10px;
}
.invoiceSection .container .main .table thead .unit_price {
    text-align: right;
    padding-right: 10px;
}
.invoiceSection .container .main .table thead .total {
    text-align: right;
    padding-right: 10px;
}

.invoiceSection .container .main .table thead tr .no {
    color: var(--black-color);
    font-size: 13px;
    background: var(--white-color);
    width: 32px;
}

.invoiceSection .container .main .table thead tr th {
    border: 1px solid var(--black-color);
}

.invoiceSection .container .main .table thead tr th {
    border: 1px solid var(--black-color);
    padding: 5px;
}

.invoiceSection .container .main .table tbody {
    display: table-row-group;
    vertical-align: middle;
    font-size: 13px;
    border-color: inherit;
}

.invoiceSection .container .main .table tbody tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.invoiceSection .container .main .table tbody tr .no {
    color: var(--black-color);
    font-size: 13px;
    background: var(--white-color);
    text-align: center !important;
}
.invoiceSection .container .main .table tbody tr .desc {
    text-align: left;
    padding: 4px 5px 4px 10px;
    width: 320px;
}
.invoiceSection .container .main .table tbody tr .desc p {
    margin: 5px 0px 0px 0;
    color: var(--black-color) !important;
    font-size: 11px;
    font-weight: 600;
}

.invoiceSection .container .main .table tbody tr .desc strong {
    font-weight: 500;
    font-size: 13px;
}

.invoiceSection .container .main .table tbody tr .unit {
    padding: 4px 5px 4px 5px;
    width: 57px;
    text-align: center !important;
}

.invoiceSection .container .main .table tbody tr .unit_price {
    padding: 4px 8px 4px 5px;
    width: 50px;
    text-align: right!important;
}
.invoiceSection .container .main .table tbody tr .total {
    padding: 4px 8px 4px 5px;
    width: 90px;
    text-align: right!important;
}

.invoiceSection .container .main .table tbody tr td {
    border: 1px solid var(--black-color) !important;
    margin: 3px 5px 3px 5px;
    background: var(--white-color);
    color: var(--black-color);
}

.invoiceSection .container .main .table .tfoot {

    border-collapse: collapse;
    border-spacing: 0;
    border: none;
}
.invoiceSection .container .main .table .tfoot tr td {
    border: none !important;
    padding: 2px 7px;
    background: var(--white-color);
    border-bottom: none;
    font-size: 14px;
    text-align: right !important;
}

.invoiceSection .container .main .table .tfoot tr td span {
    border-bottom: 1px solid var(--black-color);
    display: block;
    width: 260px;
    float: right;
}

.invoiceSection .container .main .table .tfoot .gran_total_text {
    width: 100px;
}

.invoiceSection .container .main .table .tfoot .gran_total {
    padding: 0px 4px;
    font-weight: bold
}

.invoiceSection .container .main .table .tfoot .pre_Paid_text {
    width: 100px;
}

.invoiceSection .container .main .table .tfoot .pre_Paid {
    padding: 0px 4px;
    font-weight: bold
}

.invoiceSection .container .main .table .tfoot .amount {
    padding: 0px 4px;
    font-weight: bold
}

.invoiceSection .container .main .table .tfoot .amount_text {
    width: 100px;
}

.invoiceSection .container .main .notice1 {
    width: 450px;
    margin-top: -148px;
    padding-bottom: 3px;
    border: none;
    font-size: 13px;
}

.invoiceSection .container .main .notice1 .notice1_1 {
    font-size: 13px;
}

.invoiceSection .container .main .notice1 .notice1_1 p {
    margin-top: 5px;
    /* text-transform: uppercase; */
    font-weight: 600;
    font-size: 11px;
    text-align: left
}

.invoiceSection .container .main .notice1 .inword {
    float: left;
    margin-right: 3px;
    height: 48px;
}

.invoiceSection .container .main .notices {
    margin-top: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.notetext {
    font-size: 13px;
}

.invoiceSection .container .main .notice {
    font-size: 14px;
}

.invoiceSection .container .main .seal_signiture {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.seal_signiture p{
    border-top: 1px solid var(--black-color);
    font-weight: 500;
}
.invoiceSection .container .main .seal_signiture .singniture {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 600;
  border-top: 1px solid var(--black-color);
}


.invoiceSection .container .main .seal_signiture1 {
    margin-top: 100px;
    margin-bottom: 50px;
    display: block;
    height: 40px;
    
}

.invoiceSection .container .main .seal_signiture .thankyou_msg {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    display: block;
    margin-bottom: 50px;
}

.product-wise td:nth-child(1){
    text-align: left!important;
}
.product-wise td:nth-child(2){
    text-align: left!important;
}
.product-wise td:nth-child(3){
    text-align: left!important;
}
.product-wise-last-row{
    text-align: right!important;
}
.last-row-product td:nth-child(1){
    text-align: right!important;
}
.final-row  td:nth-child(1){
    text-align: right!important;
}
.final-row  td:nth-child(2){
    text-align: right!important;
}

@media print {
    .printBtn {
        display: none;
    }

    title {
        display: none;
    }

    @page {
        margin-top: 0;
        margin-bottom: 0;
    }


}


.sales-detail-view th {
    font-size: 9px !important;
    padding: 4px;
    text-align: left;
}

.sales-detail-view tr {
    font-size: 9px !important;
    padding: 4px;
    text-align: left;
}

.sales-table td {
    border: 1px solid var(--black-color) !important;
}

.sales-table thead tr th {
    white-space: pre-wrap !important;
}

.sales-table
th {
    white-space: pre-wrap !important;
    padding: 8px !important;
    background: var(--white-color) !important;
    color: var(--black-color);
    font-weight: 200;
}
.sales-table td {
    padding: 4px 3px 4px 3px !important;
    text-align: center!important;
}
.myTable {
    table-layout: unset;
    width: 100%;
}

.mainhead {
    border-bottom: none !important;
    font-size: 13px !important;
}

.myhead th {
    font-size: 14px !important;
    text-align: center;
}

.mybody td {
    margin: 0 !important;
    padding: 0 !important;
}

.mybody .slno {
    width: 20px;
    text-align: center !important;
    padding: 3px !important;
    border: none !important;
    border-right: 1px solid var(--black-color) !important;
}

.mybody .brandName {
    width: 279px;
    text-align: left !important;
    padding: 3px !important;
    border: none !important;
}

.mybody .amount {
    width: 60px;
    text-align: right !important;
    padding: 3px !important;
    border: none !important;
    border-left: 1px solid  var(--black-color) !important;
}

.mybody .slTotal {
    border: none !important
}

.mybody .groupHead {
    text-align: center !important;
    font-weight: bold;
    color: var(--gray-color);
    padding: 3px !important;
}

.mybody .tmessage {
    text-align: right !important;
}

.sales-big {
    width: 1024px !important;
}

.fixed-table {
    position: relative;
    max-height: 95vh;
    overflow: auto;
}

.fixed-table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--model-wrapper-color);
    border-radius: 5px;
    background-color: var(--white-color);
}

.fixed-table::-webkit-scrollbar {
    width: 5px;
    background-color: var(--white-color);
}

.fixed-table::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px var(--model-wrapper-color);
    background-color: var(--light-gray-color);
}

/* todo css  */

.fixed-table-header {
    position: sticky;
    top: 0;
    background:var(--white-color) !important;
}

.fixed-table-header th {
    position: sticky;
    top: 0;
    color: var(--black-color);
    font-weight: 600;
    font-size: 12px !important;
    border: 1px solid var(--black-color);
    padding: 2px !important;
    text-align: center;
}

.pagebreakAtAGlance {
    display: none;
}


@media print {
    .sidepart {
        display: none;
    }

    .adminfooter {
        display: none;
    }

    .toppart {
        display: none;
    }

    .fixed-table {
        max-height: 15000px;
    }

    .fixed-table-header {
        background-color: var(--white-color);
        position: relative;
    }

    .fixed-table-header th {
        position: relative;
        color: var(--black-color)!important;
        border: 1px solid var(--black-color);
        background-color: var(--white-color)!important;
    }

    .pagebreakAtAGlance {
        display: block;
        margin-top: 80px;
        color: var(--white-color);
    }
    .checkbox{
        display: none!important;
    }
}


@page {
    size: auto;
    margin: 30px 0px !important;
}
