.profile_holder{
	.profile{
		display: flex;
		justify-content: center;
		margin: 20px 0;
		img{
			width: 80px;
			display: flex;
			border: 1px solid var(--border-color);
			border-radius: 4px;
		}
	}
	.info{
		display: flex;
		flex-direction: column;
		
		.info_inner{
			
			display: flex;
			margin-top: 10px;
			
			p{
				flex: 1;
				text-align: center;
				font-size: 14px;
				font-weight: 400;
			}
			span{
				flex: 2;
				text-align: start;
				font-size: 14px;
				color: var(--header-font-color);
			}
		}
	}
	.close_btn{
		display: flex;
		justify-content: center;
		margin-top: 10px;

		button{
		width: 100px;
        font-size: 14px;
        padding: 8px 15px;
        font-weight: 500;
        color: var(--white-color);
        background-color: var(--danger);
        margin-top: 10px;
        text-transform: capitalize;
		}
	}
}
.reset_holder{
	.info_section{
		margin: 10px 0;
		p{
			font-size: 14px;
			color: var(--header-font-color);
		}
	}
	.captcha_form{
		.captcha_input{
			display: flex;
			gap: 10px;
			align-items: center;
			label{
				color: var(--header-font-color);
			}
			input{
				padding: 10px;
				border: 1px solid var(--border-color);
				border-radius: 5px;
				outline: none;
				&:focus{
					border: 1px solid var(--outline-color);
				}
			}
		}
		span{
			
			color: var(--primary);
			margin: 5px 0;
		}
	
	}
	.close_btn{		
		display: flex;
		gap: 15px;
		justify-content: end;
		margin-top: 20px;
		
		button{
		width: 100px;
		font-size: 16px;
		padding: 8px 15px;
		font-weight: 500;
		color: var(--white-color);
		background-color: var(--danger);
		margin-bottom: 20px;
		text-transform: capitalize;
		&:hover{
			background-color: var(--cyan);
		}
	}	
}
}