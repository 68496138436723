.wrapper{
    background-color: var(--next-white);
	min-height: calc(100vh - 100px);
	padding-bottom: 50px;  
}

.holder{
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;
   
}

.head{
	display: flex;
	align-items: center;
	gap: 10px;
    
}

.information{
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 10px;
    background-color: var(--white-color);
    padding: 10px;
    border-radius: 8px;
    h3{
     font-weight: 500;
     color: var(--dark);
    }
    .customerDetails, .order_details{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .address{
        display: flex;
        gap: 10px;
    }
}

.table_wrapper{
	position: relative;
	background-color: var(--white-color);
    padding: 10px;
    border-radius: 4px;
	table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        th {
            background-color: var(--teal);
            color: var(--white-color);
            border: 1px solid var(--white-color);
            padding: 4px;
        }
        tbody tr td{
            padding: 4px;
            border: 1px solid var(--white-color);
        }
        tbody tr td:nth-child(2){
           text-align: left;
        }
        tbody tr:nth-child(even) {
            background-color: var(--lightb);
        }
    }
    .payment_info{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;
        margin-top: 10px;
        div{
            display: flex;
            gap: 10px;
            background-color: #e9e9fe;
            color: var(--primary);
            padding: 4px;
            border-radius: 4px;
        }
    }
    .btn_group{
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 16px;
        button{
            padding: 8px 16px;
            background-color: var(--success);
            color: var(--white-color);
        }
       
    }
}
.form_section{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 16px;
    .input_wrapper{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        align-items: center;
        label{
            text-align: right;
            grid-column: span 3;
        }
        input{
            padding: 8px;
            border-radius: 4px;
            border: none;
            outline: 1px solid var(--gray);
        }
        select{
            padding: 8px;
            border-radius: 4px;
            border: none;
            outline: 1px solid var(--gray);
        }
        
    }
   
}
