.sectionWrapper{
    background-color: var(--next-white);
    min-height: calc(100vh - 100px);
    padding-bottom: 50px;
	padding: 30px;
	.innerSection{
		padding: 30px;
		background-color: var(--white);
		border-radius: 10px;

		h2{
			text-align: center;
			font-size: 20px;
			font-weight: 500;
			padding-bottom: 4px;
			border-bottom: 1px solid var(--border-color);
			max-width: max-content;
			margin: auto;
			
		}
		form{
			display: grid;
			gap: 20px;
			grid-template-columns: repeat(2,1fr);
			margin-top: 10px;
			.inputWrapper{
				display: flex;
				flex-direction: column;
				gap: 5px;

				.sectonInner{
					padding: 8px;
					border: none;
					border: 4px;
					outline: 1px solid var(--border-color);
				}
			}
			.buttonWrapper{
				margin: auto;
				grid-column: span 2;

				button{
					padding: 10px;
					border-radius: 4px;
					font-size: 16px;
					background-color: var(--green);
					color: var(--white);

				}
			}
		}

		.tableWrapper{
			margin-top: 20px;
			table{
				width: 100%;
				thead{
					background-color: var(--teal);
					tr{
						color: var(--white);
						th{
							padding: 4px;
						}
					}
				}
			}
		}
	}
}