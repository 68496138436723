.wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.holder {
	width: 70%;
	position: relative;
	background-color: var(--white-color);
	border-radius: var(--modal-radius);
	overflow: auto;
	max-height: 90vh;
	overflow: auto;
}
.holder h3{
    background-color: var(--primary);
	color: var(--white-color);
	text-align: center;
    padding: 10px;
}
.info{
    text-align: center;
    padding: 8px;
}

.table_wrapper{
	position: relative;
    padding: 10px;
	table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        th {
            background-color: var(--teal);
            color: var(--white-color);
            border: 1px solid var(--white-color);
            padding: 4px;
        }
        tbody tr td{
            padding: 4px;
            border: 1px solid var(--white-color);
        }
        tbody tr td:nth-child(1){
           text-align: left;
        }
        tbody tr:nth-child(even) {
            background-color: var(--lightb);
        }
        .table_input{
            border-radius: 4px;
            border: none;
            outline: 1px solid var(--gray);
            padding: 4px;
        }
    }
}
.button_group{
    display: flex;
    gap: 20px;
    justify-content: center;
    padding-bottom: 20px;
    button{
        padding: 8px 20px;
        background-color: var(--green);
        color: var(--white-color);
    }
    button:last-child{
        background-color: var(--red);
    }
}