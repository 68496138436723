.heading{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;
	h2 {
		font-weight: 400;
		border-bottom: 1px solid var(--border-color);
		padding-bottom: 2px;
		text-transform: uppercase;
		font-size: 18px;
		width: max-content;
		margin: auto;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 10px;
   		margin-bottom: 15px;
	}
	.inner_headign{
		display: flex;
		background-color: var(--white-color);
		border-radius: var(--modal-radius);
		gap: 16px;
		padding: 10px;
		select{
			padding: 8px;
			border-radius: 4px;
			border: none;
			outline: 1px solid var(--gray);
			flex: 1;
		}
		button{
		 padding: 8px 20px;
		 background-color: var(--green);
		 color: var(--white-color);	
		}
	}


}