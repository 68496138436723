.wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
    inset: 0;
	display: flex;
	align-items: center;
	z-index: 1000;
	justify-content: center;

}
.holder{
    position: relative;
	border-radius: var(--modal-radius);
	background-color: var(--white-color);
    border-radius: 8px;
    overflow: auto;
    height: 90vh;
    width: 90%;
    .header{
        background-color: var(--primary);
		text-align: center;
		padding: 10px 30px; 
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 0;
        z-index: 1;
    }
	h3 {
		color: var(--white-color);
		font-weight: 500;      
	}
    button{
        padding: 0 16px;
    }
}
.orderactivites {
    padding: 10px 20px;
}
.orderactivites h4{
   margin: 0;
   font-weight: 500;
   font-size: 1.5rem;
}

.table_wrapper{
    width: 96%;
    margin: auto;
    margin-bottom: 50px;
	table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        thead{
            position: sticky;
            top: 44px;
        }
        th {
            background-color: var(--teal);
            color: var(--white-color);
            border: 1px solid var(--white-color);
            padding: 4px;
       
        }
        tbody tr td{
            padding: 4px;
            border: 1px solid var(--white-color);
            
        }
        tbody tr td[aria-label="bold-text"]{
           font-weight: 500;
            
        }
        tbody tr td:nth-child(2){
           text-align: left;
        }
        tbody tr:nth-child(even) {
            background-color: var(--lightb);
        }
    }
}

.btn_group{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.btn_group button{
    background: var(--red);
	color: var(--white-color);
    padding: 10px 20px;
}