.wrapper{
    background-color: var(--next-white);
	min-height: calc(100vh - 100px);
	padding-bottom: 50px;  
}

.holder{
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;
   
	h3{
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 16px;
		border-bottom: 1px solid var(--primary);
		max-width: max-content;
		margin: auto;
		margin-top: 10px;
		margin-bottom: 8px;
	}
	.search_wrapper{
		display: grid;
		grid-template-columns: repeat(1,1fr);
		gap: 20px;
		.common{
			display: flex;
			flex-direction: column;
		}
		input{
			padding: 8px;
			outline: 1px solid var(--border-color);
			border: none;
			border-radius: 2px;
			
		}
		select{
			padding: 8px;
			outline: 1px solid var(--border-color);
			border: none;
			border-radius: 2px;
			display: flex
		}
		.StockDetails_info_button{
			display: flex;
			gap: 20px;
			justify-content: center;
			
			button{
				background-color: var(--cyan);
				padding: 8px 15px;
				color: var(--white-color);
				&:last-child{
					background-color: var(--green);
				}
			}
		}
		
	}
	.view_quantity{
		background-color: var(--white-color);
		padding: 10px;
		border-radius: 4px;
		display: flex;
		align-items: center;
	}

}

.search_holder{
	background-color: var(--white-color);
	padding: 20px;
	border-radius: 8px;

	h4{
		text-align: center;
		font-weight: 400;
		margin-bottom: 15px;
	}
}

.table_wrapper{
	position: relative;
	background-color: var(--white-color);
    padding: 10px;
    border-radius: 4px;
	overflow: auto;
	table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
		font-size: 14px;
        th {
            background-color: var(--teal);
            color: var(--white-color);
            border: 1px solid var(--white-color);
            padding: 4px;
        }
        tbody tr td{
            padding: 4px;
            border: 1px solid var(--white-color);
			
        }
        tbody tr:nth-child(even) {
            background-color: var(--lightb);
        }
    }

}
.StockViewSearch{
	flex: 4;
}
.StockViewSearch2{
	flex: 2;
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	align-items: center;
	label{
		font-size: 14px;
		color: var(--dark);
	}
	select{
		padding: 8px;
		border: none;
		outline: 1px solid var(--border-color);
		border-radius: 2px;
		width: 60px;
	}
}
.view_btn{
	background-color: var(--cyan);
	color: var(--white-color);
	padding: 3px 5px;
	border-radius: 2px;
	font-size: 13px;
}
.pagination_section{
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 4px;
	display: flex;
	gap: 20px;
	align-items: center;
	button{
		padding: 8px;
		background-color: var(--info);
		color: var(--white-color);
	}
	.page_number{
		display: flex;
		align-items: center;
		gap: 5px;

		input{
			padding: 8px;
			border: none;
			outline: 1px solid var(--border-color);
			border-radius: 4px;
		}
	}
}


.stock_variance_pop_wrapper{
	position: fixed;
	background-color: var( --model-wrapper-color);
	inset: 0;
	z-index: 2000;
	display: flex;
	align-items: center;
	justify-content: center;
	.stock_variance_pop{
		width: 80%;
		max-height: 50%;
		background-color: var(--white-color);
		height: auto;
		border-radius: 4px;
		padding: 30px;
		overflow: auto;
		position: relative;
		.StockInfoCloseButton{
			position: absolute;
			right: 10px;
			background-color:transparent;
			top: 5px;
			font-size: 16px;
			transition: 0.3s;
		}
		.StockInfoCloseButton:hover{
			color: var(--red);
			transition: 0.3s;
		}
		h3{
			color: var(--dark);
			font-weight: 500;
			margin-bottom: 5px;
		}
		p{
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 10px;
		}
		table {
			width: 100%;
			border-collapse: collapse;
			text-align: center;
			font-size: 14px;
			th {
				background-color: var(--teal);
				color: var(--white-color);
				border: 1px solid var(--white-color);
				padding: 4px;
			}
			tbody tr td{
				padding: 4px;
				border: 1px solid var(--white-color);
				
			}
			tbody tr:nth-child(even) {
				background-color: var(--lightb);
			}
		}
	}
}

@media screen and (min-width: 992px) {
	.StockViewSearch2{
		flex: 2;
	}
	.StockViewSearch{
		flex: 3;
	}
	.search_wrapper{
		grid-template-columns: repeat(4,1fr) !important;
	}
	.stock_variance_pop{
		width: 40%;
	}

  }


