
.invoice .info {
    font-size: 14px;
    margin-bottom: 3px;
    display: flex;
}

 .invoice .info .moreinfo {
    font-weight: 600;
  
}
 .invoice .info .invoiceMoreinfo{
    width: 63px;
}



 .table table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 0px !important;
}
.filter-data {
    margin-bottom: 15px;
}
 .table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

 .table thead tr {
    height: 20px;
}

 .table thead .unit {
    text-align: center;
    padding-right: 10px;
}
 .table thead .unit_price {
    text-align: right;
    padding-right: 10px;
}
 .table thead .total {
    text-align: right;
    padding-right: 10px;
}

 .table thead tr .no {
    color: var(--black-color);
    font-size: 13px;
    background: var(--white-color);
    width: 32px;
}

 .table thead tr th {
    border: 1px solid var(--black-color);
}

 .table thead tr th {
    border: 1px solid var(--black-color);
    padding: 5px;
}

 .table tbody {
    display: table-row-group;
    vertical-align: middle;
    font-size: 13px;
    border-color: inherit;
}

 .table tbody tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
 .table tbody tr .no {
    color: var(--black-color);
    font-size: 13px;
    background: var(--white-color);
    text-align: center !important;
}
 .table tbody tr .desc {
    text-align: left;
    padding: 4px 5px 4px 10px;
    width: 320px;
}
 .table tbody tr .desc p {
    margin: 5px 0px 0px 0;
    color: var(--black-color) !important;
    font-size: 11px;
    font-weight: 600;
}

 .table tbody tr .desc strong {
    font-weight: 500;
    font-size: 13px;
}

 .table tbody tr .unit {
    padding: 4px 5px 4px 5px;
    width: 57px;
    text-align: center !important;
}

 .table tbody tr .unit_price {
    padding: 4px 8px 4px 5px;
    width: 50px;
    text-align: right!important;
}
 .table tbody tr .total {
    padding: 4px 8px 4px 5px;
    width: 90px;
    text-align: right!important;
}

 .table tbody tr td {
    border: 1px solid var(--black-color) !important;
    margin: 3px 5px 3px 5px;
    background: var(--white-color);
    color: var(--black-color);
}

 .table .tfoot {

    border-collapse: collapse;
    border-spacing: 0;
    border: none;
}
 .table .tfoot tr td {
    border: none !important;
    padding: 2px 7px;
    background: var(--white-color);
    border-bottom: none;
    font-size: 14px;
    text-align: right !important;
}

 .table .tfoot tr td span {
    border-bottom: 1px solid var(--black-color);
    display: block;
    width: 260px;
    float: right;
}

 .table .tfoot .gran_total_text {
    width: 100px;
}

 .table .tfoot .gran_total {
    padding: 0px 4px;
    font-weight: bold
}

 .table .tfoot .pre_Paid_text {
    width: 100px;
}

 .table .tfoot .pre_Paid {
    padding: 0px 4px;
    font-weight: bold
}

 .table .tfoot .amount {
    padding: 0px 4px;
    font-weight: bold
}

 .table .tfoot .amount_text {
    width: 100px;
}
