
/* aler css */
.InfoAlert {
	position: fixed;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	width: 30%;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0px 0px 10px gray;
	z-index: 2050;
}

.InfoAlert .buttonsec {
	float: right;
}

.InfoAlert .caption {
	background-color: rgb(36, 2, 100);
	color: white;
	padding: 10px;
	margin: 0;
}

.InfoAlert input {
	margin: 25px 10px;
	padding: 10px 25px;
	outline: none;
	border: none;
	border-radius: 5px;

}

.InfoAlert .yes {
	background-color: green;
	color: white;
}