.wrapper {
	background-color: var(--next-white);
	min-height: calc(100vh - 100px);
	padding-bottom: 50px;
}

.holder {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	
	h2{

		font-weight: 400;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 2px;
        text-transform: uppercase;
        font-size: 18px;
        width: max-content;
        margin: auto;
        letter-spacing: 1px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 15px;
	}

}

.p_search {
	display: flex;
	align-items: center;
	padding: 10px;
	gap: 30px;
	background-color: var(--white-color);
	border-radius: 4px;

	.my_select {
		flex: 1;
	}
}

.purchase_wrapper {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	background-color: var(--white-color);
	padding: 10px;
	gap: 16px;
	border-radius: 4px;

	.product_show {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
}

.table_others {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	background-color: var(--white-color);
	padding: 10px;
	gap: 16px;
	border-radius: 4px;
}

.table_wrapper {
	position: relative;
	background-color: var(--white-color);
	padding: 10px;
	border-radius: 4px;

	table {
		width: 100%;
		border-collapse: collapse;
		text-align: center;
		font-size: 14px;

		th {
			background-color: var(--teal);
			color: var(--white-color);
			border: 1px solid var(--white-color);
			padding: 4px;
		}

		tbody tr td {
			padding: 4px;
			border: 1px solid var(--white-color);

		}

		tbody tr td:nth-child(4) {
			text-align: left;
		}

		tbody tr td:nth-child(6) {
			text-align: left;
		}

		tbody tr:nth-child(even) {
			background-color: var(--lightb);
		}

		.input_wrap {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;

			input {
				width: 50px;
				text-align: center;
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button{
					-webkit-appearance: none;
					margin: 0;
				}
			}
			button {
				padding: 4px;
				font-size: 8px;
				background-color: var(--green);
				color: var(--white-color);

				&:first-child {
					background-color: var(--red);
				}
			}

		}

		.action_buttons {
			display: flex;
			gap: 8px;
			align-items: center;
			justify-content: center;

			button {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: var(--red);
				padding: 8px;
				color: var(--white-color);
			}
		}
	}

}

.info_section {
	border: 1px solid var(--gray);
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	.po_section {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		input{
			flex: 1;
			padding: 4px;
			border-radius: 4px;
			border: none;
			outline: 1px solid var(--gray);
		}
	}

	.supplier_select {
		display: flex;
		gap: 20px;
		align-items: center;
		select {
			flex: 1;
			padding: 4px;
			border-radius: 4px;
			border: none;
			outline: 1px solid var(--gray);
		}
	}
	.supplier_discount,  .payment_method{
		display: flex;
		gap: 20px;
	}
	.purchase_total	{
		display: flex;
		flex-direction: column;
		gap: 10px;
		.counting{
			display: flex;
			gap: 20px;
			p{
				flex: 1;
				&:last-child{
					flex: 3;
				}
			}
		}
	}
	.button_group{
		display: flex;
		margin-top: 10px;
		gap: 16px;
		justify-content: flex-end;
		button{
			padding: 8px 24px;
			background-color: var(--green);
			color: var(--white-color);
			&:last-child{
				background-color: var(--teal);
			}
		}

	}
}

.AlertMessage{
	position: fixed;
	background-color: var(--model-wrapper-color);
    inset: 0;
	display: flex;
	align-items: center;
	z-index: 1000;
	justify-content: center;
}

.pop_holder {
	position: relative;
	border-radius: var(--modal-radius);
	background-color: var(--white-color);
	border-radius: 8px;
	overflow: hidden;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	h2 {
		margin: 0;
		padding: 0;
		width: 100%;
		text-align: center;
		font-weight: 500;
		font-size: 1.8rem;
	}
	button {
		padding: 8px 24px;
		background-color: var(--green);
		color: var(--white-color);
	}
}