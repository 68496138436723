.productAttributeWrapper{
    .input_wrapper{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        align-items: flex-end;
    }
}

.main{
    background-color: var(--white-color);
    border-radius: 10px;
    padding: 10px;
}
.common{
    flex: 1;
    min-width: 30%;
    display: flex;
    flex-direction: column;
}
.common input{
   padding: 10px;

}
.extra_wrapper{
    display: flex;
    flex: 1;
    flex-wrap: wrap;
}
.extra_wrapper h4{
   width: 100%;
   text-align: center;
   border-bottom: 1px solid var(--border-color);
   margin: 0;
   padding: 0;
   padding-bottom: 3px;
   margin-bottom:10px ;
   font-size: 20px;
}
.inner_common{
    flex: 1;
    min-width: 30%;
    display: flex;
    flex-direction: column;
}
.image_holder{
    display: flex;
    gap: 10px;
}
.image_show{
    width: 100px;
    height: auto;
    margin-top: 15px;
}
.text_editor{
   margin-top: 20px;
}
.btn_group{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.btn_group button{
    background-color: var(--green);
   border-radius: 4px;
}
.btn_group button:hover{
    background-color: var(--white);
    outline: 1px solid var(--green);
    color: var(--dark);
    transition: 0.3s;
   }

.common_att{
    flex: 1;
    min-width: 20%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.common_att input{
   padding: 8px;
   border: none;
   border-radius: 4px;
   border: 1px solid var(--border-color);
   outline: none;
}
.att_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white-color);
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 20px;
    gap: 20px;
    h4{
        margin: 0;
        padding: 0;
        font-size: 20px;
        color: var(--header-font-color);
    }
    label{
        margin-left: 5px;
    }
}

.att_buttons{
    display: flex;
    align-items: center;
}
.att_buttons button{
   background-color: var(--green);
   border-radius: 4px;
   color: var(--white-color);
   padding: 10px 20px;
   margin: 20px 0;
}
.submitbutton{
    background-color: var(--green);
   border-radius: 4px;
   color: var(--white-color);
   padding: 10px 20px;
   margin-top: 20px;
}
.main table{
    width: 100%;
}

.main table{
	width: 100%;
	margin-top: 20px;
    border-collapse: collapse;
    th{
        background-color: var(--teal);
	color: var(--white-color);
	padding: 14px;
	font-size: 16px;
	text-align: center;
        border: 1px solid var(--white-color);
    }
}
.main table td {
	text-align: center;
	padding: 5px 10px;
    border: 1px solid var(--gray);
    img{
        width: 60px;
        height: auto;
    }
    .table_buttons{
        display: flex;
        gap: 10px;
        justify-content: center;
        button{
            padding: 8px;
            background-color: var(--green);
            color: var(--white-color);
            &:last-child{
                background-color: var(--red);
            }
        }
    }
   
}

.main table tbody td:nth-child(3) {
	text-align: start;
}

.main table tr:nth-child(even) {
	background-color: var(--td-color);
}
.main table tr{
	font-size: 14px;

}
.wrapper{
    position: fixed;
    background-color: var(--model-wrapper-color);
    inset: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
}
.conAlert{
    background-color: var(--white-color);
    padding: 16px;
    border-radius: 4px;
    h2{
        color: var(--red);
        font-weight: 500;
    }
}
.button_section{
    display: flex;
    justify-content: center;
    gap: 20px;
    button{
        padding: 8px 16px;
        background-color: var(--green);
        color: var(--white-color);
        &:last-child{
            background-color: var(--red);
        }
    }
}
