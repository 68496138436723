
.addnewtab {
	display: flex;
	border-bottom: 1px solid #dfdfdf;
	margin-bottom: 10px;
	position: sticky;
	top: 50px;
	background-color: var(--white-color);
	z-index: 1000;
	padding: 30px;
	border-radius: 10px;

	h4 {
		padding: 8px;
		cursor: pointer;
		margin: 0;
		margin-bottom: 10px;
		border: 1px solid var(--border-color);
		margin-right: 15px;
		border-radius: 5px;
		transition: 0.3s;
		font-weight: 500;
	}
}

.tabActive {
	background-color: var(--primary);
	border-radius: 2px;
	font-weight: 300;
	color: var(--white-color) !important;
}
