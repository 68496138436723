.invoicemain {
    position: absolute;
    min-height: 100vh;
    width: 100%;
    background-color: var(--white-color);
    inset: 0;
    z-index: 100;
}

.printBtn {
    position: fixed;
    cursor: pointer;
    background-color: var(--orange);
    color: var(--white-color);
    transition: 0.3s;
    right: 30px;
    top: 30px;
    padding: 8px 24px;
    border: 1px solid var(--orange);
    font-size: 1rem;
}
.printBtn:hover {
    background-color:var(--white-color);
    color: var(--orange);
    transition: 0.3s;
}

.holder{
    width: 60%;
    margin: auto;
}
.client {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.client p span{
    font-weight: 500;
}
.invoice{
    display: flex;
    flex-direction: column;
    gap: 4px;  
}
.invoice p span{
    font-weight: 500;
}
.topSection{
    width: 100%;
    img{
        width: 100%;
        height: auto;
    }
}
.details{
    display: flex;
    justify-content: space-between;
   padding-bottom: 12px;
}

.table_wrapper{
    position: relative;
    table{
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        font-size: 14px;
        thead{
            th{
                border: 1px solid var(--black-color);
                padding: 4px;
                
            }
        }
        tbody{
            td{
                border: 1px solid var(--black-color);
                padding: 4px;
            }
          
        }
        
    }
}
.seal_signiture{
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
}
.thankyou_msg{
    margin-top: 30px;
    text-align: center;
}

@media print{
    .holder{
        width: 90%;
    }
    .printBtn{
        display: none;
    }
}