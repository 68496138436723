.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;

	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

		h2 {
			font-weight: 400;
			border-bottom: 1px solid var(--border-color);
			padding-bottom: 2px;
			text-transform: uppercase;
			font-size: 18px;
			width: max-content;
			margin: auto;
			letter-spacing: 1px;
			text-align: center;
			margin-top: 10px;
			   margin-bottom: 15px;
		}
		.search_section{
			display: flex;
			gap: 20px;
			margin-bottom: 20px;
			
			.input_section{
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 10px;
				p{
					color: var(--danger);
				}
				label{
					font-size: 14px;
					
				}
				input{
					
					padding: 8px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
					
			}
			
		}	
		.buttonWrapper{		
			display: flex;
			gap: 15px;
			justify-content: end;
			button{
			width: 100px;
			font-size: 16px;
			padding: 8px 15px;
			font-weight: 500;
			color: var(--white-color);
			background-color: var(--green);
			margin-bottom: 20px;
			text-transform: capitalize;

		}	
		}
		
		.tableSection{
			table {
				width: 100%;
				border-collapse: collapse;
				text-align: center;
				th {
					background-color: var(--teal);
					color: var(--white-color);
					border: 1px solid var(--white-color);
					padding: 8px;
				}
				tbody tr td{
					padding: 4px;
					border: 1px solid var(--white-color);
					svg{
						cursor: pointer;
						color: var(--indigo);
					}
				}
				tbody tr td:nth-child(1){
				   text-align: left;
				}
				tbody tr:nth-child(even) {
					background-color: var(--lightb);
				}
			}
			
		}
	}
	


}