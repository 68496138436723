.deliverydone{
	position: fixed;
	inset: 0;
	background-color: var(--model-wrapper-color);
	z-index: 1000;
	display: flex;
}
.holder {
	background-color: var(--white-color);
	width: 50%;
	margin: auto;
	max-height: 80vh;
	overflow: auto;
	border-radius: var(--modal-radius);
}

.holder h3 {
	background-color: var(--primary);
	color: var(--white-color);
	padding: 10px 0;
	text-align: center;
}
.my_form{
    padding: 20px;
}
.common {
	margin: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.common input {
	padding: 8px;
	width: 68%;
	border-radius: 4px;
	border: none;
	outline: 1px solid var(--gray);
}

.common label {
	width: 32%;
}
.delivery_info{
    display: flex;
    gap: 20px;
    margin:  15px 0;
}
.receive{
	display: flex;
	gap: 20px;
}
 .icon span{
	display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

 .icon svg{
	padding: 0;
	margin: 0;
    color: var(--gray);
}
 .icon p{
	padding: 0;
	margin: 0;
    color: var(--gray);
}
.active_svg svg{
    color: var(--green);
}
.active_svg p{
    color: var(--green);
}
.bank_select select{
    margin-top: 10px;
	padding: 8px;
	border-radius: 4px;
	border: none;
	outline: 1px solid var(--gray);
}
.buttonsection {
	display: flex;
	margin-top: 20px;
	align-items: center;
	justify-content: center;
}

.buttonsection button {
	margin-right: 10px;
	color: var(--white-color);
	text-align: right;
	background-color: var(--green);
	padding: 6px 20px;
}
.buttonsection button:first-child {
	background-color: var(--red);
}

.buttonsection button:disabled {
	background-color: var(--gray);
}

