.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;

	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);
		h2 {
			font-weight: 400;
			border-bottom: 1px solid var(--border-color);
			padding-bottom: 2px;
			text-transform: uppercase;
			font-size: 18px;
			width: max-content;
			margin: auto;
			letter-spacing: 1px;
			text-align: center;
			margin-top: 10px;
			 margin-bottom: 15px;
		}
		svg{
			font-size: 20px;
			color: var(--black-color) !important;
			cursor: pointer;
			&:hover{
				color: var(--gray) !important;
			}
		}
		.orderdetaislInner{
			h3{
				text-align: center;
				font-weight: 500;
			}
			.customerSection{
				display: flex;
				justify-content: space-between;
				
				.customerDetails{
					display: flex;
					flex-direction: column;
					gap: 6px;
					h4{
						font-size: 16px;
						font-weight: 600;
					}
					p{
						font-size: 16px;
						color: var(--header-font-color);
						font-weight: 500;
					}
				}
				.orderDetails{
					display: flex;
					flex-direction: column;
					gap: 6px;
					h4{
						font-size: 16px;
						font-weight: 600;
					}
					p{
						font-size: 14px;
						color: var(--header-font-color);
						font-weight: 500;
					}
				}
			}
		}
	
	.tableSection{
			margin-top: 20px;
		table {
			width: 100%;
			border-collapse: collapse;
			text-align: center;
			
			th {

				background-color: var(--teal);
				color: var(--white-color);
				border: 1px solid var(--white-color);
				padding: 8px;
			}
			tbody tr td{
				padding: 4px;
				border: 1px solid var(--white-color);
				.quantity_edit{
					display: flex;
					gap: 5px;
					justify-content: center;
					align-items: center;
					p{
						font-size: 20px;
						font-weight: 500;
					}
					input{
					margin: 3px;
					padding: 6px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					&:focus{
						outline: 1px solid var(--outline-color);
					}				
					
					}
				}
				svg{
					cursor: pointer;
					color: var(--indigo);
				}
			}
		
			tbody tr:nth-child(even) {
				background-color: var(--lightb);
			}
		}
		.tableDetails{
			margin-right: 5px;
			text-align: end;
			p{
				padding: 5px 0px;
				
			}
		
		}
		.ButtonGroup{
			display: flex;
			justify-content: end;
			gap: 20px;
			a{
				margin-top: 20px;
				width: 150px;
				padding: 10px;
				background-color: var(--cyan);
				color: var(--white-color);
				transition: 0.3s;
				text-align: center;
				border-radius: 4px;
				&:hover{
					background-color: var(--green);
					transition: 0.3s;
				}
			}
			
		}
		
	}

}
	}

	.conAlert{
		position: fixed;
    background-color: var(--model-wrapper-color);
    inset: 0;
    display: flex;
    align-items: center;
    z-index: 1000;
    justify-content: center;
	 .popUpWrapper{
		width: 500px;
		padding: 30px;
		position: relative;
		border-radius: var(--modal-radius);
		background-color: var(--white-color);
		border-radius: 8px;
		overflow: hidden;

		.buttonsec{
			display: flex;
			justify-content: center;
			gap: 20px;
			
			input{
				margin-top: 20px;
				width: 80px;
				border: none;
				border-radius: 5px;
				padding: 8px;
				background-color: var(--cyan);
				color: var(--white-color);
				cursor: pointer;
				
				&:first-child{
					background-color: var(--danger);
				}
			}
		}
	 }
	}
