.salesHistoruImagesec {
	display: flex;
	align-items: center;
	font-weight: bold;
	color: var(--gray-color);
}

.salesHistoruImagesec img {
	height: 30px;
	width: 30px;
	border: 1px solid var(--sky-blue-color);
	border-radius: 50%;
	margin-right: 5px;
	object-fit: contain;
}

.actionButton {
	display: flex;
	align-items: center;
	justify-content: center;
}

.actionButton a svg {
	color: var(--light-green-color);
	width: 20px;
	height: 20px;
	cursor: pointer;
	padding: 5px;
}

.actionButton a svg:hover {
	color: var(--primary-color) !important;
}

.actionButton a svg:hover::after {
	content: 'hello';
	display: block;
	position: absolute;
	z-index: 120211;
}

.tooltip {
	position: absolute;
	right: 0;
	background-color: var(--primary-color);
	color: var(--white-color);
	border-radius: 5px;
	margin-top: -10px;
	padding: 5px;
	z-index: 1;
	width: max-content;
	visibility: hidden;
}


.salesMain {
	width: 100%;
	min-height: 200px;
	padding-bottom: 30px;
}

.salesMain .innerSale {
	width: 96%;
	margin: auto;
	padding-top: 10px;
	position: relative;
}

.salesMain h2 {
	margin: 0;
	padding: 0;
}

.salesMain .innerSale .saleHeading {
	font-weight: 400;
	border-bottom: 1px solid var(--gray-color);
	padding-bottom: 2px;
	text-transform: uppercase;
	font-size: 18px;
	width: max-content;
	margin: auto;
	letter-spacing: 1px;
}

.tableSection table th {
	background-color: var(--th-color);
	color: var(--white-color);
}

.tableSection table tr:nth-child(even) {
	background-color: var(--td-color);
}
.tableSection table tr td{
	text-align: center;
}


.orderactivites {
	position: fixed;
	background-color: var(--white-color);
	z-index: 100;
	width: 70%;
	padding: 20px;
	border: 1px solid var(--sky-blue-color);
	top: 110px;
	margin: auto;
	border-radius: 20px;
}

.orderactivites h3 {
	margin: 0;
	padding: 0;
	width: 100%;
	background-color: var(--sky-blue-color);
	text-align: center;
	padding: 10px 0;
	border-radius: 10px;
	color: var(--white-color);

}

.orderactivites button {
	background: var(--red-color);
	color: var(--white-color);
	border-radius: 5px;
	padding: 8px;
	border: none;
	margin-top: 20px;
	cursor: pointer;
	float: right;

}

.orderactivites table th {
	background-color: var(--primary-color);
	color: var(--white-color);
}


.SpinnerWrrapper {
	position: fixed;
	left: 50%;
	top: 30%;
	z-index: 1;
	padding: 30px;
	transform: translate(-30%, -50%);
}

.SpinnerWrrapper p {
	width: 100%;
	text-align: center;
	margin-top: 20px;
	font-size: 25px;
	font-weight: bold;
	font-style: italic;
	color: var(--green-color);
}