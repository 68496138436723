.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;

	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

		h2 {
			font-weight: 400;
			border-bottom: 1px solid var(--border-color);
			padding-bottom: 2px;
			text-transform: uppercase;
			font-size: 18px;
			width: max-content;
			margin: auto;
			letter-spacing: 1px;
			text-align: center;
			margin-top: 10px;
			   margin-bottom: 15px;
		}
		.searchSection{
			display: flex;
			gap: 20px;
			margin-bottom: 20px;
			display: grid;
			grid-template-columns: repeat(1,1fr);
            .input_section{
                gap: 8px;
                display: flex;
                flex-direction: column;
                .inputAll{
                    
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    label{
                        font-size: 14px;
                        
                    }
                    input{
                    
                        padding: 10px;
                        border-radius: 4px;
                        outline: 1px solid var(--border-color);
                        border: none;
                        &:focus{
                            outline: 1px solid var(--outline-color);
                        }					
                    }
					
                }
                	
                label{
                    font-size: 14px;
                    
                }
        
        
            }
			
		}	
		.buttonWrapper{
				display: flex;
				justify-content: end;
				align-items: center;
				gap: 20px;
			button{
				width: 100px;
				font-size: 16px;
				padding: 8px 15px;
				font-weight: 500;
				color: var(--white-color);
				background-color: var(--green);
				margin-bottom: 20px;
				text-transform: capitalize;
				&:last-child{
					background-color: var(--cyan);
			}
		}
		}
		
	
	}
	

	a{
		text-decoration: none;
		color: var(--cyan);
		
	}
	
}


@media only screen and (min-width: 992px) {
	.innerSale{
	.searchSection{
		
		grid-template-columns: repeat(1,1fr);
	}

	}
}