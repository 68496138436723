.wrapper{
    position: fixed;
    background-color: var(--model-wrapper-color);
    inset: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.holder{
    width:40%;
    background-color: var(--white-color);
    overflow: hidden;
    border-radius: var(--modal-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    h3{
        text-align: center;
        font-weight: 500;
        margin:20px 0;
    }
}
.my_form{
    display: flex;
    gap: 15px;
    flex-direction: column;
    width: 90%;
    margin: 10px auto;
}
.inner_form{
    display: flex;
    gap: 10px;
}
.inner_form label{
   flex: 1;
}
.inner_form input{
   flex: 2;
   padding: 8px;
   border: none;
   outline: 1px solid var(--border-color);
   border-radius: 2px;
}

.button_wrapper{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
button{
    width: 150px;
    font-size: 16px;
    padding: 8px 15px;
    font-weight: 500;
    color: var(--white-color);
    background-color: var(--green);
    margin-bottom: 20px;
    text-transform: capitalize;

    &:first-child{
        background-color: var(--red);
    }
}
}
