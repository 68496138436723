.wrapper{
    background-color: var(--next-white);
	min-height: calc(100vh - 100px);
	padding-bottom: 50px;  
}

.holder{
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;
   
}

.head{
	display: flex;
	align-items: center;
	gap: 10px;
    
    h2{
        font-weight: 400;
		border-bottom: 1px solid var(--border-color);
		padding-bottom: 2px;
		text-transform: uppercase;
		font-size: 18px;
		width: max-content;
		margin: auto;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 10px;
   		margin-bottom: 8px;
    }
    
}

.information{
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 10px;
    background-color: var(--white-color);
    padding: 10px;
    border-radius: 8px;
    h3{
     font-weight: 500;
     color: var(--dark);
    }
    .customerDetails, .order_details{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .address{
        display: flex;
        gap: 10px;
    }
}

.table_wrapper{
	position: relative;
	background-color: var(--white-color);
    padding: 10px;
    border-radius: 4px;
	table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        th {
            background-color: var(--teal);
            color: var(--white-color);
            border: 1px solid var(--white-color);
            padding: 4px;
        }
        tbody tr td{
            padding: 4px;
            border: 1px solid var(--white-color);
			svg{
				cursor: pointer;
				color: var(--indigo);
			}
        }
        tbody tr td:nth-child(1){
           text-align: left;
        }
        tbody tr:nth-child(even) {
            background-color: var(--lightb);
        }
    }
}
.table_details{
	display: flex;
	flex-direction: column;
	gap: 8px;
    text-align: end;

	button{
	 padding: 8px;
	 background-color: var(--green);
	 color: var(--white-color);
	 margin-top: 20px;

	}
    p{
        font-size: 16px;
        
    }
}