.wrapper{
    background-color: var(--poup-background-color);
    inset: 0;
    position: fixed;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.holder{
    background-color: var(--white-color);
    width: 50%;
    max-height: 80vh;
    overflow: auto;
    padding-bottom: 30px;
}
.head{
    padding: 8px 16px;
    background-color: var(--pest-color);
    color: var(--white-color);
    position: relative;
}
.head button{
    position: absolute;
    right: 16px;
    top: 8px;
    padding: 8px 16px;
    background-color: var(--danger-color);
    color: var(--white-color);
}
.my_form{
    display: flex;
    padding: 16px;
    gap: 16px;
}
.my_form input{
    padding: 8px;
    border-radius: 4px;
    border: none;
    outline: 1px solid var(--black-d);
    flex: 1;
}
.my_form button{
    padding: 8px 16px;
    background-color: var(--hard-green);
    color: var(--white-color);
}
.img_holder{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding: 16px;
}
.img_holder img{
    border: 1px solid gray;
    width: 50%;
    margin: auto;
}