
.addCatagory {
    width: 100%;
    background-color: var(--white-color);
    padding: 10px;
    border-radius: 10px;


}

.addCatagory .itemlisth3 {
    text-align: center;
    border-bottom: 1px solid var(--gray);
    padding-bottom: 1px;
    max-width: max-content;
    margin: auto;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 17px;
}

.atributesec {
    display: flex;
   margin-bottom: 10px;
}

.nameAtrribute {
    display: flex;
    padding: 10px;
    width: 95%;
    align-items: center;
    margin: auto;
}


.addnew {
    width: 15%;
    background: var(--primary);
    color: var(--white-color);
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 20px;
    padding: 11px;
    border: none;
}

.AtributesTable {
    overflow-x: auto;
    margin: auto;

    table{
        width: 100%;
    }
    th {
        background-color: var(--teal);
        color: var(--white-color);
        padding: 8px;
    }
    tbody tr:nth-child(even) {
        background-color: var(--lightb);
    }
    tr{
        text-align: center;
        
    }
    td{
        padding: 5px;
    }
     td:nth-child(3) {
        text-align: start;
    }
}


