
.common_class {
	padding: 20px;
	border-radius: 10px;

	.input_section {
		
		display: flex;
		align-items: center;
		flex: 6;
		position: relative;
		justify-content: center;
		gap: 20px;
		.input_wrapper{

			flex: 1;
			gap: 12px;
			display: flex;
			align-items: center;
			position: relative;

			label{
				flex: 2;
				text-align: end;
				color: var(--header-font-color);
				}
				input{
					flex: 5;
					padding: 10px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
						
		}
	
		.buttonWrapper{
			display: flex;
			justify-content: end;
			align-items: center;
			gap: 20px;
			
		button{
			width: 150px;
			font-size: 16px;
			padding: 8px 15px;
			font-weight: 500;
			color: var(--white-color);
			background-color: var(--green);
			
			text-transform: capitalize;
			&:last-child{
				background-color: var(--cyan);
		}
	}

	}
	}


	.tableSection{
			margin-top: 20px;
		table {
			width: 100%;
			border-collapse: collapse;
			text-align: center;
			
			th {
				background-color: var(--teal);
				color: var(--white-color);
				border: 1px solid var(--white-color);
				padding: 8px;
			}
			tbody tr td{
				padding: 4px;
				border: 1px solid var(--white-color);
				svg{
					cursor: pointer;
					color: var(--indigo);
				}
			}
			/* tbody tr td:nth-child(1){
			   text-align: left;
			} */
			tbody tr:nth-child(even) {
				background-color: var(--lightb);
			}
		}
		
	}
}
