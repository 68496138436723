
.details .client .info {
    font-size: 14px;
    margin-bottom: 3px;
}

 .details .client .info .moreinfo {
    font-weight: 600;
    min-width: 70px;
}

 .details .invoice .info {
    font-size: 14px;
    margin-bottom: 3px;
    display: flex;
}

 .details .invoice .info .moreinfo {
    font-weight: 600;
}

 .details .invoice .info .invoiceMoreinfo{
    width: 63px;
}

 .details .invoice .info {
    font-size: 14px;
    margin-bottom: 3px;
    display: flex;
}

 .details .invoice .info .moreinfo {
    font-weight: 600;
}

 .details .invoice .info .invoiceMoreinfo{
    width: 63px;
}

.table table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 0px !important;
}


.sales_detail_view th {
    font-size: 9px !important;
    padding: 4px;
    text-align: left;
}

.sales_detail_view tr {
    font-size: 9px !important;
    padding: 4px;
    text-align: left;
}


.sales_table td {
    border: 1px solid #000 !important;
}

.sales_table thead tr th {
    white-space: pre-wrap !important;
}

.sales_table
th {
    white-space: pre-wrap !important;
    padding: 8px !important;
    background: #fff !important;
    color: #000;
    font-weight: 200;
}
.sales_table td {
    padding: 4px 3px 4px 3px !important;
    text-align: center!important;
} 

.fixed_table {
    position: relative;
    max-height: 95vh;
    overflow: auto;
}

.fixed_table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: white;
}

.fixed_table::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.fixed_table::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #9e9d9e;
}

.fixed_table_header {
    position: sticky;
    top: 0;
    background:#fff !important;
}

.fixed_table_header th {
    position: sticky;
    top: 0;
    color: #000;
    font-weight: 600;
    font-size: 12px !important;
    border: 1px solid #000;
    padding: 2px !important;
    text-align: center;
}




@media print {
    .fixed_table {
        max-height: 15000px;
    }

    .fixed_table_header {
        background-color: white;
        position: relative;
    }

    .fixed_table_header th {
        position: relative;
        color: black!important;
        border: 1px solid black;
        background-color: white!important;
    }

}


 .table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

 .table thead tr {
    height: 20px;
}

 .table thead .unit {
    text-align: center;
    padding-right: 10px;
}
 .table thead .unit_price {
    text-align: right;
    padding-right: 10px;
}
 .table thead .total {
    text-align: right;
    padding-right: 10px;
}

 .table thead tr .no {
    color: #000;
    font-size: 13px;
    background: #fff;
    width: 32px;
}

 .table thead tr th {
    border: 1px solid #000;
}

 .table thead tr th {
    border: 1px solid #000;
    padding: 5px;
}

 .table tbody {
    display: table-row-group;
    vertical-align: middle;
    font-size: 13px;
    border-color: inherit;
}

 .table tbody tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
 .table tbody tr .no {
    color: #000;
    font-size: 13px;
    background: #fff;
    text-align: center !important;
}
 .table tbody tr .desc {
    text-align: left;
    padding: 4px 5px 4px 10px;
    width: 320px;
}
 .table tbody tr .desc p {
    margin: 5px 0px 0px 0;
    color: #000 !important;
    font-size: 11px;
    font-weight: 600;
}

 .table tbody tr .desc strong {
    font-weight: 500;
    font-size: 13px;
}

 .table tbody tr .unit {
    padding: 4px 5px 4px 5px;
    width: 57px;
    text-align: center !important;
}

 .table tbody tr .unit_price {
    padding: 4px 8px 4px 5px;
    width: 50px;
    text-align: right!important;
}
 .table tbody tr .total {
    padding: 4px 8px 4px 5px;
    width: 90px;
    text-align: right!important;
}

 .table tbody tr td {
    border: 1px solid #000 !important;
    margin: 3px 5px 3px 5px;
    background: #fff;
    color: #000;
}

 .table .tfoot {

    border-collapse: collapse;
    border-spacing: 0;
    border: none;
}
 .table .tfoot tr td {
    border: none !important;
    padding: 2px 7px;
    background: #FFFFFF;
    border-bottom: none;
    font-size: 14px;
    text-align: right !important;
}

 .table .tfoot tr td span {
    border-bottom: 1px solid #000;
    display: block;
    width: 260px;
    float: right;
}

 .table .tfoot .gran_total_text {
    width: 100px;
}

 .table .tfoot .gran_total {
    padding: 0px 4px;
    font-weight: bold
}

 .table .tfoot .pre_Paid_text {
    width: 100px;
}

 .table .tfoot .pre_Paid {
    padding: 0px 4px;
    font-weight: bold
}

 .table .tfoot .amount {
    padding: 0px 4px;
    font-weight: bold
}

 .table .tfoot .amount_text {
    width: 100px;
}



.final_row td {
    font-weight: bold;
 
}
.final_row  td:nth-child(1){
    text-align: right!important;
}

.final_row  td:nth-child(2){
    text-align: right!important;
}

.no_border_right {
    border-right: none !important;
    border-left: none !important;
}

.no_border_right:first-child {
    border: 1px solid black !important;
    text-align: right !important;
    padding-right: 5px !important;
}
