
.addCatagory {
	width: 100%;
    margin-top: 20px;
    background: var(--white-color);
    padding-top: 10px;
	border-radius: 10px;
}

.addCatagory .itemlisth3 {
	text-align: center;
	text-decoration: underline;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 10px;
} 
.searh_select{
	flex: 1;
}
.ltemlistsearch{
	width: 95%;
	margin: auto;
	display: flex;
	flex-direction: column;
	background-color: var(--white-color);
	padding: 10px;
	gap: 15px;
}
.add_new_button{
	padding: 8px 20px;
	background-color: var(--green);
	color: var(--white-color);
}

.itemCategoryTable {
	overflow: auto;
	width: 95%;
	margin: auto;
}
.itemCategoryTable table{
	width: 100%;
	margin-top: 20px;
}
.itemCategoryTable table td {
	text-align: center;
	padding: 5px 10px;

}

.itemCategoryTable table tbody td:nth-child(3) {
	text-align: start;
}
.itemCategoryTable table tbody td:last-child{
	display: flex;
	align-items: center;
	justify-content: center;

}

.itemCategoryTable table th {
	background-color: var(--teal);
	color: var(--white-color);
	padding: 14px;
	font-size: 16px;
	text-align: center;
	
}
.itemCategoryTable table tr:nth-child(even) {
	background-color: var(--lightb);
}
.itemCategoryTable table tr{
	font-size: 14px;

}
.btn_group{
	display: flex;
	flex-wrap: nowrap;
}
.itemCategoryTable table .edit{
	border: 1px solid var(--border-color);
	padding: 3px 5px;
	margin-right: 5px;
	cursor: pointer;
	background-color: var(--green);
	border-radius: 3px;
	color: var(--white-color);
	transition: 0.3s;
}
.itemCategoryTable table .edit:hover{
	background-color: var(--white-color);
	color: var(--green);
	transition: 0.3s;
}
.itemCategoryTable table .seo {
	border: 1px solid var(--border-color);
	padding: 3px 5px;
	margin-right: 5px;
	cursor: pointer;
	background-color: var(--info);
	color: var(--white-color);
	border-radius: 3px;
	transition: 0.3s;
}
.itemCategoryTable table .seo:hover{
	background-color: var(--white-color);
	color: var(--info);
	transition: 0.3s;
}

.itemCategoryTable table .delete {
	border: 1px solid var(--border-color);
	padding: 3px 5px;
	cursor: pointer;
	background-color: var(--red);
	color: var(--white-color);
	border-radius: 3px;
}
.itemCategoryTable table .delete:hover{
	background-color: var(--white-color);
	color: var(--red);
	transition: 0.3s;
}


/* SEO Part */
.add_seodata_wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
	inset: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
}

.add_seodata {
	position: relative;
	padding: 20px;
	background-color: var(--white-color);
}

.category_name {
	margin: 0;
	text-align: center;
	background-color: var(--green);
	color: var(--white-color);
	border-radius: 4px;
	padding: 8px 0;
}

.input_section {
	margin-top: 20px;
	width: 100%;
}


.inputs {
	display: flex;
	margin: 16px 0;
	align-items: center;
}

.inputs label {
	flex: 1;
}


.inputs input,
textarea {
	flex: 2;
	padding: 10px;
	border-radius: 8px;
}

.buttons {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-top: 20px;
}
.buttons button{
	padding: 8px 20px;
	color: var(--white-color);
	background-color: var(--green);
}
.buttons button:last-child{
	background-color: var(--red);
}


.inputs img {
	height: 80px;
	width: auto;
}

@media screen and (min-width:768px) {
	.ltemlistsearch{
		flex-direction: row;
	}
}


