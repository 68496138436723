
.wrapper{
    position: fixed;
	background: var(--model-wrapper-color);
    inset: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.holder{
    position: relative;
	border-radius: var(--modal-radius);
	background-color: var(--white-color);
    border-radius: 8px;
    overflow: hidden;
    width: 50%;
    max-height: 90vh;
    overflow: auto;
}
.holder h3 {
	margin: 0;
	padding: 0;
	width: 100%;
	background-color: var(--teal);
	text-align: center;
	padding: 10px 0;
	color: var(--white-color);
    font-weight: 500;
    font-size: 1.5rem;    
}
.my_form{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  
}
.input_holder{
    display: flex;
    align-items: center;
    gap: 15px;
}
.input_holder label{
    flex: 2;
}
.input_holder input{
    flex: 5;
    padding: 8px;
}
.my_select{
    flex: 5;
}

/* image section */

.image_wrapper{
    display: flex;
    flex: 5;
}
.image_wrapper input{
    flex: 1;
}
.image_wrapper .show_image{
   flex: 1;
}
.image_wrapper .show_image img{
   width: 100%;
   height: auto;
}
.image_wrapper .show_image_sqr img{
   width: 50px;
   height: auto;
}
/* image end */

.btn_group{
    display: flex;
    justify-content: flex-end;
    gap: 25px;
}
.btn_group button{
    background-color: var(--green);
	padding: 10px 20px;
	color: var(--white-color);
}
.btn_group button:first-child{
    background-color: var(--red);
}