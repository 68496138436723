.wrapper {
	position: fixed;
	background-color: var(--model-wrapper-color);
	inset: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;

}


.holder {
    padding: 20px;
    background: var(--white-color);
    margin: auto;
    border-radius: var(--modal-radius);
    width: 40%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.holder h2 {
	font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.25rem;
}
.age_select{
    display: flex;
    gap: 16px;
    align-items: center;
    .my_select{
        flex: 1;
    }
}
.table_wrapper{
    table{
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        thead th{
            color: var(--white-color);
            background-color: var(--teal);
            border: 1px solid var(--white-color);
            padding: 4px;
        }
        tbody td{
            border: 1px solid var(--white-color);
            padding: 4px;
        }
        tr:nth-child(even){
            background-color: var(--lightb) !important
         }
         button{
            padding: 4px 10px;
            background-color: var(--red);
            color: var(--white-color);
         }
    }

}

.button_section{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    button{
        padding: 8px 16px;
            background-color: var(--green);
            color: var(--white-color);
            &:first-child{
                background-color: var(--red);
            }
    }
}
