.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;

	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

		h2 {
			font-weight: 400;
			border-bottom: 1px solid var(--border-color);
			padding-bottom: 2px;
			text-transform: uppercase;
			font-size: 18px;
			width: max-content;
			margin: auto;
			letter-spacing: 1px;
			text-align: center;
			margin-top: 10px;
			   margin-bottom: 15px;
		}
	
		.input_wrapper{
			
			gap: 20px;
			display: flex;
            justify-content: center;
            align-items: center;
			label{
				
				font-size: 16px;
				text-align: start;
				font-weight: 500;
				color: var(--header-font-color);
				
				}
				input{
					
					padding: 10px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
				button{
					width: 150px;
					font-size: 16px;
					padding: 8px 15px;
					font-weight: 500;
					color: var(--white-color);
					background-color: var(--green);				
					text-transform: capitalize;
					&:last-child{
						background-color: var(--cyan);
				}
			}
						
		}
.table{
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 20px;
	
	p{
		
		padding: 8px;
		cursor: pointer;
		border-bottom: 1px solid var(--border-color);
		
	}
}
	.display_none{
		display: none;
	}

}
	}