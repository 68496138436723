.wrapper{
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--model-wrapper-color);
    z-index: 1000;
}
.holder {
	width: 50%;
	background-color: var(--white-color);
	border-radius: var(--modal-radius);
	display: flex;
	flex-direction: column;
    overflow: hidden;
}
.inner_holder{
    margin: 20px;
}

.holder h2 {
	background-color: var(--primary);
	color: var(--white-color);
	text-align: center;
    padding: 10px;
}

.holder h3 {
	color: var(--red);
	width: 100%;
	padding: 5px 0;
}

.inputSection {
	width: 100%;
	padding: 10px 0;
}

.inputSection .common {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	padding: 10px 0;
	align-items: center;
}

.inputSection .common label {
	width: 30%;
}

.inputSection .common input {
	width: 70%;
	padding: 8px;
	border-radius: 4px;
	border: none;
	outline: 1px solid var(--gray);
}

.inputSection .common select {
	width: 70%;
	padding: 8px;
	border-radius: 4px;
	border: none;
	outline: 1px solid var(--gray);
}

.buttonsection {
    display: flex;
    gap: 16px;
    justify-content: center;
}

.buttonsection button {
	padding: 8px 16px;
	color: var(--white-color);
	background-color: var(--green);
	border-radius: 4px;
	cursor: pointer;
}
.buttonsection button:first-child {
	background-color: var(--red);
}

.buttonsection button:hover {
	filter: brightness(.9);
}