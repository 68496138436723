.details {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    text-align: center;
  }
}
.client {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: sticky;
  top: 100px;
  background-color: var(--white-color);
  /* background-color: red; */
  z-index: 3;
  padding: 10px 0;
  .info_section {
    display: flex;
    justify-content: space-between;
  }
}
.table_wrapper {
  position: relative;
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    thead {
      position: sticky;
      top: 165px;
      background-color: var(--white-color);
      z-index: 4;
      th {
        border: 1px solid var(--black-color);
        padding: 4px;
      }
    }
    tbody {
      td {
        border: 1px solid var(--black-color);
        padding: 4px;
        &:nth-child(2) {
          text-align: left;
        }
        &:nth-child(3) {
          text-align: left;
        }
      }

      .last_row_product {
        td {
          text-align: center;
          font-weight: 500;
          &:nth-child(1) {
            text-align: right;
          }
        }
      }
      .final_row {
        td {
          text-align: right;
          font-weight: 500;
        }
      }
    }
  }
}
.print_time {
  text-align: right;
  color: var(--gray);
  position: fixed;
  bottom: 0;
  font-size: 0.5rem;
}
@media print {
  .client {
    position: relative;
    top: 0;
  }
  .table_wrapper {
    table {
      thead {
        position: relative;
        top: 0;
        background-color: transparent;
      }
    }
  }
}
