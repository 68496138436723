.salesMain{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;
	h2 {
		font-weight: 400;
		border-bottom: 1px solid var(--border-color);
		padding-bottom: 2px;
		text-transform: uppercase;
		font-size: 18px;
		width: max-content;
		margin: auto;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 10px;
		   margin-bottom: 15px;
	}
	.innerSale{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

	

	.my_form{
			display: flex;
			flex-direction: column;
			gap: 20px;
			margin-bottom: 20px;
			width: 80%;
			margin: auto;
			margin-top: 20px;
			.inner{
				flex: 1;
				display: flex;
				
				gap: 10px;

				label{
					font-size: 14px;
					flex: 1;
					text-align: center;
				}
				input{
					flex: 2;
					padding: 8px;
					border-radius: 4px;
					outline: 1px solid var(--border-color);
					border: none;
					
					
					&:focus{
						outline: 1px solid var(--outline-color);
					}					
				}
				select{
					padding: 8px;
					border-radius: 4px;
					border: none;
					border: 1px solid var(--border-color);		
					
					&:focus{
						outline: 1px solid var(--outline-color);
					}	
				}
				
			}
		
		}
	.btn_group{
		display: flex;
		justify-content: center;
		margin-top: 20px;
		gap: 20px;
		button{
		width: 150px;
        font-size: 14px;
        padding: 12px 15px;
        font-weight: 500;
        color: var(--white-color);
        background-color: var(--danger);
        margin-top: 10px;
        text-transform: capitalize;
		&:last-child{
			background-color: var(--cyan);
		}
		}
	}
}
}
