.wrapper {
    background-color: var(--white-color);
    padding: 30px;
    border-radius: 8px;
  
    .innerWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
    .top_section{
        display: flex;
        gap: 30px;
        align-items: center;
    }
      .top_section button {
        border: none;
        background-color: var(--primary-color);
        border-radius: 4px;
        color: var(--white-color);
        font-size: 1rem;
        transition: 0.3s;
        padding: 12px;
        cursor: pointer;
        &:hover {
          outline: 1px solid var(--primary-color);
          background-color: var(--white-color);
          color: var(--primary-color);
          transition: 0.3s;
        }
      }
       .top_section input {
        flex: 1;
        padding: 12px;
      }
    }
  }
  .row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
    .column {
      -ms-flex: 25%; /* IE10 */
      flex: 25%;
      max-width: 25%;
      padding: 0 4px;
      gap: 8px;
    
    }
    .item_wrapper{
      border: 1px solid var(--black-d);
      margin-bottom: 8px;
      padding: 8px;
    }
    .item_wrapper img {
      margin-top: 8px;
      vertical-align: middle;
      width: 100%;
     
    }
    .actions p {
     color: var(--black-d);
     padding: 8px 0;
    }
    .buttons{
      display: flex;
      gap: 16px;
      button{
        flex: 1;
        padding: 8px;
        background-color: var(--hard-green);
        color: var(--white-color);
        &:last-child{
          background-color: var(--danger-color);
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .row{ 
      .column {
      -ms-flex: 33.33%;
      flex: 33.33%;
      max-width: 33.33%;
    }
  }
   
  }
  @media screen and (max-width: 800px) {
    .row{ 
      .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }
   
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .row{ 
      .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
  }
  