.invoice_main{
    position: fixed;
    inset: 0;
    z-index: 101;
    background-color:var(--white-color);
    overflow: auto;
    padding-bottom: 70px;
}
.printBtn{
    position: fixed;
    right: 50px;
    top: 50px;
    padding: 8px 24px;
    background-color: var(--primary);
    color: var(--white-color);
    &:hover{
        background-color: var(--white-color);
        color: var(--primary);
        outline: 1px solid var(--primary);
        transition: .3s;
    }
}
.contents{
    width: 80%;
    margin: auto;
    img{
        width: 100%;
        height: auto;
        position: sticky;
        top: 0;
        z-index: 3;
    }
}

.details{
    display: flex;
    flex-direction: column;
    gap: 8px;
  
    h3{
        text-align: center;
    }
}
.client{
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 105px;
    padding: 10px;
    background-color: var(--white-color);
    z-index: 3;
}
.filter_data{
    position: fixed;
    left: 10px;
    top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 5;
    .checks{
        display: flex;
        gap: 8px;
    }
    input{
        cursor: pointer;
        &:hover{
            color: var(--green);
        }
    }
    label{
        cursor: pointer;
        &:hover{
            color: var(--green);
        }
    }
    button{
        padding: 8px;
        background-color: var(--green);
        color: var(--white-color);
    }
}
.table_wrapper{
    position: relative;
    table{
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        thead{
            position: sticky;
            top: 135px;
            background-color: var(--white-color);
            z-index: 4;
            th{
                border: 1px solid var(--black-color);
                padding: 4px;
            }
        }
        tbody{
            td{
                border: 1px solid var(--black-color);
                padding: 4px;
                &:nth-child(2){
                    text-align: left;
                }
                &:nth-child(3){
                    text-align: left;
                }
            }
            
            .last_row_product{
                td{
                    text-align: center;
                    font-weight: 500;
                    &:nth-child(1){
                        text-align: right;
                    }
                }
            }
            .final_row{
                td{
                    text-align: right;
                    font-weight: 500;
                }
            }
        }
        
    }
}
.print_time{
    text-align: right;
    color: var(--gray);
    position: fixed;
    bottom: 0;
    font-size: .5rem;
}

@media print {
      
    .invoice_main {
        position: relative;
    }
    
    .printBtn {
        display: none; /* Hide the print button */
    }
    
    .contents {
        width: 96%; /* Set the width of contents */
        margin: 0 auto; /* Center the contents horizontally */
    }
    .filter_data{
        display: none;
    }
    .client{
        position: relative;
        top: 0;
    }
    .table_wrapper{
        table{
            thead{
                position: relative;
                top: 0;
                background-color: transparent;
            }
        }
    }
}