.wrapper{

    --radious:8px;
    background-color: var(--next-white);
    /* background-color: red; */
    min-height: calc(100vh - 100px);
	padding-bottom: 50px;
}
.menuitems{
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}

.item{
    background-color: var(--white);
    aspect-ratio: 4/2;
    border-radius: var(--radious);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--secondary);
	text-align: center;  
    h2{
        font-size: 1.2rem;
        font-weight: 500;
    }
    &:hover{
        
        background-color: var(--lightb);
        h2{
            color: var(--cyan);
        }
    }
}
@media screen and (max-width: 992px) {
    .menuitems{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 768px) {
    .menuitems{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 500px) {
    .menuitems{
        grid-template-columns: repeat(2, 1fr);
    }
    .item{
        h2{
            font-size: 1rem;
        }
    }
}
