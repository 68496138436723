.heading{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;
	
	.inner_headign{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

		h2 {
			font-weight: 400;
			border-bottom: 1px solid var(--border-color);
			padding-bottom: 2px;
			text-transform: uppercase;
			font-size: 18px;
			width: max-content;
			margin: auto;
			letter-spacing: 1px;
			text-align: center;
			margin-top: 10px;
			   margin-bottom: 15px;
		}
		.input_section{

			
			.address{
				display: flex;
				gap: 20px;
				.common{
					display: flex;
					flex: 1;
					justify-content: center;
					align-items: center;
					gap: 10px;

					label{
						font-size: 14px;
						margin-bottom: 5px;
						
					}
					input{
						width: 100%;
						padding: 8px;
						border-radius: 4px;
						border: 1px solid var(--border-color);
						outline: none;
						&:focus{

							outline: 1px solid var(--outline-color);
						}	
					}
				}
			}
			.dates{
				display: flex;
				gap: 20px;
				margin-top: 20px;
				.common{
					display: flex;
					flex: 1;
					justify-content: center;
					align-items: center;	
					gap: 10px;
					margin-top: 30px;

					label{
						font-size: 14px;
						margin-bottom: 5px;
						width: 100px;
					}
					input{
						width: 100%;
						padding: 6px;
						border-radius: 4px;
						outline: none;
						border: 1px solid var(--border-color);
						
						&:focus{

							outline: 1px solid var(--outline-color);
						}
					}
				}
			}
			
		}

		.buttons{
			display: flex;
			justify-content: space-between;
			margin: 30px 0 10px 0;
			button{
				padding: 8px 10px;
				font-weight: 500;
				color: var(--white-color);
				
				&:nth-child(1){
					background-color: var(--cyan);

				}
				&:nth-child(2){
					background-color: var(--primary);

				}
				&:nth-child(3){
					background-color: var(--green);

				}
				&:nth-child(4){
					background-color: var(--orange);

				}
				&:nth-child(5){
					background-color: var(--danger);

				}
			}
		}
	}
}