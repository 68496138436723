.wrapper{
    background-color: var(--next-white);
	min-height: calc(100vh - 100px);
	padding-bottom: 50px;  
}

.holder{
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;
   
}

.head{
	display: flex;
	align-items: center;
	gap: 10px;
    
}

.information{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 20px;
    background-color: var(--white-color);
    padding: 10px;
    border-radius: 8px;
    h3{
     font-weight: 500;
     color: var(--dark);
    }
    .customerDetails, .order_details{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .address{
        display: flex;
        gap: 10px;
    }
}

.table_wrapper{
	position: relative;
	background-color: var(--white-color);
    padding: 10px;
    border-radius: 4px;
    overflow: auto;
	table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        th {
            background-color: var(--teal);
            color: var(--white-color);
            border: 1px solid var(--white-color);
            padding: 4px;
        }
        tbody tr td{
            padding: 4px;
            border: 1px solid var(--white-color);
        }
        tbody tr td:nth-child(2){
           text-align: left;
        }
        tbody tr:nth-child(even) {
            background-color: var(--lightb);
        }
    }
    .payment_info{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 8px;
        margin-top: 10px;
        div{
            display: flex;
            gap: 10px;
            background-color: #e9e9fe;
            color: var(--primary);
            padding: 4px;
            border-radius: 4px;
        }
    }
}
.form_section{
    
    display: grid;
    grid-template-columns: repeat(1,1fr);
    margin-top: 10px;
    gap: 10px;
    .input_wrapper{
       display: flex;
       flex-direction: column;
       gap: 5px;
        input{
            padding: 8px;
            border-radius: 4px;
            border: none;
            outline: 1px solid var(--gray);
        }
        select{
            padding: 8px;
            border-radius: 4px;
            border: none;
            outline: 1px solid var(--gray);
        }
       
    }
    .btn_group{
        display: flex;
        gap: 16px;
        margin-top: 16px;
        button{
            padding: 8px 16px;
            background-color: var(--success);
            color: var(--white-color);
        }
        button:first-child{
            background-color: var(--red);
        }
        button:disabled{
            background-color: var(--gray);
        }
      
      a{
        background-color: var(--cyan);
        color: var(--white-color);
        margin: 0;
        padding: 8px;
        border-radius: 4px;
      }
    }
}
@media only screen and (min-width: 992px) {
    .wrapper{
        .information{
            grid-template-columns: repeat(2,1fr);
        }
        .payment_info{
            grid-template-columns: repeat(5, 1fr);
        }
        .form_section{  
           
            grid-template-columns: repeat(4,1fr);
        }
    }
	
	}
