
.heading {
	width: 100%;
	min-height: 100px;
	padding-bottom: 30px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.heading .inner_headign {
	width: 96%;
	margin: auto;
	padding-top: 10px;
	position: relative;
}

.heading h2 {
	margin: 0;
	padding: 0;
}

.heading .inner_headign .headign_h2 {
	color: #1b6aaf;
	font-weight: 400;
	margin-bottom: 20px;
}

.heading .inner_headign .input_section2 {
	display: flex;
	align-items: flex-end;
}

.heading .inner_headign .input_section2 .common {
	flex-grow: 1;
}


.heading .inner_headign .input_section2 .common button {
	background-color: #1b6aaf !important;
	color: white;
	border: none;
	border-radius: 10px;
	cursor: pointer;
	font-weight: bold;
}

.heading .inner_headign .input_section2 .common button:hover {
	background-color: #064275;
}

.heading .inner_headign .input_section2 .common select {
	border: none;
	outline: 1px solid #2980b9;
	border-radius: 10px;
}

.heading .inner_headign .input_section2 .common {
	display: flex;
	flex-direction: column;
	align-items: left;
	margin-right: 10px;
}

.heading .inner_headign .input_section2 .common input {
	border: none;
	outline: 1px solid #2980b9;
	border-radius: 10px;
	padding: 5px;
}

.heading .inner_headign .input_section2 .common label {
	margin-bottom: 8px;
}





.input_section {
	display: flex;
}

.input_section .common {
	width: 100%;
}

.input_section select {
	width: 80%;
	border: none;
	outline: 1px solid #064275;
	border-radius: 8px;
	margin-bottom: 10px;
}
.date_section {
	margin-top: 15px;
}
.report_button {
	width: 200px !important;
	margin-top: 20px;
	margin-right: 10px;
}
