.wrapper {
  position: fixed;
  inset: 0;
  background-color: var(--model-wrapper-color);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .holder {
    height: 80vh;
    background-color: var(--white-color);
    padding: 30px;
    border-radius: 8px;
    overflow: auto;
    max-width: 1200px;
    width: 90%;
    .edit_page {
      background-color: white;
      padding: 10px;
      margin: auto;
    }
    .edit_page h2 {
      margin-bottom: 15px !important;
      color: var(--dark);
      text-transform: uppercase;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 2px;
      max-width: max-content;
      margin: auto;
    }
    .edit_page .input_all {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .edit_page .input_all .common {
      display: flex;
      align-items: center;
    }
    .edit_page .input_all .common label {
      flex: 1;
    }
    .edit_page .input_all .common input {
      flex: 5;
      padding: 10px;
    }
    .edit_page .input_all .page-title {
      width: 100%;
      text-align: center;
      background-color: #177ec1;
      color: #ffffff;
      padding: 5px;
    }
    .buttonWrapper {
      display: flex;
      padding: 10px 0 50px 0;
      gap: 20px;
      margin: auto;
      button {
        width: 150px;
        padding: 10px;
        font-size: 16px;
        font-weight: 500;
        background-color: var(--green);
        color: var(--white-color);
        transition: 0.3s;
        &:hover {
          background-color: var(--white-color);
          outline: 1px solid var(--green);
          color: var(--green);
          transition: 0.3s;
        }
        &:last-child {
          background-color: var(--red);

          &:hover {
            background-color: var(--white-color);
            outline: 1px solid var(--red);
            color: var(--black);
            transition: 0.3s;
            color: var(--red);
          }
        }
      }
    }
  }
}
