.heading{
	text-align: center;
}
/* Supplier Starts from here */
.addsupplier {
	display: flex;
	flex-wrap: wrap;
	width: 80%;
	margin: auto;
}

.addsupplier .supplierinput {
	width: 50%;
	display: flex;
	flex-direction: column;
}

.addsupplier .supplierinput label {
	margin-top: 15px;

}

.addsupplier .supplierinput input {
	margin-top: 5px;
	padding: 8px;
	margin-right: 10px;
	border-radius: 4px;
	outline: 1px solid var(--cadet-gray-color);
	border: none;
}

.addsupplier .supplierinput select {
	margin-top: 5px;
	padding: 8px;
	margin-right: 10px;
	width: 97%;
}
.WarningText {
	color: var(--red-color);
	font-style: italic;
	font-weight: bold;
	padding: 0;
	margin: 0;
	margin-top: 5px;
}
.addsupplier .supplierinput img {
	height: 70px;
	width: 70px;
	object-fit: cover;
	border-radius: 50%;
	margin-top: 10px;
	border: 3px solid var(--sky-blue-color);
}

.addsupplier .buttonsection {
	width: 100%;
	/* align-items: revert; */
	flex-direction: row;
	justify-content: right;
}

.addsupplier .buttonsection button {
	width: max-content;
	padding: 10px 30px;
	margin-top: 15px;
	border: 1px solid var(--green-color);
	color: var(--green-color);
	border-radius: 10px;
	background-color: var(--white-color);
	font-weight: bold;
	cursor: pointer;
}

.addsupplier .buttonsection button:last-child {
	color: var(--white-color);
	border: none;
	margin-left: 20px;
	background-color: var(--light-green-color);
	width: 20%;
	margin: 20px auto;
}

.addsupplier .buttonsection button:last-child:hover {
	color: var(--white-color);
	background-color: var(--primary-color);
}

.addsupplier .buttonsection button:hover {
	color: var(--white-color);
	background-color: var(--green-color);
}
