
.salesMain {
	width: 100%;
	min-height: 200px;
	padding-bottom: 30px;
}

.salesMain .innerSale {
	width: 96%;
	margin: auto;
	padding-top: 10px;
	position: relative;
}

.salesMain h2 {
	margin: 0;
	padding: 0;
}

.salesMain .innerSale .saleHeading {
	font-weight: 400;
	border-bottom: 1px solid var(--gray-color);
	padding-bottom: 2px;
	text-transform: uppercase;
	font-size: 18px;
	width: max-content;
	margin: auto;
	letter-spacing: 1px;
}
/* user complaint starts here */
.common_class {
	/* border: 1px dashed #1b6aaf; */
	padding: 20px;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.common_class .input_section {
	width: 100%;
	display: flex;
	align-items: center;
	flex: 6;
	position: relative;
}

.common_class .input_section button {
	padding: 10px;
	background-color: var(--light-green-color);
	cursor: pointer;
}

.common_class .input_section button:hover {

	background-color: var(--green-color);
}

.common_class .input_wrapper {
	flex: 9;
	display: flex;
	margin: 10px 0;
	margin-right: 20px;
	align-items: center;

}

.common_class .input_wrapper label {
	flex: 3;
}

.common_class .input_wrapper input {
	padding: 8px;
	margin-left: 10px;
	flex: 6;
}

.complain_category .input_section {
	display: block;
}

.complain_category .input_section button {
	float: right;
	margin-right: 20px;
}

.image_view {
	display: flex;
	flex-wrap: wrap;
}

.image_view img {
	width: 100px;
	margin: 10px;
}

.SpinnerWrrapper {
	position: fixed;
	left: 50%;
	top: 30%;
	z-index: 1;
	padding: 30px;
	transform: translate(-30%, -50%);
}

.SpinnerWrrapper p {
	width: 100%;
	text-align: center;
	margin-top: 20px;
	font-size: 25px;
	font-weight: bold;
	font-style: italic;
	color: var(--green-color);
}