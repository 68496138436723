.wrapper {
	background-color: var(--next-white);
	min-height: calc(100vh - 100px);
	padding-bottom: 50px;
}

.holder {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;

}

.head{
	display: flex;
	align-items: center;
	gap: 10px;
}
.information{
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 10px;
    background-color: var(--white-color);
    padding: 10px;
    border-radius: 8px;
    h3{
     font-weight: 500;
     color: var(--dark);
    }
    .customerDetails, .order_details{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .address{
        display: flex;
        gap: 10px;
    }
}

.table_wrapper{
	position: relative;
	background-color: var(--white-color);
    padding: 10px;
    border-radius: 4px;
	table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        th {
            background-color: var(--teal);
            color: var(--white-color);
            border: 1px solid var(--white-color);
            padding: 4px;
        }
        tbody tr td{
            padding: 4px;
            border: 1px solid var(--white-color);
        }
        tbody tr td:nth-child(1){
           text-align: left;
        }
        tbody tr:nth-child(even) {
            background-color: var(--lightb);
        }
		.last_tr td:nth-child(1){
			text-align: right;
			font-weight: 500;
			padding-right: 16px;
		}

		td:last-child{
			text-align: right;
			padding-right: 25px;
		}
    }
	.button_group{
		display: flex;
		margin-top: 10px;
		gap: 16px;
		justify-content: flex-end;
		button{
			padding: 8px 24px;
			background-color: var(--green);
			color: var(--white-color);
			a{
				text-decoration: none;
				color: var(--white-color);
			}
			&:first-child{
				background-color: var(--red);
			}
			&:nth-child(2){
				background-color: var(--info);
			}
			&:last-child{
				background-color: var(--green);
			}
		}

	}
}
