.wrapper{
   

    .holder{
        background-color: var(--white-color);
        display: flex;
        border-radius: 10px;
        .images{
            display: flex;
            justify-content: center;
            margin-top: 10px;
            
            img{
                width: 300px;
                border: 1px solid var(--border-color);
               
            }
        }
     
      
        .inner{
            width: 90%;
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            flex-direction: column;
            padding: 20px 0;
            h2{
                text-align: center;
                border-bottom: 1px solid var(--gray);
                padding-bottom: 1px;
                max-width: max-content;
                margin: auto;
                margin-bottom: 10px;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 2px;
                font-size: 17px;
                color: var(--header-font-color);
               
            }
  
            button{
                width: 150px;
                background-color: var(--green);
                border-radius: 4px;
                color: var(--white-color);
                padding: 8px 16px;
                margin-bottom: 20px;
                margin: auto;
                &:last-child{
                    background-color: var(--red);
                
                }
            }
          
        
        }
  
}


}