.wrapper{
    padding: 30px 0;
    .holder{
        background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

        .table_wrapper{
            table{
                width: 100%;
                thead{
                    background-color: var(--teal);
                    tr{
                        color: var(--white-color);
                        font-size: 14px;
                        th{
                            padding: 6px;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            padding: 4px;
                            text-align: center;
                        }
                    }
                    tr:nth-child(even) {
                        background-color: var(--next-white);
                      
                      }
                }
            }
        }
        .final_wrapper{
            display: flex;
            justify-content: center;
            gap: 10px;
            margin-top: 10px;
            span{
                padding: 10px;
                font-weight: 600;
                font-size: 16px;
                &:nth-child(2){
                    color: var(--success);
                }
                &:nth-child(3){
                    color: var(--danger);
                }
            }
            
        }
       
    }
}

.progressbar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
 
}

.progressbar progress {
    width: 50%;
    height: 20px; /* Optional: to increase the height of the progress bar */
    background-color: white;
    border-radius: 50px;
}

.progressbar progress::-webkit-progress-bar {
    background-color:var(--next-white); /* Background color of the progress bar */
    border-radius: 50px;
}

.progressbar progress::-webkit-progress-value {
    background-color: var(--success); /* Color of the filled portion */
    border-radius: 50px;
}
 
.progressbar progress::-moz-progress-bar {
    background-color: var(--success); /* Firefox filled portion */
    border-radius: 50px;
}

.progressbar small {
    font-size: 16px;
    color: var(--success); /* Color for the percentage text */
}

