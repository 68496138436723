.wrapper{
    position: relative;
    button{
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        gap: 5px;
        background-color: var(--dark);
        color: var(--white-color);
        padding: 4px 10px;
    }
}