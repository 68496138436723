.wrapper_popup{
    inset: 0;
    background-color: var(--model-wrapper-color);
    z-index: 100;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    .holder{
        background-color: var(--white-color);
        padding: 20px;
        border-radius: 4px;
        position: relative;
        .close_button{
            position: absolute;
            top: 10px;
            right: 10px;
            background-color:transparent;
            transition: 0.3s;
            svg{
                font-size: 20px;
                color: var(--gray-dark);
            }
            &:hover svg{
                color: var(--red);
                transition: 0.3s;
            }
        }
        h3{
            text-align: center;
            font-weight: 400;
            border-bottom: 1px solid var(--border-color);
            margin: auto;
            max-width: max-content;
            margin-bottom: 10px;
        }
        form{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap: 20px;
            
        }
        .input_wrapper{
            display: flex;
            flex-direction: column;
            gap: 1px;
            lable{
                font-size: 14px;
            }
            input{
                margin-top: 2px;
                padding: 8px;
                border: none;
                border-radius: 2px;
                outline: 1px solid var(--border-color);

                &:focus{
                    outline: 1px solid var(--primary);
                }
            }
            img{
                width: 80px;
                height: 80px;
                border-radius: 8px;
                margin-top: 5px;
            }
            
           .control{
                &:focus{
                    outline: 1px solid var(--primary) !important;
                }
            }
        }
        .button_wrapper{
            grid-column: span 3;
            display: flex;
            justify-content: center;

            button{
                background-color: var(--green);
                padding: 8px 14px;
                color: var(--white-color);
                border-radius: 2;
                margin-top: 10px;
            }
        }
    }
}