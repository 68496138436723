.heading{
	background-color: var(--next-white);
	padding: 30px;
	height: 100vh;

	.inner_headign{
		background-color: var(--white-color);
		padding: 30px;
		border-radius: var(--modal-radius);

		h2 {
			font-weight: 400;
			border-bottom: 1px solid var(--border-color);
			padding-bottom: 2px;
			text-transform: uppercase;
			font-size: 18px;
			width: max-content;
			margin: auto;
			letter-spacing: 1px;
			text-align: center;
			margin-top: 10px;
			   margin-bottom: 15px;
		}
		.input_section{

				display: flex;
				gap: 20px;
				align-items: center;
				justify-content: center;
				.icon_sec{
					display: flex;
					
					justify-content: center;
					align-items: center;
					gap: 10px;
					margin: 20px 0;
					
					label{
						font-size: 14px;
						margin-bottom: 5px;
						
					}
					span{
						width: 100%;
						padding: 8px;
						border-radius: 4px;
						display: flex;
						align-items: center;
						gap: 10px;
				
					}
				}
		
		}	
		
	}
	


}