.popUpLocationSection {
  position: fixed;
  background-color: var(--model-wrapper-color);
  inset: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
  justify-content: center;
  .popUp {
    width: 50%;
    position: relative;
    border-radius: var(--modal-radius);
    background-color: var(--white-color);
    border-radius: 8px;
    overflow: hidden;
    padding: 30px;
    h2 {
      font-weight: 400;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 2px;
      text-transform: uppercase;
      font-size: 18px;
      width: max-content;
      margin: auto;
      letter-spacing: 1px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    .input_section {
      display: flex;
      align-items: center;
      flex: 6;
      position: relative;
      justify-content: center;
      gap: 20px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      .input_wrapper {
        gap: 12px;
        display: flex;
        flex-direction: column;
        label {
          font-size: 16px;
          text-align: start;
          font-weight: 500;
          color: var(--header-font-color);
        }
        input {
          padding: 10px;
          border-radius: 4px;
          outline: 1px solid var(--border-color);
          border: none;
          &:focus {
            outline: 1px solid var(--outline-color);
          }
        }
        textarea {
          padding: 10px;
          border-radius: 4px;
          outline: 1px solid var(--border-color);
          border: none;
          &:focus {
            outline: 1px solid var(--outline-color);
          }
        }
        select {
          padding: 10px;
          border-radius: 4px;
          outline: 1px solid var(--border-color);
          border: none;
          &:focus {
            outline: 1px solid var(--outline-color);
          }
        }
      }
    }
    .button_wrapper {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;
      button {
        width: 100px;
        padding: 10px;
        background-color: var(--cyan);
        color: var(--white-color);
        &:first-child {
          background-color: var(--danger);
        }
      }
    }
  }
}
