@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  height: 5px;
}
::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: var(--gray);
}

::-webkit-scrollbar-thumb {
  background: var(--secondary);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary);
}
.sidemenu .active {
  background-color: var(--lightb);
  border-radius: 8px;
  color: var(--indigo);
}

button {
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

@layer base {
  .custom-table {
    @apply relative w-full;
  }
  .custom-table table {
    @apply w-full border-collapse border border-white text-center;
  }

  .custom-table table th {
    @apply bg-emerald-500 font-medium text-white p-2 border border-white text-center print:border-black print:text-black;
  }

  .custom-table table td {
    @apply text-black p-2 border border-white  print:border-black print:text-black;
  }

  .custom-table table tr:nth-child(even) {
    @apply bg-blue-200;
  }

  .custom-table table tr:hover {
    @apply bg-emerald-100;
  }
  .custom-table table tr {
    @apply break-inside-avoid;
  }
}
