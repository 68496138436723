
.salesMain {
	/* background-color: #d7edff; */
	width: 100%;
	min-height: 200px;
	padding-bottom: 30px;
}

.salesMain .innerSale {
	width: 96%;
	margin: auto;
	padding-top: 10px;
	position: relative;
}

.salesMain h2 {
	margin: 0;
	padding: 0;
}

.salesMain .innerSale .saleHeading {
	font-weight: 400;
	border-bottom: 1px solid var(--gray-color);
	padding-bottom: 2px;
	text-transform: uppercase;
	font-size: 18px;
	width: max-content;
	margin: auto;
	letter-spacing: 1px;
}

.salesMain .innerSale .searchSection {
	display: flex;
	background-color: var(--white-color);
	border-radius: 10px;
	flex-wrap: wrap;
	margin-top: 15px;
}

.salesMain .salesHistoruImagesec {
	display: flex;
	align-items: center;
	font-weight: bold;
	color: var(--gray-color);
}

.salesMain .salesHistoruImagesec img {
	height: 30px;
	width: 30px;
	border: 1px solid var(--sky-blue-color);
	border-radius: 50%;
	margin-right: 5px;
	object-fit: contain;
}

.salesMain .innerSale .tableSection {
    background-color: var(--white-color);
    border-radius: 2px;
    overflow-x: auto;
}



.salesMain .innerSale tr th {
	background-color: var(--primary-color);
	color: var(--white-color);
}

.salesMain .innerSale .invoiceClick,
.customerClick {
	cursor: pointer;
	color: var(--sky-blue-color);
}


.salesMain .innerSale .myStatus .packaging {
	background-color: var(--pink-color) !important;
}
.salesMain .innerSale .myStatus {
	display: flex;
	align-items: center;
	height: 100%;
}

.salesMain .innerSale .myStatus svg {
	color: var(--green-color);
	padding: 4px;
	margin-left: 4px;
	height: 17px;
	width: 14px;
	cursor: pointer;
}

.salesMain .innerSale .myStatus p {
	color: var(--red-color);
	padding: 3px 5px;
	border-radius: 5px;
	color: white;
}

.salesMain .innerSale .myStatus .confirmed {
	color: #298d00;
}

.salesMain .innerSale .myStatus .packaging {
	background-color: rgb(5, 196, 196);
}

.salesMain .innerSale .myStatus .preturn {
	background-color: rgb(9, 105, 96);
}

.salesMain .innerSale .myStatus .fullreturn {
	background-color: rgb(187, 7, 127);
}

.salesMain .innerSale .myStatus .complete {
	background-color: rgb(0, 129, 50);
}

.salesMain .innerSale .myStatus .deliveryboyassigned {
	color: #1e0030;
}

.salesMain .innerSale .myStatus .fullreturn,
.hello {
	color: #d81619 !important;
	margin-bottom: 4px;
	background: none !important;
}

.salesMain .innerSale .myStatus button {
	border: none;
	background: #2e0249 !important;
	color: #fff;
	font-size: 11px;
	padding: 5px 2px !important;
	border-radius: 2px;
	font-weight: 200;
	letter-spacing: 1px;
	width: 85px;
}

.salesMain .innerSale .myStatus .fullreturn .deliveryHandover {
	background-color: green;
}
/* expense part start here */
.expense {
	display: block;
	position: relative;
}

.expense .input_section {
	display: flex;
	flex-direction: column;
}

.expense .input_section .input_common {
	display: flex;
	flex-direction: column;
}

.expense .input_section .input_common
input
{
	padding: 10px;
	outline: none;
	border: 1px solid #a6a5a5;
	width: 90%;
	margin-top: 5px;
}
.expense .input_section .input_common 
select {
	padding: 10px;
	outline: none;
	border: 1px solid #a6a5a5;
	width: 90%;
	margin-top: 5px;
}
.expense .input_section .input_common
input{
	padding: 10px;
	outline: none;
	border: 1px solid #a6a5a5;
	width: 90%;
	margin-top: 5px;
}

.tox.tox-tinymce.tox-platform-touch {
	height: 270px !important;
}

.expenceLanel label {
	border: none !important;
	padding: 0 !important;
}

.expense .input_section .input_common input {
	padding: 8px;
	border: none;
	outline: 1px solid #1b6aaf;
	border-radius: 5px;
}

.expense .input_section .input_common input:focus {
	outline: 1px solid #cc04cc;
}

.expense .input_section .input_common select {
	padding: 8px;
	border: none;
	outline: 1px solid #1b6aaf;
	border-radius: 5px;
	width: 100%;
}

.expense .input_section .input_common select:focus {
	outline: 1px solid #cc04cc;
}

.expense .input_section .input_common button {
	padding: 10px 15px;
	width: max-content;
	align-self: flex-end;
	margin-top: 10px;
	border: none;
	background-color: #008000;
	color: white;
	font-weight: bold;
	border-radius: 5px;
}

.expense .add_expense {
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 20px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 20px;
}

.expense .add_expense .input_common input {
	margin: 0;
}

.expense .add_expense .input_common select {
	margin: 0;
}

.expense .input_section .input_common button:hover {
	background-color: #4e0580;
	transition: 1s;
}


.save_expense {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
	margin-top: 10px;
}

.save_expense .input_common {
	display: flex;
	width: 50%;
	align-items: center;
	justify-content: flex-end;
}

.save_expense .input_common label {
	/* flex: 1;	 */
	margin-right: 10px;
}

.save_expense .input_common input {
	flex: 1;
	padding: 8px;
	border: none;
	outline: 1px solid #1b6aaf;
	border-radius: 5px;
}

.save_expense .input_common select {
	flex: 1;
	padding: 8px;
	border: none;
	outline: 1px solid #1b6aaf;
	border-radius: 5px;
}

.save_expense .input_common input:focus {
	outline: 1px solid #cc04cc;
}

.save_expense .input_common select:focus {
	outline: 1px solid #cc04cc;
}

.save_expense .input_common button {
	padding: 10px 15px;
	width: max-content;
	justify-self: flex-end;
	margin-top: 10px;
	border: none;
	background-color: #008000;
	color: white;
	font-weight: bold;
	border-radius: 5px;
}

.WarningText {
	color: red;
	font-style: italic;
	font-weight: bold;
	padding: 0;
	margin: 0;
	margin-top: 5px;
}


.color_red{
    color: red;
}