.up_down{
    position: fixed;
    bottom: 50px;
    z-index: 1000;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    button{
        width: 30px;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: var(--black-color);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        opacity: .5;
        &:hover{
            opacity: 1;
        }
    }
}