.input_wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    background: var(--white-color);
    padding: 30px;
    border-radius: 10px;
    border-radius: 10px;
}
.common{
    flex: 1;
    min-width: 30%;
    display: flex;
    flex-direction: column;
}
.common label{
    margin-bottom: 5px;
}
.common input{
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    background-color: var(--white);
   
}
.option{
    border: 1px solid var(--outline-color);
    outline: 0;
    padding: 10px
}
.common input:focus{
    border: 1px solid var(--outline-color);
    outline: 0;
}
.extra_wrapper{
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin: 0 auto;
    background-color: var(--white-color);
    padding: 10px;
    border-radius: 10px;
}
.extra_wrapper h4{
   width: 100%;
   text-align: center;
   border-bottom: 1px solid var(--border-color);
   margin: 0;
   padding: 0;
   /* max-width: max-content; */
   padding-bottom: 5px;
   margin-bottom:20px ;
   font-size: 20px;
   color: var(--header-font-color);
   font-weight: 500;
}
.inner_common{
    flex: 1;
    min-width: 30%;
    display: flex;
    flex-direction: column;
}
.image_holder{
    display: flex;
    gap: 10px;
}
.image_show{
    width: 100px;
    height: auto;
    margin-top: 15px;
}
.text_editor{
   margin-top: 20px;
}
.btn_group{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.btn_group button{
   background-color: var(--green);
   border-radius: 4px;
   color: var(--white-color);
   padding: 10px 20px;
   font-size: 14px;
   margin-top: 20px;
   transition: 0.3s;
}
.btn_group button:hover{
 background-color: var(--white);
 outline: 1px solid var(--green);
 color: var(--dark);
 transition: 0.3s;
}

.common_att{
    flex: 1;
    min-width: 20%;
    display: flex;
    flex-direction: column;
}
.common_att input{
   padding: 10px;
}
.att_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 5px;
    margin-bottom: 10px;
    gap: 20px;
}
.att_head h4{
    margin: 0;
    padding: 0;
    font-size: 20px;
}
.att_buttons{
    display: flex;
    align-items: center;
}
.att_buttons button{
   background-color: var(--green);
   border-radius: 4px;
   color: var(--white-color);

}
