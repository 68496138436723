
.SpinnerWrrapper {
	position: fixed;
	left: 50%;
	top: 30%;
	z-index: 1;
	padding: 30px;
	transform: translate(-30%, -50%);
}

.SpinnerWrrapper p {
	width: 100%;
	text-align: center;
	margin-top: 20px;
	font-size: 25px;
	font-weight: bold;
	font-style: italic;
	color: green;
}